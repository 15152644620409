import React, { useState } from 'react';
import { Box, Input, Button, Stack, useBreakpointValue, StackDirection, Card, CardHeader, CardBody, Heading, Text } from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useAuth } from '../AuthContext';

const schema = yup.object().shape({
  email: yup.string().required().email(),
});

const LoginPage: React.FC = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<{
    email: string;
  }>({
    resolver: yupResolver(schema),
  });

  const { requestPasswordReset } = useAuth();

  const stackDirection = useBreakpointValue({ base: 'column', md: 'column' }) as StackDirection | undefined;

  const [errorMessage, setErrorMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = async ({ email }: { email: string }) => {
    try {
      setSubmitting(true);
      await requestPasswordReset(email);
      setSubmitted(true);
    } catch (error: any) {
      setErrorMessage('Email not recognised. Please try again.');
    }

    setSubmitting(false);
  };

  const handleFormChange = () => {
    if (errorMessage) {
      setErrorMessage('');
    }
  };

  return (
    <>
      <Card
        paddingY={9}
        paddingX={7}
        borderRadius={'20px'}
        width={'450px'}
        color="#878790"
        boxShadow={'0px 50px 50px 0px rgba(0, 0, 0, 0.25)'}
      >
        <CardHeader>
          <Heading size="xl" mb="3" color="#315D4F" fontWeight={'700'}>
            Reset password
          </Heading>
          {!submitted && (
            <Text fontSize={'md'} lineHeight={'normal'} color="#878790">
              Enter the email address that is linked to this account and we will send you a password reset link.
            </Text>
          )}
        </CardHeader>
        <CardBody pt="10" pb="6">
          {!submitted && (
            <form onSubmit={handleSubmit(onSubmit)} onChange={handleFormChange}>
              <Stack direction={stackDirection} spacing={2}>
                <Box>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({ field }) => <Input {...field} size="lg" placeholder={'Email'} />}
                  />
                  <Box minHeight={6} pt={1} fontSize={'sm'} color={'#F00'}>
                    {errors.email && 'A valid email is required'}
                    {errorMessage && errorMessage}
                  </Box>
                </Box>
                <Box>
                  <Button size="lg" type="submit" mb="4" mt="4" width={'100%'} isLoading={submitting}>
                    Request reset link
                  </Button>
                </Box>
                <Box>
                  <Text>
                    If you don't want to reset your password you can <RouterLink to="/login">Login</RouterLink>.
                  </Text>
                </Box>
              </Stack>
            </form>
          )}
          {submitted && (
            <Box>
              {' '}
              <Text fontSize={'lg'} color="green">
                Email sent! Please check your email.
              </Text>
            </Box>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default LoginPage;
