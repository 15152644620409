import React, { useState, useEffect } from 'react';
import { Avatar, Box, Tooltip } from '@chakra-ui/react';
import axios from 'axios';

interface Props {
  name: string;
  size?: string;
  iconUrl: string;
}

export const Badge: React.FC<Props> = ({ iconUrl, size, name }) => {
  const [src, setSrc] = useState('');

  const fetchSrc = async () => {
    try {
      const res = await axios.get(iconUrl);
      setSrc(`data:image/svg+xml;utf8,${encodeURIComponent(res.data.replace(/<\?xml.*?\?>/, ''))}`);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchSrc();
  }, []);

  return (
    <Box>
      <Tooltip label={name}>
        <Avatar src={src} height={size || '32px'} width={size || '32px'} mr="1" ignoreFallback />
      </Tooltip>
    </Box>
  );
};

export default Badge;
