import React, { useState, useEffect } from 'react';
import { Box, Input, Button, Stack, useBreakpointValue, StackDirection, Card, CardHeader, CardBody, Heading, Text } from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from '../AuthContext';
import api from '../api';

const schema = yup.object().shape({
  password: yup.string().required().min(8, 'Please choose a password that is at least 8 characters long.'),
});

const LoginPage: React.FC = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<{
    password: string;
  }>({
    resolver: yupResolver(schema),
  });

  const { passwordReset } = useAuth();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const navigate = useNavigate();

  const passwordResetToken = params.get('passwordResetToken');

  const stackDirection = useBreakpointValue({ base: 'column', md: 'column' }) as StackDirection | undefined;

  const [mainErrorMessage, setMainErrorMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = async ({ password }: { password: string }) => {
    if (!passwordResetToken) {
      setErrorMessage('Invalid reset token. Please request a new link.');
      return;
    }
    try {
      setSubmitting(true);
      await passwordReset(password, passwordResetToken);
      setSubmitted(true);
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (error: any) {
      setMainErrorMessage('Password reset token has expired or does not exist. Please request a new link.');
    }

    setSubmitting(false);
  };

  const handleFormChange = () => {
    if (errorMessage) {
      setErrorMessage('');
    }
  };

  useEffect(() => {
    async function validateToken() {
      try {
        await api.post(`/users/password-reset-token-validation/${passwordResetToken}`);
      } catch (error: any) {
        setMainErrorMessage('Password reset token has expired or does not exist. Please request a new link.');
      }
    }
    validateToken();
  }, []);

  return (
    <>
      <Card
        paddingY={9}
        paddingX={7}
        borderRadius={'20px'}
        width={'450px'}
        color="#878790"
        boxShadow={'0px 50px 50px 0px rgba(0, 0, 0, 0.25)'}
      >
        <CardHeader>
          <Heading size="xl" mb="3" color="#315D4F" fontWeight={'700'}>
            Reset password
          </Heading>
          {!submitted && !mainErrorMessage && (
            <Text fontSize={'md'} lineHeight={'normal'} color="#878790">
              Please enter your new password.
            </Text>
          )}
        </CardHeader>
        <CardBody pb="6">
          {!submitted && !mainErrorMessage && (
            <form onSubmit={handleSubmit(onSubmit)} onChange={handleFormChange}>
              <Stack direction={stackDirection} spacing={2}>
                <Box>
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    render={({ field }) => <Input {...field} size="lg" placeholder={'Password'} type="password" />}
                  />
                  <Box minHeight={6} pt={1} fontSize={'sm'} color={'#F00'}>
                    {errors.password && 'Please choose a password that is at least 8 characters long.'}
                    {errorMessage && errorMessage}
                  </Box>
                </Box>
                <Box>
                  <Button size="lg" type="submit" mb="4" mt="4" width={'100%'} isLoading={submitting}>
                    Update password
                  </Button>
                </Box>
              </Stack>
            </form>
          )}
          {submitted && (
            <Box>
              {' '}
              <Text fontSize={'lg'} color="green">
                You have successfully changed your password!
              </Text>
            </Box>
          )}
          {mainErrorMessage && (
            <Box>
              {' '}
              <Text fontSize={'lg'} color="red">
                {mainErrorMessage}
              </Text>
            </Box>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default LoginPage;
