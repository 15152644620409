import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, CardHeader, CardBody, Stack, CheckboxGroup, Checkbox, Select, Button, SimpleGrid, GridItem } from '@chakra-ui/react';

import ListingDetails from '../../components/ListingDetails';
import TextHeading from '../../components/TextHeading';

import { useToastContext } from '../../ToastContext';
import api from '../../api';
import { Utils } from '../../services';

export const AdminListingDetailsPage: React.FC = () => {
  const { listingId } = useParams();
  const toast = useToastContext();
  const navigate = useNavigate();

  const [listingData, setListingData]: any = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await api.get(`/admin-listings/${listingId}`);

      setListingData(data);
      setLoading(false);
    } catch (error) {
      toast('An error occurred', Utils.formatErrorMessage(error), 'error');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [category, setCategory] = useState([]);
  const [region, setRegion] = useState('');

  const hanldeCategoryChange = (e: any) => {
    const selectedCategory = e.length > 0 ? e.pop() : null;
    // @ts-ignore
    setCategory(selectedCategory ? [selectedCategory] : []);
  };
  const handleRegionChange = (e: any) => setRegion(e.target.value);

  const [saving, setSaving] = useState(false);

  const handleShareClick = async () => {
    try {
      setSaving(true);

      await api.post(`/admin-listings/affiliate-sharing`, {
        listingId,
        affiliateCategory: category[0],
        affiliateRegion: region,
      });

      toast('Listing shared', 'Listing has been shared successfully with afifliate partners', 'success');
      navigate('/listings?tab=expired');
    } catch (error) {
      toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }

    setSaving(false);
  };

  console.log(category);
  return (
    <SimpleGrid columns={3} spacing="6">
      <GridItem colSpan={2}>
        <ListingDetails data={listingData} onDataChange={fetchData} loading={saving} />
      </GridItem>
      <GridItem>
        <Card>
          <CardHeader>
            <TextHeading title="Share with the Affilates" fontSize="21px"></TextHeading>
          </CardHeader>
          <CardBody>
            <Stack>
              <CheckboxGroup value={category} onChange={hanldeCategoryChange}>
                <Stack spacing={4} direction={'column'} pb="3">
                  <Checkbox size="lg" value="all" colorScheme="brand">
                    All
                  </Checkbox>
                  <Checkbox size="lg" value="Charity" colorScheme="brand">
                    Charity
                  </Checkbox>
                  <Checkbox size="lg" value="School" colorScheme="brand">
                    School
                  </Checkbox>
                  <Checkbox size="lg" value="Start up" colorScheme="brand">
                    Start up
                  </Checkbox>
                </Stack>
              </CheckboxGroup>

              <Select placeholder={'Choose Location'} size="lg" value={region} onChange={handleRegionChange}>
                <option value="all">All</option>
                <option value="East (England)">East (England)</option>
                <option value="East Midlands (England)">East Midlands (England)</option>
                <option value="West Midlands (England)">West Midlands (England)</option>
                <option value="London">London</option>
                <option value="North East (England)">North East (England)</option>
                <option value="North West (England)">North West (England)</option>
                <option value="South East (England)">South East (England)</option>
                <option value="South West (England)">South West (England)</option>
                <option value="Yorkshire and The Humber">Yorkshire and The Humber</option>
                <option value="Scotland">Scotland</option>
                <option value="Wales">Wales</option>
                <option value="Northern Ireland">Northern Ireland</option>
              </Select>

              <Button mt="4" onClick={handleShareClick} isDisabled={category.length === 0 || region === ''}>
                Share
              </Button>
            </Stack>
          </CardBody>
        </Card>
      </GridItem>
    </SimpleGrid>
  );
};

export default AdminListingDetailsPage;
