import React, { useState } from 'react';
import {
  Skeleton,
  Stack,
  Table,
  Thead,
  Tbody,
  Avatar,
  Tr,
  Th,
  Td,
  Box,
  BoxProps,
  TableContainer,
  SkeletonCircle,
  IconButton,
  Text,
  useDisclosure,
  LinkBox,
  LinkOverlay,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

import { ReactComponent as IcnDelete } from '../icons/icn-delete.svg';
import { ReactComponent as IcnEdit } from '../icons/icn-edit.svg';

import ConfirmDialog from './ConfirmDialog';

import XBorder from './XBorder';

export interface Column {
  header: string;
  accessor: string;
  align?: 'left' | 'center' | 'right';
}

export interface Props extends BoxProps {
  data: any[];
  loading: boolean;
  columns: Column[];
  children?: React.ReactNode;
  deleteing?: boolean;
  onDelete?: (id: string) => void;
  onEditClick?: (item: any) => void;
  enableEdit?: boolean;
  enableDelete?: boolean;
  noAvatar?: boolean;
}

function getValue(obj: any, key: string): any {
  const keys = key.split('.');
  let value = obj;

  if (Object.entries(value).length === 0) {
    return '';
  }

  for (let i = 0; i < keys.length; i++) {
    if (typeof value[keys[i]] !== 'undefined' && value[keys[i]] !== null) {
      value = value[keys[i]];
    } else {
      return '';
    }
  }

  return value;
}

export const AdminDataTable: React.FC<Props> = ({
  data,
  loading,
  columns,
  onDelete,
  onEditClick,
  enableEdit = false,
  enableDelete = false,
  deleteing,
  noAvatar = false,
  ...wrapperProps
}) => {
  const skeletonData = [{}, {}, {}, {}];
  const [itemToDelete, setItemToDelete]: any = useState(null);

  const { isOpen: isDeleteDialogOpen, onOpen: onDeleteDialogOpen, onClose: onDeleteDialogClose } = useDisclosure();

  const handleDeleteClick = (item: any) => {
    setItemToDelete(item);
    onDeleteDialogOpen();
  };

  const handleDeleteDialogClose = () => {
    setItemToDelete(null);
    onDeleteDialogClose();
  };

  const handleDeleteConfirm = () => {
    if (typeof onDelete === 'function') {
      onDelete(itemToDelete.id);
    }
    setItemToDelete(null);
    onDeleteDialogClose();
  };

  return (
    <>
      <ConfirmDialog
        title={`Delete ${itemToDelete?.name}?`}
        message="Are you sure you want to delete this item?"
        isOpen={isDeleteDialogOpen}
        onClose={handleDeleteDialogClose}
        onConfirm={handleDeleteConfirm}
        isConfirming={deleteing}
        confirmText="Delete"
      />
      <Box {...wrapperProps}>
        <TableContainer>
          <Table borderBottomRadius="30px" padding="0">
            <Thead>
              <Tr border={'none'}>
                {columns.map((column: Column, index: number) => (
                  <Th
                    border={'none'}
                    key={column.accessor}
                    textAlign={column.align || 'left'}
                    textTransform={'none'}
                    color={'brand.900'}
                    {...(index === 0 ? { pl: 0 } : {})}
                  >
                    {column.header}
                  </Th>
                ))}
                {enableEdit && (
                  <Th textAlign={'center'} textTransform={'none'}>
                    Edit
                  </Th>
                )}
                {enableDelete && (
                  <Th textAlign={'center'} textTransform={'none'}>
                    Delete
                  </Th>
                )}
              </Tr>
            </Thead>
            <Tbody>
              {!loading && (!data || (data && data.length === 0)) && (
                <>
                  <Tr>
                    <Td colSpan={5} textAlign={'center'} pb={0} pt={0} px={0}>
                      <XBorder mb={0} mt={0} />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td colSpan={5} textAlign={'center'} className="">
                      <Text py={3}>No data to display</Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td colSpan={5} textAlign={'center'} pb={5} pt={0} px={0}>
                      <XBorder mb={0} mt={0} />
                    </Td>
                  </Tr>
                </>
              )}
              {loading &&
                skeletonData.map((item: any, index: number) => (
                  <Tr key={index} className="x-border">
                    <Td pl="0" pr="5" borderBottom="none">
                      <Stack direction={'row'} display={'flex'} alignItems="center">
                        {!noAvatar && <SkeletonCircle size="48px" mr={1} />}
                        <Skeleton height="16px" width={'calc(95% - 48px)'} />
                      </Stack>
                    </Td>
                    {[...columns].splice(1, columns.length - 1).map((column: Column) => (
                      <Td key={column.accessor} textAlign={column.align} fontSize={'14px'}>
                        {getValue(item, column.accessor)}
                      </Td>
                    ))}
                    {enableEdit && (
                      <Td borderBottom="none" textAlign={'center'}>
                        <Skeleton textAlign={'center'}>
                          <IconButton onClick={() => onEditClick && onEditClick(item)} aria-label="Edit" size={'sm'} icon={<IcnEdit />} />
                        </Skeleton>
                      </Td>
                    )}
                    {enableDelete && (
                      <Td borderBottom="none" textAlign={'center'}>
                        <Skeleton isLoaded={!loading} textAlign={'center'}>
                          <IconButton
                            isDisabled={!enableDelete}
                            onClick={() => handleDeleteClick(item)}
                            aria-label="Delete"
                            size={'sm'}
                            icon={<IcnDelete />}
                          />
                        </Skeleton>
                      </Td>
                    )}
                  </Tr>
                ))}
              {!loading &&
                data &&
                data.length > 0 &&
                data.map((item: any, index: number) => (
                  <Tr key={index} className="x-border">
                    <Td pl="0" pr="5" borderBottom="none">
                      {item.link && (
                        <LinkBox>
                          <LinkOverlay as={NavLink} to={item.link}>
                            <Stack direction={'row'} height={'48px'}>
                              {!noAvatar && <Avatar size="sm" src={item.image ? item.image : '/placeholder.jpg'} />}
                              <Box fontWeight={600} pl={1} display={'flex'} alignItems="center">
                                <Box overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'} maxWidth={'300px'}>
                                  {item.name}
                                </Box>
                              </Box>
                            </Stack>
                          </LinkOverlay>
                        </LinkBox>
                      )}
                      {!item.link && (
                        <Stack direction={'row'} height={'48px'}>
                          {!noAvatar && <Avatar size="sm" src={item.image ? item.image : '/placeholder.jpg'} />}
                          <Box fontWeight={600} pl={1} display={'flex'} alignItems="center">
                            <Box overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'} maxWidth={'300px'}>
                              {item.name}
                            </Box>
                          </Box>
                        </Stack>
                      )}
                    </Td>
                    {[...columns].splice(1, columns.length - 1).map((column: Column) => (
                      <Td key={column.accessor} textAlign={column.align} fontSize={'14px'}>
                        {getValue(item, column.accessor)}
                      </Td>
                    ))}
                    {enableEdit && (
                      <Td borderBottom="none" textAlign={'center'}>
                        <Skeleton isLoaded={!loading} textAlign={'center'}>
                          <IconButton onClick={() => onEditClick && onEditClick(item)} aria-label="Edit" size={'sm'} icon={<IcnEdit />} />
                        </Skeleton>
                      </Td>
                    )}
                    {enableDelete && (
                      <Td borderBottom="none" textAlign={'center'}>
                        <Skeleton isLoaded={!loading} textAlign={'center'}>
                          <IconButton
                            isDisabled={!enableDelete}
                            onClick={() => handleDeleteClick(item)}
                            aria-label="Delete"
                            size={'sm'}
                            icon={<IcnDelete />}
                          />
                        </Skeleton>
                      </Td>
                    )}
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default AdminDataTable;
