import React from 'react';
import { Card, CardBody, Text, Box, Center, Button } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

import { useAuth } from '../AuthContext';

interface Props {
  light?: boolean;
}

export const ExploreCTACard: React.FC<Props> = ({ light = false }) => {
  const { user } = useAuth();

  return (
    <Card bg={light ? '#fff' : 'brand.900'}>
      <CardBody color={light ? '#000' : '#fff'}>
        <Text fontSize={'36px'} lineHeight={'42px'} textAlign={'center'}>
          Increase your impactX, <strong>{user?.fullName.split(' ')[0]}</strong>!
        </Text>
        <Center position={'relative'}>
          <svg xmlns="http://www.w3.org/2000/svg" width="224" height="214" viewBox="0 0 224 214" fill="none">
            <path
              d="M177.756 84.4968C178.73 84.4618 179.587 83.9343 180.064 83.0997L188.763 66.0245C202.213 91.327 202.459 121.312 189.373 146.709L168.835 147.776C168.553 147.795 168.285 147.654 168.128 147.41L159.402 133.556C167.769 118.571 168.595 100.689 161.781 85.0949L177.756 84.4968Z"
              fill="#E2E8E7"
              fillOpacity="0.15"
            />
            <path
              d="M114.885 19.8828C129.042 20.3439 142.941 24.2769 155.215 31.2988C167.489 38.3207 177.652 48.0633 185.187 59.8061L175.84 78.172C175.69 78.4341 175.446 78.5905 175.17 78.5999L158.748 79.2128C153.941 71.0659 147.121 64.3178 138.987 59.6644C132.534 55.9727 125.482 53.6899 117.993 52.87L125.454 38.7454C125.919 37.8651 125.873 36.7955 125.329 35.9431L114.885 19.8828Z"
              fill="#E2E8E7"
              fillOpacity="0.07"
            />
            <path
              d="M112.611 161.142C129.737 160.945 145.732 152.759 155.795 139.092L164.305 152.603C164.84 153.45 165.795 153.945 166.79 153.896L185.901 152.89C170.827 177.098 144.923 192.277 116.2 193.671L104.994 176.44C104.837 176.196 104.819 175.889 104.957 175.647L112.616 161.133L112.611 161.142Z"
              fill="#5D8075"
            />
            <path
              d="M55.1708 65.8881C55.4526 65.869 55.7303 66.015 55.8774 66.2538L64.6037 80.1078C56.2274 95.0873 55.4102 112.974 62.2154 128.563L46.24 129.161C45.2752 129.202 44.4243 129.72 43.9468 130.554L35.2376 147.649C21.7875 122.346 21.5415 92.3616 34.6269 66.9639L55.1653 65.8978L55.1708 65.8881Z"
              fill="#DEE5E2"
            />
            <path
              d="M48.1759 135.472C48.3147 135.23 48.5632 135.089 48.8297 135.074L65.2521 134.461C70.0589 142.608 76.8784 149.356 85.0124 154.009C91.4749 157.706 98.5272 159.989 106.007 160.804L98.5454 174.928C98.0749 175.818 98.1305 176.893 98.6762 177.721L109.12 193.781C94.9685 193.31 81.0638 189.387 68.79 182.365C56.5161 175.343 46.3535 165.601 38.8185 153.858L48.1814 135.463L48.1759 135.472Z"
              fill="#E2E8E7"
              fillOpacity="0.5"
            />
            <path
              d="M38.0941 60.7683C53.1786 36.5658 79.0726 21.3812 107.796 19.9872L119.002 37.2178C119.149 37.4565 119.167 37.7631 119.038 38.0113L111.385 52.5157C94.2586 52.713 78.2632 60.8991 68.2001 74.5658L59.6904 61.0546C59.1558 60.2078 58.2104 59.7184 57.2054 59.7617L38.0996 60.7586L38.0941 60.7683Z"
              fill="white"
            />
          </svg>
          <Box color="#fff" fontSize="96px" pos={'absolute'} top="50%" left="50%" transform="translate(-50%, -50%)">
            +
          </Box>
        </Center>
        <Box textAlign={'center'} fontSize={'14px'} px={2} lineHeight={'normal'}>
          Reuse, reallocate and repurpose to make an immediate and positive impact.
        </Box>
        <Center pt={4}>
          <Button as={NavLink} to="/marketplace" fontSize={'16px'} border={'1px solid #fff'} fontWeight={'normal'} px={10}>
            Explore your Marketplace
          </Button>
        </Center>
      </CardBody>
    </Card>
  );
};

export default ExploreCTACard;
