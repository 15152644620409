import React, { useEffect, useState } from 'react';
import { Stack, Card, CardHeader, CardBody, Image, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import PageGrid from '../components/PageGrid';
import ScoreCard from '../components/ScoreCard';
import TextHeading from '../components/TextHeading';
import ExploreCTACard from '../components/ExploreCTACard';

import api from '../api';

const NewsArticlePage: React.FC = () => {
  const { articleId } = useParams();
  const [article, setArticle]: any = useState();

  const fetchData = async () => {
    try {
      const response = await api.get(`/news/${articleId}`);
      setArticle(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PageGrid
      sideBar={
        <Stack gap="6">
          <ScoreCard />
          <ExploreCTACard />
        </Stack>
      }
    >
      <Card>
        <CardHeader>
          <TextHeading fontSize={'21px'} title={article?.title} borderColor="#315D4F"></TextHeading>
        </CardHeader>
        <CardBody>
          <Image width={'100%'} fallbackSrc={'/placeholder.jpg'} borderRadius={'15px'} src={article?.image} />

          <Text py="6" fontSize={'21px'}>
            {article?.subtitle}
          </Text>

          <Text pb="2" whiteSpace={'pre-line'}>
            {article?.description}
          </Text>
        </CardBody>
      </Card>
    </PageGrid>
  );
};

export default NewsArticlePage;
