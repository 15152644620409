import React from 'react';
import { Box, Center, Stack } from '@chakra-ui/react';
import { motion } from 'framer-motion';

interface Props {
  score: number;
}

export const Score: React.FC<Props> = ({ score }) => (
  <Box pos={'relative'}>
    <Center>
      <motion.div animate={{ rotate: 360 }} transition={{ duration: 10, repeat: Infinity }}>
        <Box
          backgroundImage="url(/score.svg)"
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          width={'230px'}
          height={'230px'}
        ></Box>
      </motion.div>
    </Center>
    <Center position={'absolute'} top={0} left={0} height={'100%'} width={'100%'}>
      <Stack>
        <Box textAlign="center" fontWeight={'700'} color="brand.900" fontSize={'48px'} lineHeight={0.9}>
          {new Intl.NumberFormat('en-US', { maximumFractionDigits: 1, notation: 'compact', compactDisplay: 'short' }).format(score) || 0}
        </Box>
        <Box textAlign="center" color="#9EA4B4" fontSize={'18px'} lineHeight={0.2} fontWeight={'700'}>
          KG CO2e
        </Box>
      </Stack>
    </Center>
  </Box>
);

export default Score;
