import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  closeButton: {
    top: 8,
    right: '28px',
  },
  dialog: {
    borderRadius: '20px',
  },
});

const xl = defineStyle({
  px: '6',
  py: '2',
  width: '840px',
  maxWidth: '840px',
});

const sizes = {
  xl: definePartsStyle({ dialog: xl }),
};

export const Modal = defineMultiStyleConfig({
  baseStyle,
  sizes,
});
