import React, { useEffect, useState } from 'react';
import { Card, Stack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

// import { useSocket } from '../SocketContext';

import PageGrid from '../components/PageGrid';
import ScoreCard from '../components/ScoreCard';
import NewsCard from '../components/NewsCard';
import MessageBox from '../components/MessageBox';

import api from '../api';

interface Props {}

const MessageThreadPage: React.FC<Props> = () => {
  // const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState(true);
  const { messageId } = useParams();
  const [message, setMessage] = useState<any>();
  const [replies, setReplies] = useState<any>();

  const fetchData = async () => {
    try {
      setLoading(true);
      const [messageResponse, repliesResponse] = await Promise.all([
        api.get(`/message/${messageId}`),
        api.get(`/message/replies/${messageId}`),
      ]);

      setMessage(messageResponse.data.data);
      setReplies(repliesResponse.data.data.items);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PageGrid
      sideBar={
        <Stack gap="6">
          <ScoreCard />
          <NewsCard />
        </Stack>
      }
    >
      <Card paddingY={4} paddingX={6} alignSelf={'flex-start'}>
        {message && (
          <MessageBox expandable message={message} firstInList lastInList={message?.replies.length === 0} onReplyDialogClose={fetchData} />
        )}

        {replies && replies.length > 0 && (
          <>
            {replies.map((reply: any, index: number) => (
              <MessageBox
                expandable
                message={reply}
                firstInList={false}
                lastInList={index === replies.length - 1}
                onReplyDialogClose={fetchData}
                key={index}
              />
            ))}
          </>
        )}
      </Card>
    </PageGrid>
  );
};

export default MessageThreadPage;
