import React, { useState, useEffect } from 'react';
import {
  LinkBox,
  LinkOverlay,
  Stack,
  useBreakpointValue,
  StackDirection,
  Box,
  IconButton,
  Wrap,
  WrapItem,
  Avatar,
  Grid,
  GridItem,
  Center,
  Text,
  Badge,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

import type { User } from '../types';
import { ReactComponent as IcnMessages } from '../icons/icn-messages.svg';
import { ReactComponent as IcnNotifications } from '../icons/icn-notifications.svg';

import { useAuth } from '../AuthContext';
import api from '../api';

import SearchBar from './SearchBar';
import { AccountPane } from './AccountPane';

// const AccountItem: React.FC<{ user: User | null; account: string }> = ({ user, account }) => (
//   <Box fontFamily={'14px'} mt={3} mb={3}>
//     <Border mb={3} />
//     <Stack direction="row" spacing={2} justifyContent={'flex-end'} alignItems={'center'}>
//       <Box>
//         <Text fontWeight="700" lineHeight={'normal'}>
//           {' '}
//           {user?.firstName + ' ' + user?.lastName}
//         </Text>
//         <Text lineHeight={'normal'}>{account}</Text>
//       </Box>
//       <Avatar src={user?.profilePhoto} name={user?.firstName + ' ' + user?.lastName} />
//     </Stack>
//   </Box>
// );

const TopBar: React.FC = () => {
  const { user } = useAuth();
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const [messageCount, setMessageCount] = useState<number>(0);
  const [unreadMessages, setUnreadMessages] = useState([]);

  const handleMessageCountClick = async () => {
    setMessageCount(0);
    try {
      unreadMessages.forEach(async (message: any) => {
        api.patch(`/message/${message.id}/mark-read`);
      });
    } catch (error) {}
  };
  const fetchData = async () => {
    try {
      const response = await api.get(`/message/received-unread`);

      setUnreadMessages(response.data.data.items);
      setMessageCount(response.data.data.items.length);
    } catch (error) {}

    try {
      const response = await api.get(`/notifications`);

      setNotificationCount(response.data.data.filter((n: any) => n.read === false)?.length || 0);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      {/* <Drawer isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent top={'120px !important'} bottom="auto !important" borderTopLeftRadius={'30px'} borderBottomLeftRadius={'30px'}>
          <DrawerBody color="brand.900" textAlign={'right'}>
            <Box fontSize="21px" fontWeight={700} pt={2} pb={1}>
              Switch account
            </Box>

            <AccountItem user={user} account="Client user" />
            <AccountItem user={user} account="Client admin" />
          </DrawerBody>
        </DrawerContent>
      </Drawer> */}

      <Center h="100%" alignItems={'center'}>
        <Grid gridTemplateColumns="repeat(3, 1fr)" width={'100%'} pl={6} pr={8}>
          <GridItem colSpan={2}>
            <Stack direction={'row'} gap={5}>
              <SearchBar />
              <Box pos={'relative'}>
                {messageCount > 0 && (
                  <Center
                    bg="#F00"
                    color="#fff"
                    width={'20px'}
                    height={'20px'}
                    borderRadius={'50%'}
                    fontSize="14px"
                    position={'absolute'}
                    zIndex={1}
                    top={'-3px'}
                    right={'-3px'}
                  >
                    {messageCount}
                  </Center>
                )}
                <IconButton
                  as={NavLink}
                  to="/messages"
                  variant="circle"
                  icon={<IcnMessages />}
                  size="lg"
                  aria-label={'Messages'}
                  onClick={handleMessageCountClick}
                ></IconButton>
              </Box>
              <Box pos={'relative'}>
                {notificationCount > 0 && (
                  <Center
                    bg="#F00"
                    color="#fff"
                    width={'20px'}
                    height={'20px'}
                    borderRadius={'50%'}
                    fontSize="14px"
                    position={'absolute'}
                    zIndex={1}
                    top={'-3px'}
                    right={'-3px'}
                  >
                    {notificationCount}
                  </Center>
                )}
                <IconButton
                  as={NavLink}
                  to="/notifications"
                  variant="circle"
                  icon={<IcnNotifications />}
                  onClick={() => setNotificationCount(0)}
                  size="lg"
                  aria-label={'Notifications'}
                ></IconButton>
              </Box>
            </Stack>
          </GridItem>
          <GridItem minWidth={'250px'}>
            <AccountPane user={user} />
          </GridItem>
        </Grid>
      </Center>
    </>
  );
};

export default TopBar;
