import React, { useState } from 'react';
import { Box, Stack } from '@chakra-ui/react';
import { add, addMonths, format, isSameMonth } from 'date-fns';

import XTabs from './XTabs';

const tabData = [
  {
    label: 'impactX',
    content: 'score',
  },
  {
    label: 'Closed loops',
    content: 'closed',
  },
];

interface Props {
  data: any;
}

interface GraphItem {
  month: string;
  score: number;
  loops: number;
}

export const ScoreGraph: React.FC<Props> = ({ data }) => {
  const maxScore = Math.max(...data.map((item: any) => item.impactXScore));
  const maxClosedLoops = Math.max(...data.map((item: any) => item.closeLoop));

  const graphData: GraphItem[] = [];
  const startMonthDate = new Date();
  startMonthDate.setDate(1);

  for (let i = 11; i >= 0; i--) {
    const currentMonthDate = addMonths(startMonthDate, -i);
    const monthData = data.find((item: any) => isSameMonth(new Date(item.date), currentMonthDate));

    graphData.push({
      month: format(addMonths(startMonthDate, -i), 'MMM'),
      score: monthData?.impactXScore / (maxScore / 100) || 0,
      loops: monthData?.closeLoop / (maxClosedLoops / 100) || 0,
    });
  }

  const [selectedTab, setSelectedTab] = useState(tabData[0].content);

  const handleTabChange = (index: number, tab: string) => {
    setSelectedTab(tab);
  };
  return (
    <XTabs data={tabData} compact onTabChange={handleTabChange}>
      {graphData && (
        <Stack direction={'row'} py={6} px={2}>
          {graphData.map((item: any, index: number) => (
            <Stack key={index} alignItems={'center'} width={'100%'}>
              <Box width={'10px'} height="50px" position="relative">
                <Box
                  bg={'brand.900'}
                  height={`${selectedTab === tabData[0].content ? item.score : item.loops}%`}
                  width={'100%'}
                  bottom="0"
                  pos={'absolute'}
                />
              </Box>
              <Box color={'brand.900'} fontSize={'10px'} textTransform={'capitalize'}>
                {item.month}
              </Box>
            </Stack>
          ))}
        </Stack>
      )}
    </XTabs>
  );
};
