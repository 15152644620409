const TOS = () => {
  return (
    <div className="tos-privacy">
      <h1>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS PLATFORM</h1>
      <h1>TERMS OF PLATFORM USE</h1>
      <p>
        This terms of use (together with the documents referred to in it) tells you the terms of use on which you may make use of our
        platform www.xsilioplatform.com (<strong>our platform</strong>), whether as a guest or a registered user. Use of our platform
        includes accessing, browsing, or registering to use our platform.
      </p>
      <p>Please read these terms of use carefully before you start to use our platform, as these will apply to your use of our platform.</p>
      <p>We recommend that you print a copy of this for future reference.</p>
      <p>By using our platform, you confirm that you accept these terms of use and that you agree to comply with them.</p>
      <p>If you do not agree to these terms of use, you must not use our platform.</p>
      <h1>OTHER APPLICABLE TERMS</h1>
      <p>These terms of use refer to the following additional terms, which also apply to your use of our platform:</p>
      <p>
        Our Privacy Policy, which sets out the terms on which we process any personal data we collect from you, or that you provide to us.
        By using our platform, you consent to such processing and you warrant that all data provided by you is accurate.
      </p>
      <h1>INFORMATION ABOUT US</h1>
      <p>
        www.xsilioplatform.com is a platform operated by XSILIO Limited ("We"). We are registered in England and Wales under company number
        14006269 and have our registered office at Springbank Offices, 93 Macclesfield Road, Prestbury SK104AG.
      </p>

      <h1>CHANGES TO THESE TERMS</h1>

      <p>We may revise these terms of use at any time by amending this page.</p>
      <p>Please check this page from time to time to take notice of any changes we made, as they are binding on you.</p>

      <h1>CHANGES TO OUR PLATFORM</h1>

      <p>
        We may update our platform from time to time, and may change the content at any time. However, please note that any of the content
        on our platform may be out of date at any given time, and we are under no obligation to update it.
      </p>
      <p>We do not guarantee that our platform, or any content on it, will be free from errors or omissions.</p>

      <h1>ACCESSING OUR PLATFORM</h1>

      <p>
        We do not guarantee that our platform, or any content on it, will always be available or be uninterrupted. Access to our platform is
        permitted on a temporary basis. We may suspend, withdraw, discontinue or change all or any part of our platform without notice. We
        will not be liable to you if for any reason our platform is unavailable at any time or for any period.
      </p>
      <p>You are responsible for making all arrangements necessary for you to have access to our platform.</p>
      <p>
        You are also responsible for ensuring that all persons who access our platform through your internet connection are aware of these
        terms of use and other applicable terms and conditions, and that they comply with them.
      </p>

      <h1>YOUR ACCOUNT AND PASSWORD</h1>

      <p>
        If you choose, or you are provided with, a user identification code, password or any other piece of information as part of our
        security procedures, you must treat such information as confidential. You must not disclose it to any third party.
      </p>
      <p>
        We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in
        our reasonable opinion you have failed to comply with any of the provisions of these terms of use.
      </p>
      <p>
        If you know or suspect that anyone other than you knows your user identification code or password, you must promptly notify us at
        support@xsilio.com.
      </p>

      <h1>INTELLECTUAL PROPERTY RIGHTS</h1>

      <p>
        We are the owner or the licensee of all intellectual property rights in our platform, and in the material published on it.&nbsp;
      </p>
      <p>Those works are protected by copyright laws and treaties around the world. All such rights are reserved.</p>
      <p>
        You may print off one copy, and may download extracts, of any page(s) from our platform for your personal use and you may draw the
        attention of others within your organisation to content posted on our platform.
      </p>
      <p>
        You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use
        any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.
      </p>
      <p>Our status (and that of any identified contributors) as the authors of content on our platform must always be acknowledged.</p>
      <p>
        You must not use any part of the content on our platform for commercial purposes without obtaining a licence to do so from us or our
        licensors.
      </p>
      <p>
        If you print off, copy or download any part of our platform in breach of these terms of use, your right to use our platform will
        cease immediately and you must, at our option, return or destroy any copies of the materials you have made.
      </p>

      <h1>NO RELIANCE ON INFORMATION</h1>

      <p>
        The content on our platform is provided for general information only. It is not intended to amount to advice on which you should
        rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on
        our platform.
      </p>
      <p>
        Although we make reasonable efforts to update the information on our platform, we make no representations, warranties or guarantees,
        whether express or implied, that the content on our platform is accurate, complete or up-to-date.
      </p>

      <h1>LIMITATION OF OUR LIABILITY</h1>

      <p>
        Nothing in these terms of use excludes or limits our liability for death or personal injury arising from our negligence, or our
        fraud or fraudulent misrepresentation, or any other liability that cannot be excluded or limited by English law.
      </p>
      <p>
        To the extent permitted by law, we exclude all conditions, warranties, representations or other terms which may apply to our
        platform or any content on it, whether express or implied.
      </p>
      <p>
        We will not be liable to any user for any loss or damage, whether in contract, tort (including negligence), breach of statutory
        duty, or otherwise, even if foreseeable, arising under or in connection with:
      </p>

      <ul>
        <li>use of, or inability to use, our platform; or</li>
        <li>use of or reliance on any content displayed on our platform.</li>

        <li>If you are a business user, please note that in particular, we will not be liable for:</li>

        <li>loss of profits, sales, business, or revenue;</li>

        <li>business interruption;</li>

        <li>loss of anticipated savings;</li>

        <li>loss of business opportunity, goodwill or reputation; or</li>

        <li>any indirect or consequential loss or damage.</li>
      </ul>
      <p>
        If you are a consumer user, please note that we only provide our platform for domestic and private use. You agree not to use our
        platform for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business
        interruption, or loss of business opportunity.
      </p>
      <p>
        We will not be liable for any loss or damage caused by a virus, distributed denial-of-service attack, or other technologically
        harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of
        our platform or to your downloading of any content on it, or on any platform linked to it.
      </p>
      <p>
        We assume no responsibility for the content of platforms linked on our platform. Such links should not be interpreted as endorsement
        by us of those linked platforms. We will not be liable for any loss or damage that may arise from your use of them.
      </p>

      <h1>UPLOADING CONTENT TO OUR PLATFORM</h1>

      <p>
        Whenever you make use of a feature that allows you to upload content to our platform, or to make contact with other users of our
        platform, you must comply with the content standards set out in our Acceptable Use Policy.
      </p>
      <p>
        You warrant that any such contribution does comply with those standards, and you will be liable to us and indemnify us for any
        breach of that warranty.
      </p>
      <p>
        Any content you upload to our platform will be considered non-confidential and non-proprietary. You retain all of your ownership
        rights in your content, but you are required to grant us and other users of the Platform a limited licence to use, store and copy
        that content and to distribute and make it available to third parties. The rights you license to us are described in the next
        paragraph (Rights you licence).
      </p>
      <p>
        We also have the right to disclose your identity to any third party who is claiming that any content posted or uploaded by you to
        our platform constitutes a violation of their intellectual property rights, or of their right to privacy.
      </p>
      <p>
        We will not be responsible, or liable to any third party, for the content or accuracy of any content posted by you or any other user
        of our platform.
      </p>
      <p>
        We have the right to remove any posting you make on our platform if, in our opinion, your post does not comply with the content
        standards set out in our Acceptable Use Policy.
      </p>
      <p>The views expressed by other users on our platform do not represent our views or values.</p>
      <p>You are solely responsible for securing and backing up your content.</p>

      <h1>RIGHTS YOU LICENCE</h1>

      <p>When you upload or post content to our platform, you grant the following licenses:</p>
      <ul>
        <li>
          A worldwide, non-exclusive, royalty-free, transferable licence to use, reproduce, distribute, prepare derivative works of,
          display, and perform that content in connection with the services provided by our platform and across different media and to
          promote the platform or services; and
        </li>
      </ul>
      <ul>
        <li>
          A worldwide, non-exclusive, royalty-free, transferable licence to allow third parties to use the content for their purposes.
        </li>
      </ul>
      <p>
        We will only ever use your materials to carry out your instructions to us &ndash; unless, very exceptionally, a court or other
        regulator orders us to disclose them.
      </p>

      <h1>VIRUSES</h1>

      <p>We do not guarantee that our platform will be secure or free from bugs or viruses.</p>
      <p>
        You are responsible for configuring your information technology, computer programmes and platform in order to access our platform.
        You should use your own virus protection software.
      </p>
      <p>
        You must not misuse our platform by knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious
        or technologically harmful. You must not attempt to gain unauthorised access to our platform, the server on which our platform is
        stored or any server, computer or database connected to our platform. You must not attack our platform via a denial-of-service
        attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence under the
        Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will co-operate with
        those authorities by disclosing your identity to them. In the event of such a breach, your right to use our platform will cease
        immediately.
      </p>

      <h1>LINKING TO OUR PLATFORM</h1>

      <p>
        You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take
        advantage of it.
      </p>
      <p>
        You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none
        exists.
      </p>
      <p>You must not establish a link to our platform in any platform that is not owned by you.</p>
      <p>
        Our platform must not be framed on any other platform, nor may you create a link to any part of our platform other than the home
      </p>
      <p>page.</p>
      <p>We reserve the right to withdraw linking permission without notice.</p>
      <p>
        The platform in which you are linking must comply in all respects with the content standards set out in our Acceptable Use Policy.
      </p>
      <p>If you wish to make any use of content on our platform other than that set out above, please contact support@xsilio.com.</p>

      <h1>THIRD PARTY LINKS AND RESOURCES IN OUR PLATFORM</h1>

      <p>
        Where our platform contains links to other platforms and resources provided by third parties, these links are provided for your
        information only.
      </p>
      <p>We have no control over the contents of those platforms or resources.</p>

      <h1>APPLICABLE LAW</h1>

      <p>
        If you are a consumer, please note that these terms of use, its subject matter and its formation, are governed by English law. You
        and we both agree to that the courts of England and Wales will have non-exclusive jurisdiction.
      </p>
      <p>
        However, if you are a resident of Northern Ireland you may also bring proceedings in Northern Ireland, and if you are resident of
        Scotland, you may also bring proceedings in Scotland.
      </p>
      <p>
        If you are a business, these terms of use, its subject matter and its formation (and any non-contractual disputes or claims) are
        governed by English law. We both agree to the exclusive jurisdiction of the courts of England and Wales.
      </p>

      <h1>CONTACT US</h1>

      <p>To contact us, please email support@xsilio.com.</p>
      <p>Thank you for visiting our platform.</p>
    </div>
  );
};

export default TOS;
