import React from 'react';
import { Box } from '@chakra-ui/react';

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<React.PropsWithChildren<{}>, ErrorBoundaryState> {
  constructor(props: React.PropsWithChildren<{}>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    console.error(error);
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box>
          <h1>Something went wrong.</h1>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
