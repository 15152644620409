import React from 'react';
import {
  Flex,
  Box,
  BoxProps,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import styled from '@emotion/styled';

import ModalDialog from '../components/XModal';

import { ReactComponent as InfoIcn } from '../icons/icn-info.svg';

import './InfoCard.scss';

interface Props extends BoxProps {
  iconColor?: string;
  title: string;
}

const StyleIcon = styled(InfoIcn)`
  path {
    stroke: ${(props) => props.color || '#315D4F40'};
  }
  circle {
    stroke: ${(props) => props.color || '#315D4F40'};
  }
`;

export const InfoTooltip: React.FC<Props> = ({ children, iconColor, title, ...boxProps }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent textAlign={'center'} color="#315D4F" paddingX={3} paddingY={5}>
          <ModalCloseButton top={6} />
          {title && (
            <ModalHeader fontSize={'21px'} pt={0} pb={0}>
              <Flex lineHeight={1.2} alignItems={'center'} height={14} justifyContent={'center'} px={20}>
                {title}
              </Flex>
            </ModalHeader>
          )}

          <ModalBody textAlign={'center'} fontSize={'18px'} pt={4} pb={3} lineHeight={'normal'} opacity={'.5'}>
            {children}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box {...boxProps}>
        <IconButton px={0} size="sm" onClick={onOpen} icon={<StyleIcon color={iconColor} />} variant={'ghost'} aria-label={title} />
      </Box>
    </>
  );
};

export default InfoTooltip;
