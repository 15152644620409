import React, { useState, useEffect } from 'react';
import { Flex, Box, Button, Input, Select, Stack, Text, SimpleGrid, Textarea, Tabs, TabPanels, TabPanel, Center } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import { useNavigate } from 'react-router-dom';

import { ListingFormData } from '../types';
import api from '../api';

import { FieldValidationMessage } from './FieldValidationMessage';
import { FormField } from './FormField';
import { Utils } from '../services';
import { useAuth } from '../AuthContext';
import { useToastContext } from '../ToastContext';
import { ImageUpload } from './ImageUpload';
import { addDays } from 'date-fns';
interface Props {
  onSubmit?: (data: any) => Promise<void>;
  onSubmitted?: () => void;
  listing: any;
  savingListing?: boolean;
}

yup.setLocale({
  mixed: {
    required: 'This field is required',
  },
  string: {
    uuid: 'This field is required',
  },
  number: {},
});

const schema = yup.object().shape({
  title: yup.string().required(),
  description: yup.string().required(),
  categoryId: yup.string().required(),
  subCategoryId: yup.string().required(),
  productId: yup.string().required(),
  condition: yup.string().required(),
  age: yup.string().required(),
  location: yup.object({
    street: yup.string().required(),
    postCode: yup.string().required(),
    city: yup.string().required(),
    country: yup.string().required(),
  }),
  expirationDate: yup.string().required(),
  quantity: yup.string().required(),
});

const ListingForm: React.FC<Props> = ({ onSubmit, onSubmitted, listing, savingListing = false }) => {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm<ListingFormData>({
    defaultValues: {
      ...(!listing
        ? {}
        : {
            ...listing,
            notes: listing.notes || '',
            materials: listing.materials || '',
            category: listing.category?.id,
            subCategory: listing.subCategory?.id,
            expirationDate: new Date(listing.expirationDate),
          }),
    },
    resolver: yupResolver(schema),
  });

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [conditionOptions, setConditionOptions] = useState([]);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { user } = useAuth();
  const navigate = useNavigate();
  const toast = useToastContext();

  const fetchData = async () => {
    try {
      const [categoriesResponse, conditionResponse] = await Promise.all([api.get('/categories'), api.get('/item-conditions')]);

      setCategoryOptions(categoriesResponse.data.data.map(({ id, name }: any) => ({ label: name, value: id })));
      setConditionOptions(conditionResponse.data.data.map(({ id, condition }: any) => ({ label: condition, value: condition })));

      if (listing) {
        setValue('categoryId', listing.product.subCategory.category.id);
        setValue('condition', listing.condition.condition);

        setLoadingSubCategories(true);
        api.get('/subCategories', { params: { category: listing.product.subCategory.category.id } }).then((response) => {
          setLoadingSubCategories(false);
          setSubCategoryOptions(response.data.data.map(({ id, name }: any) => ({ label: name, value: id })));
          setValue('subCategoryId', listing.product.subCategory.id);
        });

        setLoadingProducts(true);
        api.get('/products', { params: { subcategory: listing.product.subCategory.id } }).then((response) => {
          setLoadingProducts(false);
          setProductOptions(response.data.data.map(({ id, name }: any) => ({ label: name, value: id })));
          setValue('productId', listing.product.id);
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [tabIndex, setTabIndex] = useState(0);
  const [listingData, setListingData] = useState(listing);

  const onDone = () => {
    if (listing) {
      if (listingData.status === 'published') {
        if (listing.status === 'draft') {
          navigate(`/marketplace?tab=disposing`);
        }
      }
    } else {
      navigate(`/marketplace/${listingData.id}`);
    }

    if (typeof onSubmitted === 'function') {
      onSubmitted();
    }
  };

  const handleFormSubmit = async (formData: ListingFormData) => {
    try {
      if (tabIndex === 1) {
        onDone();
        return;
      }

      let payload = Utils.cleanPayload({
        ...listing,
        title: formData.title,
        description: formData.description,
        categoryId: formData.categoryId,
        subCategoryId: formData.subCategoryId,
        productId: formData.productId,
        materials: formData.materials,
        condition: formData.condition,
        age: Number(formData.age),
        location: {
          ...formData.location,
        },
        expirationDate: new Date(formData.expirationDate),
        quantity: Number(formData.quantity),
        notes: formData.notes,
      });

      if (typeof onSubmit === 'function') {
        return onSubmit(payload);
      }

      if (listing) {
        if (isDirty) {
          const {
            data: { data },
          } = await api.patch(`/listings/${listing.id}`, payload);
          setListingData(data);
          toast('Saved as draft', 'Your listing has been saved as a draft!', 'success');
        }
      } else {
        const {
          data: { data },
        } = await api.post('/listings', payload);
        setListingData(data);
      }

      setTabIndex(1);
    } catch (error: any) {
      let toastMessage = `An error occurred: \n`;

      error.response.data.errors.forEach(({ message }: any) => (toastMessage += '* ' + message + '\n'));

      setErrorMessage(toastMessage);
    }

    setSaving(false);
  };

  const [loadingSubCategories, setLoadingSubCategories] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type === 'change') {
        if (name === 'categoryId') {
          setLoadingSubCategories(true);
          api.get('/subCategories', { params: { category: value[name] } }).then((response) => {
            setLoadingSubCategories(false);
            setSubCategoryOptions(response.data.data.map(({ id, name }: any) => ({ label: name, value: id })));
          });
          setValue('subCategoryId', '');
          setValue('productId', '');
        } else if (name === 'subCategoryId') {
          setLoadingProducts(true);
          api.get('/products', { params: { subcategory: value[name] } }).then((response) => {
            setLoadingProducts(false);
            setValue('productId', '');
            setProductOptions(response.data.data.map(({ id, name }: any) => ({ label: name, value: id })));
          });
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  const isAdmin = user?.userType === 'client admin';
  const isMine = listing?.createdBy?.id === user?.id;
  const isDraft = listing?.status === 'draft';

  let isMyDraft = isMine && isDraft;

  const editingDisabled = isAdmin && listing && !isMyDraft;

  const [uploading, setUploading] = useState(false);

  const handleImageUploadChange = async (file: File) => {
    let formData = new FormData();

    formData.append('files', file);

    try {
      setUploading(true);
      await api.patch(`/listings/${listingData.id}/images`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const {
        data: { data },
      } = await api.get(`/listings/${listingData.id}`);
      setListingData(data);
    } catch (error) {
      toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }

    setUploading(false);
  };

  const handlePublish = async () => {
    await api.patch(`/listings/${listingData.id}`, {
      publish: true,
    });
    toast('Well done!', 'Your listing has been published successfully!', 'success');

    onDone();
  };

  return (
    <Box as="form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Tabs index={tabIndex}>
        <TabPanels>
          <TabPanel p="0">
            <Stack gap={4} rowGap={1}>
              <Text color="brand.900" fontSize={'md'} mb={4}>
                Here you can easily save your listings as drafts or publish them.
              </Text>

              <FormField
                fieldName={'title'}
                errors={errors}
                control={control}
                render={({ field }: any) => <Input {...field} autoFocus placeholder={'Title*'} />}
              />

              <SimpleGrid columns={2} gap={4} rowGap={0}>
                <FormField
                  fieldName={'description'}
                  errors={errors}
                  control={control}
                  render={({ field }: any) => <Textarea {...field} minHeight={'150px'} placeholder={'Description*'} resize="none" />}
                />

                <FormField
                  fieldName={'notes'}
                  errors={errors}
                  control={control}
                  render={({ field }: any) => <Textarea {...field} minHeight={'150px'} placeholder={'Notes'} resize="none" />}
                />
              </SimpleGrid>
            </Stack>

            <SimpleGrid columns={3} gap={4} rowGap={1} mt={1}>
              <FormField
                fieldName={'categoryId'}
                errors={errors}
                control={control}
                render={({ field }: any) => (
                  <Select {...field} placeholder="Category*" disabled={editingDisabled}>
                    {categoryOptions.map(({ label, value }: any) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </Select>
                )}
              />

              <FormField
                fieldName={'subCategoryId'}
                errors={errors}
                control={control}
                render={({ field }: any) => (
                  <Select
                    {...field}
                    placeholder={loadingSubCategories ? 'Loading...' : 'Sub-Category*'}
                    disabled={loadingSubCategories || editingDisabled}
                  >
                    {subCategoryOptions.map(({ label, value }: any) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </Select>
                )}
              />

              <FormField
                fieldName={'productId'}
                errors={errors}
                control={control}
                render={({ field }: any) => (
                  <Select
                    {...field}
                    placeholder={loadingSubCategories ? 'Loading...' : 'Product*'}
                    disabled={loadingProducts || editingDisabled}
                  >
                    {productOptions.map(({ label, value }: any) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </Select>
                )}
              />

              <FormField
                fieldName={'materials'}
                errors={errors}
                control={control}
                render={({ field }: any) => <Input {...field} placeholder={'Materials'} disabled={editingDisabled} />}
              />

              <FormField
                fieldName={'condition'}
                errors={errors}
                control={control}
                render={({ field }: any) => (
                  <Select {...field} placeholder="Condition*" disabled={editingDisabled}>
                    {conditionOptions.map(({ label, value }: any) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </Select>
                )}
              />

              <FormField
                fieldName={'age'}
                errors={errors}
                control={control}
                render={({ field }: any) => <Input {...field} placeholder="Age (years)*" type="number" disabled={editingDisabled} />}
              />

              <FormField
                fieldName={'quantity'}
                errors={errors}
                control={control}
                render={({ field }: any) => <Input {...field} placeholder="Quantity*" type="number" disabled={editingDisabled} />}
              />

              <FormField
                fieldName={'expirationDate'}
                errors={errors}
                control={control}
                render={({ field: { onChange, value } }: any) => (
                  <SingleDatepicker
                    date={value || addDays(new Date(), 1)}
                    onDateChange={onChange}
                    minDate={new Date()}
                    configs={{
                      dateFormat: 'dd/MM/yyyy',
                    }}
                  />
                )}
              />
              {/* </SimpleGrid>
      <SimpleGrid columns={3} gap={4} rowGap={1}> */}
              <FormField
                fieldName={'location.street'}
                errors={errors}
                control={control}
                render={({ field }: any) => <Input {...field} placeholder="Street*" disabled={editingDisabled} />}
              />

              <FormField
                fieldName={'location.postCode'}
                errors={errors}
                control={control}
                render={({ field }: any) => <Input {...field} placeholder="Postal code*" disabled={editingDisabled} />}
              />

              <FormField
                fieldName={'location.city'}
                errors={errors}
                control={control}
                render={({ field }: any) => <Input {...field} placeholder="City*" disabled={editingDisabled} />}
              />

              <FormField
                fieldName={'location.country'}
                errors={errors}
                control={control}
                render={({ field }: any) => <Input {...field} placeholder="Country*" disabled={editingDisabled} />}
              />
            </SimpleGrid>

            {errorMessage && (
              <FieldValidationMessage mt={-2} pb={6}>
                {errorMessage}
              </FieldValidationMessage>
            )}

            <Flex direction="row" pt={6} justifyContent={'end'}>
              <Button type="submit" isLoading={saving || savingListing}>
                Next
              </Button>
            </Flex>
          </TabPanel>
          <TabPanel p="0">
            <Text color="brand.900" fontSize={'md'} mb={6}>
              Here you can easily upload an image. Skip image upload by clicking done or publish. You can upload further images once you
              have published your listing.
            </Text>
            <Center>
              <ImageUpload onImageChange={handleImageUploadChange} />
            </Center>
            <Flex direction="row" pt={6} justifyContent={'end'}>
              <Button type="submit" isLoading={saving || savingListing} variant={'outline'}>
                Save as draft
              </Button>
              {listingData && listingData.status === 'draft' && (
                <Button onClick={handlePublish} ml={6}>
                  Publish
                </Button>
              )}
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default ListingForm;
