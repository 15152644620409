import React, { createContext, useContext, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { useAuth } from './AuthContext';

const SocketContext = createContext<Socket | null>(null);

export const useSocket = () => {
  return useContext(SocketContext);
};

interface SocketProviderProps {
  children: React.ReactNode;
}

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const { getAuthToken } = useAuth();
  const [socket, setSocket] = useState<Socket | null>(null);

  const token = getAuthToken();

  useEffect(() => {
    const newSocket = io('ws://api.dev.xsilio.velocitech.io', {
      autoConnect: false,
      auth: {
        token,
      },
      extraHeaders: {
        ...(token ? { authorization: token } : {}),
      },
    });

    newSocket.connect();

    newSocket.on('notifications', (notification) => {
      console.log(notification);
    });

    newSocket.on('chat', (notification) => {
      console.log(notification);
    });

    setSocket(newSocket);

    return () => {
      if (!socket) {
        return;
      }

      socket.off('notifications');
      socket.off('chat');
      socket.off('connectedUserCount');
      socket.off('authentication');
      socket.disconnect();
    };
  }, []);

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};
