import { avatarAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(avatarAnatomy.keys);

const smSize = defineStyle({
  width: 12,
  height: 12,
  fontWeight: 700,
});

const mdSize = defineStyle({
  width: 14,
  height: 14,
  fontSize: '28px',
  fontWeight: 700,
});

const threeXlSize = defineStyle({
  width: '234px',
  height: '234px',
  fontSize: '72px',
  fontWeight: 700,
});

const sizes = {
  sm: definePartsStyle({ container: smSize }),
  md: definePartsStyle({ container: mdSize }),
  '3xl': definePartsStyle({ container: threeXlSize }),
};

const baseStyle = definePartsStyle({
  excessLabel: {
    ml: 1,
    fontSize: 'sm',
    width: '34px',
    height: '34px',
  },
});

export const Avatar = defineMultiStyleConfig({ sizes, defaultProps: { size: 'md' }, baseStyle });
