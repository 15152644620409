const Privacy = () => {
  return (
    <div className="tos-privacy">
      <p>This is privacy notice of XSILIO Limited.</p>
      <p>
        We respect your privacy and are determined to protect your personal data. The purpose of this privacy notice is to inform you as to
        how we look after your personal data when you visit our platform (regardless of where you visit it from). We&rsquo;ll also tell you
        about your privacy rights and how data protection law protects you.
      </p>
      <p>This privacy notice is provided in a layered format so you can click through to the specific areas set out below.</p>
      <br />
      <p>
        <strong>1. WHO WE ARE AND IMPORTANT INFORMATION</strong>
      </p>
      <p>
        <strong>2. THE PERSONAL DATA WE COLLECT ABOUT YOU</strong>
      </p>
      <p>
        <strong>3. HOW WE COLLECT YOUR PERSONAL DATA</strong>
      </p>
      <p>
        <strong>4. HOW WE USE YOUR PERSONAL DATA</strong>
      </p>
      <p>
        <strong>5. WHO WE SHARE YOUR PERSONAL DATA WITH</strong>
      </p>
      <p>
        <strong>6. INTERNATIONAL TRANSFERS</strong>
      </p>
      <p>
        <strong>7. DATA SECURITY</strong>
      </p>
      <p>
        <strong>8. DATA RETENTION</strong>
      </p>
      <p>
        <strong>9. YOUR LEGAL RIGHTS</strong>
      </p>
      <p>
        <strong>10. Changes to this notice and your duty to inform us of changes</strong>
      </p>
      <p>
        <strong>11. QUERIES, REQUESTS OR CONCERNS</strong>
      </p>
      <p>
        <strong>1. WHO WE ARE AND IMPORTANT&nbsp;INFORMATION&nbsp;</strong>
      </p>
      <br />
      <h1>What is the purpose of this privacy notice?</h1>
      <p>
        This privacy notice aims to give you information on how we collect and process your personal data through your use of this platform,
        including any data you may provide through this platform when you login and utilise the platform for asset reuse.
      </p>
      <p>This platform is not intended for children and we do not knowingly collect data relating to children.</p>
      <p>
        You must read this privacy notice together with any other privacy notice we may provide on specific occasions when we are collecting
        or processing personal data about you so that you are fully aware of how and why we are using your data. This privacy notice
        supplements the other notices and is not intended to override them.
      </p>
      <p>
        <strong>Data controller(s)</strong>
      </p>
      <p>
        XSILIO Limited is the controller and responsible for your personal data (collectively referred to as &ldquo;XSILIO&rdquo;, "we",
        "us" or "our" in this privacy notice). Our contact details are Springbank Offices, 93 Macclesfield Road, Prestbury SK104AG. For all
        data matters contact support@xsilio.com
      </p>
      <h2>Third-party links outside of our control</h2>
      <p>
        This platform may include links to third-party platforms, plug-ins and applications. Clicking on those links or enabling those
        connections may allow third parties to collect or share data about you. We do not control these third-party platforms and are not
        responsible for their privacy statements.
      </p>
      <p>When you leave our platform, we encourage you to read the privacy notice of every platform you visit.</p>
      <h1>2. THE PERSONAL DATA WE COLLECT ABOUT YOU</h1>
      <p>
        Personal data, or personal information, means any information about an individual from which that person can be identified. You can
        find out more about personal data from the <a href="https://ico.org.uk/global/contact-us/">Information Commissioners Office</a>.
      </p>
      <p>We may collect, use, store and transfer different kinds of personal data about you which we have grouped together follows</p>
      <ul>
        <li>
          <strong>Identity Data</strong>&nbsp;includes first name, last name and title.
        </li>
        <li>
          <strong>Contact Data</strong>&nbsp;includes email address and phone number.
        </li>
      </ul>
      <p>
        We do not collect any&nbsp;<strong>Special Categories of Personal Data</strong>&nbsp;about you (this includes details about your
        race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership,
        information about your health and genetic and biometric data). Nor do we collect any information about criminal convictions and
        offences.
      </p>
      <p>
        <h2>If you fail to provide personal data </h2>
      </p>
      <p>
        Where we need to collect your personal data by law, or under the terms of a contract we have with you and you fail to provide that
        data when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to
        provide you with goods or services). In this case, we may have to cancel a product or service you have with us but we will notify
        you if this is the case at the time.
      </p>

      <h1>3. HOW WE COLLECT YOUR PERSONAL DATA </h1>

      <p>We use different methods to collect data from and about you including through:</p>
      <ul>
        <li>
          <strong>Directly.</strong>&nbsp;You or your employer may give us your identity and contact information by filling in forms or by
          corresponding with us. This includes personal data you provide an account is created on our platform.
        </li>
      </ul>

      <h1>4. HOW WE USE YOUR PERSONAL DATA</h1>

      <p>
        We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following
        circumstances:
      </p>
      <ul>
        <li>
          <strong>Performance of Contract </strong>this&nbsp;means processing your data where it is necessary for the performance of a
          contract to which you are a party or to take steps at your request before entering into such a contract.
        </li>
      </ul>
      <ul>
        <li>
          <strong>Legitimate Interest</strong>&nbsp;this means the interest of our business in conducting and managing our business to
          enable us to give you the best service/product and the most secure experience. We make sure we consider and balance any potential
          impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We
          do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent
          or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests
          against any potential impact on you in respect of specific activities by emailing support@xsilio.com.
        </li>
      </ul>
      <ul>
        <li>
          <strong>Comply with a legal or regulatory obligation</strong>&nbsp;this means processing your personal data where it is necessary
          for compliance with a legal or regulatory obligation that we are subject to.
        </li>
      </ul>
      <p>
        Generally we do not rely on consent as a legal basis for processing your personal data other than in relation to sending third party
        direct marketing communications to you via email or text message. You have the right to withdraw consent to marketing at any time
        by&nbsp;contacting us AT SUPPORT@XSILIO.COM.
      </p>

      <h2>Purposes for which we will use your personal data</h2>

      <p>
        We have set out below, in a table format, a description of all the ways we plan to use your personal data, with the legal bases we
        rely on to do so.
      </p>
      <p>
        Note that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using
        your data. Please contact us&nbsp;support@xsilio.com if you need details about the specific legal ground we are relying on to
        process your personal data where more than one ground has been set out in the table below.
      </p>
      <table cellSpacing="0" cellPadding="0">
        <tbody>
          <tr>
            <td>
              <p>
                <strong>Purpose/Activity</strong>
              </p>
            </td>
            <td>
              <p>
                <strong>Type of data</strong>
              </p>
            </td>
            <td>
              <p>
                <strong>Lawful basis for processing including basis of legitimate interest</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>To register you as a new customer</p>
            </td>
            <td>
              <p>(a) Identity</p>
              <p>(b) Contact</p>
            </td>
            <td>
              <p>Performance of a contract with you or your employer.</p>
            </td>
          </tr>
        </tbody>
      </table>

      <h2>Opting out</h2>

      <p>You can ask us or third parties to stop sending you marketing messages at any time emailing support@xsilio.com.</p>

      <h2>Cookies</h2>

      <p>
        You can set your browser to refuse all or some browser cookies, or to alert you when platforms set or access cookies. If you disable
        or refuse cookies, please note that some parts of this platform may become inaccessible or not function properly.
      </p>

      <h2>Change of purpose</h2>

      <p>
        We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it
        for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the
        processing for the new purpose is compatible with the original purpose, please&nbsp;email support@xsilio.com.
      </p>
      <p>
        If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows
        us to do so.
      </p>
      <p>
        Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this
        is required or permitted by law.
      </p>

      <h1>5. WHO WE SHARE YOUR PERSONAL DATA WITH</h1>

      <p>
        We may have to share your personal data with the parties set out below for the purposes set out in the table in paragraph 4 above.
      </p>

      <ul>
        <li>Internal Third Parties including Velocitech, our technology partner.</li>
        <li>External Third Parties Service</li>
        <ul>
          <li>Providers such as AWS based in the UK who provide data hosting services.</li>
          <li>Professional advisers including lawyers, bankers, auditors and insurers based in the UK .</li>
          <li>HM Revenue &amp; Customs, regulators and other authorities.</li>
        </ul>
        <li>
          Third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets. Alternatively, we may seek to
          acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data
          in the same way as set out in this privacy notice.
        </li>
      </ul>

      <p>
        We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not
        allow our third-party service providers to use your personal data for their own purposes and only permit them to process your
        personal data for specified purposes and in accordance with our instructions.
      </p>

      <h1>6. INTERNATIONAL TRANSFERS</h1>

      <p>We do not transfer your personal data outside the UK.</p>

      <h1>7. DATA SECURITY</h1>

      <p>
        We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in
        an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents,
        contractors and other third parties who have a business need to know. They will only process your personal data on our instructions
        and they are subject to a duty of confidentiality.
      </p>
      <p>
        We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of
        a breach where we are legally required to do so.
      </p>

      <h1>8. DATA RETENTION</h1>

      <p>
        We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the
        purposes of satisfying any legal, accounting, or reporting requirements.
      </p>
      <p>
        To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal
        data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your
        personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.
      </p>
      <p>
        By law we have to keep basic information about our customers (including Contact, Identity, Financial and Transaction Data) for SIX
        years after they cease being customers.
      </p>
      <p>
        In some circumstances you can ask us to delete your data: see <strong>Your legal rights </strong>below for further information.
      </p>
      <p>
        In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for research or
        statistical purposes in which case we may use this information indefinitely without further notice to you.
      </p>

      <h1>9. YOUR LEGAL RIGHTS</h1>

      <p>
        Unless subject to an exemption under the data protection laws, you have the following rights with respect to your personal data:
      </p>
      <ul>
        <li>The right to request a copy of the personal data which we hold about you;</li>
      </ul>
      <ul>
        <li>The right to request that we correct any personal data if it is found to be inaccurate or out of date;</li>
      </ul>
      <ul>
        <li>The right to request your personal data is erased where it is no longer necessary to retain such data;</li>
      </ul>
      <ul>
        <li>
          The right to withdraw your consent to the processing at any time, where consent was the lawful basis for processing your data;
        </li>
      </ul>
      <ul>
        <li>
          The right to request that we provide you with your personal data and where possible, to transmit that data directly to another
          data controller, (known as the right to data portability), where applicable 9i.e. where our processing is based on consent or is
          necessary for the performance of our contract with you or where we process your data by automated means);
        </li>
      </ul>
      <ul>
        <li>
          The right, where there is a dispute in relation to the accuracy or processing of your personal data, to request a restriction is
          placed on further processing;
        </li>
      </ul>
      <ul>
        <li>
          The right to object to our processing of personal data, where applicable i.e. where processing is based on our legitimate
          interests (or in performance of a task in the public interest/exercise of official authority); direct marketing or processing for
          the purposes of scientific/historical research and statistics).
        </li>
      </ul>

      <p>If you wish to exercise any of the rights set out above, please&nbsp;email support@xsilio.com.</p>

      <h2>No fee required &ndash; with some exceptions</h2>

      <p>
        You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a
        reasonable admin fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your
        request in these circumstances.
      </p>

      <h2>What we may need from you</h2>

      <p>
        We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal
        data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any
        person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to
        speed up our response.
      </p>

      <h2>Time limit to respond</h2>

      <p>
        We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is
        particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.
      </p>

      <h1>10. Changes to this notice and your duty to inform us of changes</h1>

      <p>This version was last updated on 01.12.2023</p>
      <p>
        Please keep us informed if your personal data changes during your relationship with us. It is important that the personal data we
        hold about you is accurate and current.
      </p>

      <h1>11. Queries, requests or concerns</h1>

      <p>
        To exercise all relevant rights, queries or complaints in relation to this policy or any other data protection matter between you
        and us, please in the first instance contact support@xsilio.com
      </p>
      <p>
        If this does not resolve your complaint to your satisfaction, you have the right to lodge a complaint with the{' '}
        <a href="https://ico.org.uk/global/contact-us/">Information Commissioners Office</a> on 03031231113 or via email{' '}
        <a href="https://ico.org.uk/global/contact-us/email/">https://ico.org.uk/global/contact-us/email/</a> or at the Information
        Commissioner's Office, Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF, England, UK.
      </p>
    </div>
  );
};

export default Privacy;
