import React from 'react';
import { Button as ChakraButton, SlideFade, useDisclosure, ButtonProps, Box } from '@chakra-ui/react';

import { ReactComponent as IcnArrow } from '../icons/icn-arrow-outline.svg';
import { ReactComponent as IcnDash } from '../icons/icn-dash-outline.svg';

interface Props extends ButtonProps {
  to?: string;
  variant?: string;
}

const Button: React.FC<Props> = ({ children, variant, ...restProps }) => {
  const { isOpen, onToggle } = useDisclosure();

  const handleMouseEnter = () => {
    onToggle();
  };

  const handleMouseLeave = () => {
    onToggle();
  };

  const OFFSET = 24;

  return (
    <ChakraButton {...restProps} variant={variant} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <SlideFade in={isOpen} offsetX={`${-OFFSET}px`} offsetY={0}>
        {variant === 'delete' ? <IcnDash /> : <IcnArrow />}
      </SlideFade>
      <Box transform={isOpen ? `translateX(${OFFSET / 3}px)` : `translateX(${-OFFSET / 2}px)`} transition={'transform 0.2s ease'}>
        {children}
      </Box>
    </ChakraButton>
  );
};

export default Button;
