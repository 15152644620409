import React from 'react';
import { Box, Image, Text, Link } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

interface Props {
  article: any;
}

export const NewsItem: React.FC<Props> = ({ article }) => (
  <Box>
    <Image width={'100%'} fallbackSrc={'/placeholder.jpg'} borderRadius={'15px'} src={article.image} />
    <Text color="brand.900" fontSize={'18px'} fontWeight={'700'} my={4}>
      {article.title}
    </Text>

    <Text fontSize={'14px'} wordBreak={'keep-all'} overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'}>
      {article.description.substring(0, 100)}
    </Text>
    <Text>
      <Link as={NavLink} to={`/news/${article.id}`} fontSize={'14px'} textDecoration={'underline'}>
        Read more
      </Link>
    </Text>
  </Box>
);
