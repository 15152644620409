import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tagAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  container: {
    borderRadius: '50px',
    background: '#E2E8E7',
    paddingY: 2,
    paddingX: 5,
  },
  label: {
    fontSize: '12px',
  },
});

export const Tag = defineMultiStyleConfig({ baseStyle });
