import React from 'react';
import { Center, VStack, Box } from '@chakra-ui/react';

import { ReactComponent as BgCircle } from '../assets/bg-circle.svg';

interface Props {
  children: React.ReactNode;
}

const BgCircleBox: React.FC<any> = ({ size, top, left }) => {
  return (
    <Box position="absolute" height={size} width={size} top={top} left={left}>
      <BgCircle width={size} height={size} />
    </Box>
  );
};

const AuthRoute: React.FC<Props> = ({ children }) => {
  return (
    <>
      <Center height={'100vh'} bg="#315D4F" position={'absolute'} top={0} left={0} bottom={0} right={0} zIndex={-1} overflow={'hidden'}>
        <BgCircleBox size={'235px'} top="-115px" left={'20%'}></BgCircleBox>
        <BgCircleBox size={'700px'} top="362px" left={'0'}></BgCircleBox>
        <BgCircleBox size={'140px'} top="70%" left={'70%'}></BgCircleBox>
        <BgCircleBox size={'235px'} top="40%" left={'90%'}></BgCircleBox>
      </Center>
      <Center height={'92vh'} position={'relative'}>
        <VStack spacing={4} height={'100%'} justifyContent={'space-between'}>
          <Box paddingTop={'9'}>
            <img width={'150px'} src="/xsilio_logo.svg" alt="Xsilio" />
          </Box>
          <Box>{children}</Box>

          <Box textAlign={'center'} color="#fff" fontSize="12px">
            Copyright © XSILIO {new Date().getFullYear()} <br />
            All Rights Reserved.
          </Box>
        </VStack>
      </Center>
    </>
  );
};

export default AuthRoute;
