import React, { useState } from 'react';
import { Box, FormControl, Switch, SimpleGrid, Button, FormLabel, Flex, Spacer } from '@chakra-ui/react';

import { FormFooter } from './FormFooter';

interface Props {
  onSubmit: () => void;
}

const NotificationSettingsForm: React.FC<Props> = ({ onSubmit }) => {
  const settings = [
    'Queries',
    'Responses',
    'Dialogues',
    'Assets',
    'New Messages',
    'Unread Messages',
    'Long Stop Dates',
    'Gamification',
    'Client Licences',
  ];
  const [switches, setSwitches] = useState(settings.reduce((obj, item) => ({ ...obj, [item]: false }), {}));

  const handleSwitchChange = (item: string) => {
    // setSwitches(prevSwitches => ({ ...prevSwitches, [item]: !prevSwitches[item] }));
  };

  const handlePublish = () => {
    onSubmit();
  };

  const handleReset = () => {
    onSubmit();
  };

  return (
    <Box>
      <FormLabel pt={1} pb={5}>
        Here you can easily enable or disable notifications alerts.
      </FormLabel>
      <SimpleGrid columns={3} gap={3}>
        {settings.map((item) => (
          <FormControl
            key={item}
            display="flex"
            alignItems="center"
            justifyContent={'space-between'}
            backgroundColor={'#E2E8E7'}
            borderRadius={'30px'}
            height={'48px'}
            px={5}
          >
            <FormLabel htmlFor={item} padding={0} margin={0} color={'#315D4F'}>
              {item}
            </FormLabel>
            <Switch id={item} onChange={() => handleSwitchChange(item)} size="lg" colorScheme="switch" />
          </FormControl>
        ))}
      </SimpleGrid>

      <FormFooter>
        <Button type="submit" variant={'outline'} onClick={handleReset}>
          Reset
        </Button>
        <Button type="submit" onClick={handlePublish}>
          Publish
        </Button>
      </FormFooter>
    </Box>
  );
};

export default NotificationSettingsForm;
