import { tableAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  table: {},
  th: {
    fontFamily: 'heading',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: 'wider',
    textAlign: 'start',
  },
});

const variantSimple = definePartsStyle((props) => {
  const { colorScheme: c } = props;

  return {
    th: {
      color: mode('gray.600', 'gray.400')(props),
      borderBottom: 'none',
    },
    td: {
      borderBottom: 'none',
    },
  };
});

export const Table = defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    variant: 'simple',
    size: 'md',
    colorScheme: 'gray',
  },
  variants: {
    simple: variantSimple,
  },
});
