import React, { useEffect } from 'react';
import { InputGroup, InputLeftElement, Input } from '@chakra-ui/react';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as IcnSearch } from '../icons/icn-search.svg';

const SearchBar: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = React.useState(searchParams.get('query') || '');

  const search = () => {
    if (location.pathname !== '/search') {
      navigate('/search');
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    setTimeout(() => {
      setSearchParams({ query: event.target.value });
      search();
    }, 300);
  };

  useEffect(() => {
    if (location.pathname !== '/search') {
      setValue('');
    }
  }, [location.pathname]);

  return (
    <InputGroup borderColor="transparent">
      <InputLeftElement pointerEvents="none" pt={1} pl={1}>
        <IcnSearch />
      </InputLeftElement>
      <Input
        placeholder="Search..."
        bg="#fff"
        size="lg"
        _placeholder={{ color: '#878790' }}
        color="#000"
        value={value}
        onChange={handleChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            search();
          }
        }}
      />
    </InputGroup>
  );
};

export default SearchBar;
