import React, { useEffect } from 'react';
import { Grid, GridItem } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../AuthContext';
import { useUIContext } from '../UIContext';

import SideBar from '../components/SideBar';
import TopBar from '../components/TopBar';

import { ReactComponent as IcnHome } from '../icons/icn-home.svg';
import { ReactComponent as IcnScore } from '../icons/icn-score.svg';
import { ReactComponent as IcnListings } from '../icons/icn-listings.svg';
import { ReactComponent as IcnMarketplace } from '../icons/icn-marketplace.svg';
import { ReactComponent as IcnNews } from '../icons/icn-news.svg';
import { ReactComponent as IcnMessages } from '../icons/icn-messages.svg';
import { ReactComponent as IcnUser } from '../icons/icn-user.svg';
import { ReactComponent as IcnHelp } from '../icons/icn-help.svg';
import { ReactComponent as IcnUsers } from '../icons/icn-users.svg';
import { ReactComponent as IcnReports } from '../icons/icn-reports.svg';
interface ClientRouteProps {
  children: React.ReactNode;
}

const ClientRoute: React.FC<ClientRouteProps> = ({ children }) => {
  const { user, userLoading, getUserData, isAffiliate } = useAuth();
  const { isSidebarOpen } = useUIContext();

  const navigate = useNavigate();

  const getUser = () => {
    getUserData();
  };

  useEffect(() => {
    getUser();
  }, []);

  const checkUser = () => {
    if (!userLoading && user === null) {
      navigate('/login');
    }
  };

  useEffect(() => {
    checkUser();
  }, [userLoading]);

  if (userLoading) {
    return null;
  }

  if (user === null) {
    return null;
  }

  if (user.userType === 'xsilio admin' || user.userType === 'systems admin') {
    navigate('/admin/home');
    return null;
  }

  const topRoutes = [
    {
      route: '/',
      label: 'Home',
      icon: <IcnHome />,
    },
    {
      route: '/marketplace',
      label: 'Marketplace',
      icon: <IcnMarketplace />,
    },
    {
      route: '/score',
      label: 'impactX Score',
      icon: <IcnScore />,
    },
    {
      route: '/messages',
      label: 'Messages',
      icon: <IcnMessages />,
    },
    {
      route: '/news',
      label: 'News & Updates',
      icon: <IcnNews />,
    },
    {
      route: '/profile',
      label: 'Profile',
      icon: <IcnUser />,
    },
  ];

  // client admin,client user, affiliate partner admin, affiliate partner user
  let midlleRoutes: any = [];

  if (user.userType === 'client admin') {
    midlleRoutes = midlleRoutes.concat([
      {
        route: '/departments',
        label: 'Departments',
        icon: <IcnUsers />,
        title: 'Departments',
      },
      {
        route: '/users',
        label: 'Users',
        icon: <IcnUsers />,
        title: 'Users',
      },
      {
        route: '/listings',
        label: 'Listings',
        icon: <IcnListings />,
        title: 'Listings',
      },
      {
        route: '/reports',
        label: 'Reports',
        icon: <IcnReports />,
        title: 'Reports',
      },
      {
        route: '/articles',
        label: 'News',
        icon: <IcnNews />,
      },
    ]);
  } else if (user.userType === 'affiliate partner admin') {
    midlleRoutes = midlleRoutes.concat([
      {
        route: '/users',
        label: 'Users',
        icon: <IcnUsers />,
        title: 'Users',
      },
      ...(isAffiliate()
        ? []
        : [
            {
              route: '/reports',
              label: 'Reports',
              icon: <IcnReports />,
              title: 'Reports',
            },
          ]),
    ]);
  }

  const bottomRoutes = [
    {
      route: '/help',
      label: 'Help & Support',
      icon: <IcnHelp />,
    },
  ];

  return (
    user && (
      <Grid
        templateAreas={`"nav header"
                  "nav main"`}
        gridTemplateColumns={`${!isSidebarOpen ? 86 : 250}px 1fr`}
        gridTemplateRows={'120px 1fr'}
        minHeight={'720px'}
      >
        <GridItem area={'nav'} position={'fixed'} h="100%" zIndex={5}>
          <SideBar topRoutes={topRoutes} bottomRoutes={bottomRoutes} midlleRoutes={midlleRoutes} />
        </GridItem>
        <GridItem area={'header'}>
          <TopBar />
        </GridItem>
        <GridItem area={'main'} px={6} pb={4}>
          {children}
        </GridItem>
      </Grid>
    )
  );
};

export default ClientRoute;
