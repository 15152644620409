import { useEffect } from 'react';
import { Route, useNavigate } from 'react-router-dom';

import AdminRoute from './AdminRoute';

import AdminUsers from '../pages/admin/AdminUsers';
import AdminHome from '../pages/admin/AdminHome';
import AdminReports from '../pages/admin/AdminReports';
import AdminOrganizations from '../pages/admin/AdminOrganizations';
import AdminCategories from '../pages/admin/AdminCategories';
import AdminSubCategories from '../pages/admin/AdminSubCategories';
import AdminProducts from '../pages/admin/AdminProducts';
import AdminAffiliates from '../pages/admin/AdminAffiliates';
import AdminNews from '../pages/admin/AdminNews';
import ProfilePage from '../pages/ProfilePage';

const Redirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/admin/home');
  }, [navigate]);

  return null;
};

export const useAdminRoutes = () => {
  return (
    <>
      <Route path="/admin" element={<Redirect />} />
      <Route
        path="/admin/home"
        element={
          <AdminRoute>
            <AdminHome />
          </AdminRoute>
        }
      />
      <Route
        path="/admin/organizations"
        element={
          <AdminRoute>
            <AdminOrganizations />
          </AdminRoute>
        }
      />
      <Route
        path="/admin/affiliates"
        element={
          <AdminRoute>
            <AdminAffiliates />
          </AdminRoute>
        }
      />
      <Route
        path="/admin/categories"
        element={
          <AdminRoute>
            <AdminCategories />
          </AdminRoute>
        }
      />
      <Route
        path="/admin/sub-categories"
        element={
          <AdminRoute>
            <AdminSubCategories />
          </AdminRoute>
        }
      />
      <Route
        path="/admin/assets"
        element={
          <AdminRoute>
            <AdminProducts />
          </AdminRoute>
        }
      />
      <Route
        path="/admin/users"
        element={
          <AdminRoute>
            <AdminUsers />
          </AdminRoute>
        }
      />
      <Route
        path="/admin/reports"
        element={
          <AdminRoute>
            <AdminReports />
          </AdminRoute>
        }
      />
      <Route
        path="/admin/news"
        element={
          <AdminRoute>
            <AdminNews />
          </AdminRoute>
        }
      />
      <Route
        path="/admin/profile"
        element={
          <AdminRoute>
            <ProfilePage />
          </AdminRoute>
        }
      />
    </>
  );
};
