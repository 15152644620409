import { Route } from 'react-router-dom';

import AuthRoute from './AuthRoute';

import LoginPage from '../pages/LoginPage';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import ResetPasswordPage from '../pages/ResetPasswordPage';

export const useAuthRoutes = () => {
  return (
    <>
      <Route
        path="/login"
        element={
          <AuthRoute>
            <LoginPage />
          </AuthRoute>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <AuthRoute>
            <ForgotPasswordPage />
          </AuthRoute>
        }
      />{' '}
      <Route
        path="/reset-password"
        element={
          <AuthRoute>
            <ResetPasswordPage />
          </AuthRoute>
        }
      />
    </>
  );
};
