import React, { useEffect, useState } from 'react';
import { Stack } from '@chakra-ui/react';

import api from '../api';
import { ListingType } from '../constants';
import { useAuth } from '../AuthContext';

import PageGrid from '../components/PageGrid';
import DataTable from '../components/ListingsTable';
import DataTabs from '../components/XTabs';
import ScoreCard from '../components/ScoreCard';
import ChallengeCard from '../components/ChallengeCard';
import IncreaseCTACard from '../components/IncreaseCTACard';

const ListingsPage: React.FC = () => {
  const { isAffiliate } = useAuth();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const affiliate = isAffiliate();

  let tabData = [
    {
      label: 'Available',
      content: ListingType.Available,
    },
  ];

  if (affiliate) {
    tabData.push({
      label: 'Closed',
      content: ListingType.Closed,
    });
  } else {
    tabData = [
      {
        label: 'Available',
        content: ListingType.Available,
      },
      {
        label: 'Wishlist',
        content: ListingType.Wishlist,
      },
      {
        label: 'Disposing',
        content: ListingType.Disposing,
      },
      {
        label: 'Closed',
        content: ListingType.Closed,
      },
      {
        label: 'Drafts',
        content: ListingType.Drafts,
      },
    ];
  }

  const [filter, setFilter] = useState<ListingType | null>(null);

  const fetchData = async () => {
    try {
      if (!filter) {
        return;
      }

      setLoading(true);

      let response;
      if (affiliate) {
        const endpointURL = filter === ListingType.Closed ? `/listings/shared-affiliates/closed` : `/listings/shared-affiliates`;

        response = await api.get(endpointURL, {
          params: { limit: 20 },
        });
      } else {
        response = await api.get(`/listings`, {
          params: { limit: 20, ...(filter ? { listingTabType: filter } : {}) },
        });
      }

      setData(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filter]);

  const handleTabChange = (index: number, content: ListingType) => {
    setFilter(content);
  };

  return (
    <PageGrid
      sideBar={
        <Stack gap="6">
          <ScoreCard />
          {!isAffiliate() && <ChallengeCard />}
          {isAffiliate() && filter !== ListingType.Closed && <IncreaseCTACard listings={data} />}
        </Stack>
      }
    >
      <DataTabs data={tabData} onTabChange={handleTabChange} syncWithUrl={true}>
        {filter && (
          <DataTable
            data={data}
            loading={loading}
            onItemChanged={fetchData}
            listingType={filter}
            wishlist={filter === ListingType.Wishlist}
          ></DataTable>
        )}
      </DataTabs>
    </PageGrid>
  );
};

export default ListingsPage;
