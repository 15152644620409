import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Box, CardFooter, Button, Stack, Image } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

import { ReactComponent as InfoIcn } from '../icons/icn-info.svg';

import api from '../api';
import Border from './XBorder';
import TextHeading from './TextHeading';

interface Props {}

const ScoreCard: React.FC<Props> = () => {
  const [news, setNews]: any = useState([]);

  const fetchData = async () => {
    try {
      const response = await api.get(`/news`, { params: { limit: 2 } });
      setNews(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Card>
      <CardHeader>
        <TextHeading title="News and Updates" borderColor="#315D4F">
          <Box paddingTop={2}>{/* <InfoIcn /> */}</Box>
        </TextHeading>
      </CardHeader>
      <CardBody>
        {!news || (news.length === 0 && <Box pt={2}>There are no news to display</Box>)}
        {news.map((article: any, index: number) => (
          <div key={article.id}>
            <Stack direction={'row'} align={'center'} as={NavLink} to={`/news/${article.id}`}>
              <Image src={article.image} width={12} height={12} borderRadius={'50%'} fallbackSrc="/placeholder.jpg" mr={1} />
              <Box overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'}>
                {article.title}
              </Box>
            </Stack>
            {index === 0 && <Border mt={5} mb={6} />}
          </div>
        ))}
      </CardBody>
      <CardFooter paddingTop={3}>
        <Button size="lg" width={'100%'} as={NavLink} to="/news">
          All News
        </Button>
      </CardFooter>
    </Card>
  );
};

export default ScoreCard;
