import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import PageGrid from '../components/PageGrid';
import ScoreCard from '../components/ScoreCard';
import IncreaseCTACard from '../components/IncreaseCTACard';
import ListingDetails from '../components/ListingDetails';
import { useToastContext } from '../ToastContext';
import { useAuth } from '../AuthContext';

import api from '../api';
import { Utils } from '../services';
import { Stack } from '@chakra-ui/react';

export const ListingDetailsPage: React.FC = () => {
  const { isAffiliate } = useAuth();
  const { listingId } = useParams();
  const toast = useToastContext();

  const [listingData, setListingData]: any = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await api.get(isAffiliate() ? `/listings/shared-affiliates/${listingId}` : `/listings/${listingId}`);

      setListingData(data);
      setLoading(false);
    } catch (error) {
      toast('An error occurred', Utils.formatErrorMessage(error), 'error');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PageGrid
      sideBar={
        <Stack gap={6}>
          <IncreaseCTACard listing={listingData} />
          <ScoreCard />
        </Stack>
      }
    >
      <ListingDetails data={listingData} onDataChange={fetchData} loading={loading} />
    </PageGrid>
  );
};

export default ListingDetailsPage;
