import React, { useState, useEffect } from 'react';
import { Box, SimpleGrid, Card, CardBody, CardHeader, CardFooter, Text, IconButton, Toast } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

import TextHeading from '../../components/TextHeading';

import api from '../../api';
import { Utils } from '../../services';
import { useToastContext } from '../../ToastContext';

import { ReactComponent as IcnEdit } from '../../icons/icn-edit.svg';
interface StatsCardProps {
  title: string;
  link: string;
  count: number;
  children?: React.ReactNode;
}

const StatsCard: React.FC<StatsCardProps> = ({ title, count, link }) => {
  return (
    <Card>
      <CardHeader>
        <TextHeading title={title}></TextHeading>
      </CardHeader>
      <CardBody pb="0">
        <Text fontSize={'64px'} color="brand.900" pt="14" lineHeight={'normal'}>
          {count}
        </Text>
      </CardBody>
      <CardFooter pt="0" pl="0" pr="0" pb="0" flexDirection={'column'} bg="#B1C3BD">
        <Box height={'20px'} width={'100%'} bg={'#fff'} borderBottomLeftRadius={'sm'} borderBottomRightRadius={'sm'}></Box>
        <Box display={'flex'} justifyContent={'space-between'} px="3" py="3">
          <Box></Box>
          <IconButton as={NavLink} icon={<IcnEdit width={'18px'} height={'18px'} />} aria-label="Edit" to={link} />
        </Box>
      </CardFooter>
    </Card>
  );
};

const AdminHome: React.FC = () => {
  const [organizationsCount, setOrganizationsCount] = useState(0);
  const [affiliatesCount, setAffiliatesCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const toast = useToastContext();

  const fetchData = async () => {
    try {
      api.get('/organisations').then((res: any) => {
        setOrganizationsCount(res.data.data.length);
      });

      api.get('/affiliate-partner').then((res: any) => {
        setAffiliatesCount(res.data.data.length);
      });

      api.get('/users').then((res: any) => {
        setUserCount(res.data.data.length);
      });
    } catch (error) {
      console.error(error);
      toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box>
      <SimpleGrid columns={3} spacing={10}>
        <StatsCard title="Organisations" count={organizationsCount} link="/admin/organizations" />
        <StatsCard title="Affiliates" count={affiliatesCount} link="/admin/affiliates" />
        <StatsCard title="Users" count={userCount} link="/admin/users" />
      </SimpleGrid>
    </Box>
  );
};

export default AdminHome;
