import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle, defineStyleConfig } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const BASE_FIELD = {
  color: '#878790',
  borderRadius: '50%', // for some reason this is necessary
  borderTopLeftRadius: '30px',
  borderBottomLeftRadius: '30px',
  borderTopRightRadius: '30px',
  borderBottomRightRadius: '30px',
};

const pill = definePartsStyle({
  field: {
    ...BASE_FIELD,
  },
  addon: {},
});

export const Input = defineMultiStyleConfig({
  baseStyle: {
    field: {
      ...BASE_FIELD,
      color: 'brand.900',
    },
  },
  variants: {
    pill,
  },
});

const baseSelect = definePartsStyle({
  // define the part you're going to style
  field: {
    ...BASE_FIELD,
    color: 'brand.900',
  },
  variants: {},
});

const baseTextarea = defineStyle({
  ...BASE_FIELD,
  borderRadius: '20px',
  paddingY: 4,
});

const sm = defineStyle({});

const selectSizes = {
  sm: definePartsStyle({ field: sm, icon: sm }),
};

export const Select = defineMultiStyleConfig({ baseStyle: baseSelect, sizes: selectSizes });

export const Textarea = defineStyleConfig({ variants: { outline: baseTextarea } });
