import React, { useEffect, useState } from 'react';
import { Tabs, Tab, TabPanel, TabList, TabPanels, Box, TabsProps } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';

import { ListingType } from '../constants';

import { ReactComponent as Curve } from '../assets/curve.svg';
interface Props extends TabsProps {
  children: React.ReactNode;
  data: { label: string; content: string }[];
  onTabChange: (index: number, content: ListingType) => void;
  compact?: boolean;
  syncWithUrl?: boolean;
  activeTab?: ListingType;
}

const DataTabs: React.FC<Props> = ({ children, data, onTabChange, compact, syncWithUrl = false, activeTab, ...tabsProps }) => {
  const [tabIndex, setTabIndex] = useState(-1);
  const tabsLength = data.length;
  const isFirstIndex = () => tabIndex === 0;
  const isLastIndex = () => tabIndex === tabsLength - 1;
  const [searchParams, setSearchParams] = useSearchParams();

  const handleTabChange = (index: number) => {
    setTabIndex(index);
    const activeTab = data[index].content;

    if (syncWithUrl) {
      setSearchParams({ tab: activeTab });
    } else {
      onTabChange(index, activeTab as ListingType);
    }
  };

  useEffect(() => {
    if (syncWithUrl) {
      const tab = searchParams.get('tab');

      let seletedTabIndex = 0;
      let seletedTabContent = data[0].content;
      if (tab) {
        seletedTabIndex = data.findIndex(({ content }) => content === tab);
        seletedTabContent = tab;
      }

      setTabIndex(seletedTabIndex);
      onTabChange(seletedTabIndex, seletedTabContent as ListingType);
    } else {
      setTabIndex(activeTab ? data.findIndex(({ content }) => content === activeTab) : 0);
    }
  }, [searchParams]);

  if (tabIndex === -1) {
    return null;
  }

  return (
    <Tabs
      isFitted
      onChange={handleTabChange}
      variant="enclosed"
      borderBottomRadius={'30px'}
      overflow={'hidden'}
      index={tabIndex}
      {...tabsProps}
    >
      <TabList border={'none'}>
        {data.map(({ label }, index) => {
          return (
            <Tab
              key={label}
              _selected={{ color: '#315D4F' }}
              rounded="md"
              borderBottomRadius={0}
              paddingY={compact ? '12px' : '14px'}
              color={compact ? '#87A29A' : '#65877C'}
              fontWeight={'bold'}
              fontSize={compact ? '18px' : '21px'}
              isDisabled={label === ''}
              pointerEvents={label === '' ? 'none' : 'auto'}
            >
              {tabIndex === index && (
                <Box
                  position={'absolute'}
                  top={'1px'}
                  left={'-1px'}
                  right={'-1px'}
                  height={'100%'}
                  background={'#fff'}
                  borderTopRadius="30px"
                >
                  {!isLastIndex() && (
                    <Box position={'absolute'} bottom={'1px'} top={0} right={'-38px'} width={'66px'}>
                      <Curve height={'100%'} />
                    </Box>
                  )}
                  {!isFirstIndex() && (
                    <Box transform="scaleX(-1)" position={'absolute'} left={'-38px'} width={'66px'} bottom={'1px'} top={0}>
                      <Curve height={'100%'} />
                    </Box>
                  )}
                </Box>
              )}
              <Box position="relative">{label}</Box>
            </Tab>
          );
        })}
      </TabList>
      <TabPanels
        bg="#fff"
        border={'none'}
        borderBottomRadius="30px"
        borderTopRightRadius={isLastIndex() ? 0 : '30px'}
        borderTopLeftRadius={isFirstIndex() ? 0 : '30px'}
      >
        {data.map(({ content }, index) => {
          return (
            <TabPanel key={index} paddingX={compact ? 4 : '24px'} py={1}>
              {children}
            </TabPanel>
          );
        })}
      </TabPanels>
    </Tabs>
  );
};

export default DataTabs;
