import React, { useState, useContext, FunctionComponent, useEffect } from 'react';

interface UIContextProps {
  isSidebarOpen: boolean;
  isWidgetBarVisible: boolean;
  toggleSidebar: () => void;
}

const UIContext = React.createContext<UIContextProps | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

const TABLET_BREAKPOINT = 1180;

export const UIProvider: FunctionComponent<Props> = ({ children }) => {
  let windowWidth = window.innerWidth;
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [isWidgetBarVisible, setIsWidgetBarVisible] = useState<boolean>(false);

  const setUIState = () => {
    windowWidth = window.innerWidth;

    const lsSidebarOpen = localStorage.getItem('sidebarOpen');

    setIsSidebarOpen(lsSidebarOpen ? lsSidebarOpen === 'true' : windowWidth > TABLET_BREAKPOINT);
    setIsWidgetBarVisible(windowWidth > TABLET_BREAKPOINT);
  };

  window.onresize = () => {
    setUIState();
  };

  useEffect(() => {
    setUIState();
  }, []);

  function toggleSidebar() {
    localStorage.setItem('sidebarOpen', JSON.stringify(!isSidebarOpen));
    setIsSidebarOpen(!isSidebarOpen);
  }

  return <UIContext.Provider value={{ isSidebarOpen, isWidgetBarVisible, toggleSidebar }}>{children}</UIContext.Provider>;
};

export function useUIContext() {
  const context = useContext(UIContext);
  if (!context) {
    throw new Error('useUIContext must be used within a UIProvider');
  }
  return context;
}
