import { format } from 'date-fns';

export class Utils {
  static formatDate(date: Date) {
    return new Date(date).toLocaleDateString();
  }

  static formatTime(date: Date | string) {
    return format(new Date(date), 'p');
  }

  static formatErrorMessage(error: any) {
    let errorMessage = '';

    if (error.response) {
      error.response.data.errors.forEach(({ message }: any) => (errorMessage += message + '\n'));
    } else {
      console.log(error);
      errorMessage = error.message;
    }

    return errorMessage;
  }

  static cleanPayload(data: any): any {
    return Object.keys(data).reduce((acc: any, key: string) => {
      if (typeof data[key] != 'undefined' && data[key] !== '') {
        acc[key] = data[key];
      }

      return acc;
    }, {});
  }

  static ellipsify(text: string, maxLength: number): string {
    if (text.length <= maxLength) {
      return text;
    }

    return text.slice(0, maxLength) + '...';
  }
}
