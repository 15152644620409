import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(cardAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  container: {
    borderRadius: 'sm',
    boxShadow: 'none',
    // marginX: 6,
    marginY: 0,
    paddingY: 0,
  },

  header: {
    fontSize: '21px',
    marginX: 6,
    marginY: 0,
    paddingX: 0,
    paddingY: 0,
    color: '#315D4F',
    borderColor: '#315D4F',
  },

  footer: {
    borderBottomRadius: 'sm',
  },
});

export const Card = defineMultiStyleConfig({ baseStyle });
