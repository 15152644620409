import React from 'react';
import { Route } from 'react-router-dom';

import PrivateRoute from './ClientRoute';

import HomePage from '../pages/HomePage';
import MessagesPage from '../pages/MessagesPage';
import MessageThreadPage from '../pages/MessageThreadPage';
import NotificationsPage from '../pages/NotificationsPage';
import NewsPage from '../pages/NewsPage';
import AllNewsPage from '../pages/AllNewsPage';
import NewsArticlePage from '../pages/NewsArticlePage';
import ListingsPage from '../pages/ListingsPage';
import ListingDetailsPage from '../pages/ListingDetailsPage';
import ProfilePage from '../pages/ProfilePage';
import ScorePage from '../pages/ScorePage';
import HelpPage from '../pages/HelpPage';
import SettingsPage from '../pages/SettingsPage';
import SearchResultsPage from '../pages/SearchResultsPage';

import AdminUsers from '../pages/admin/AdminUsers';
import AdminDepartments from '../pages/admin/AdminDepartments';
import AdminReports from '../pages/admin/AdminReports';
import AdminNews from '../pages/admin/AdminNews';
import AdminListings from '../pages/admin/AdminListings';
import AdminListingDetails from '../pages/admin/AdminListingDetails';

export const useClientRoutes = () => {
  return (
    <>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <HomePage />
          </PrivateRoute>
        }
      />

      <Route
        path="/marketplace"
        element={
          <PrivateRoute>
            <ListingsPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/search"
        element={
          <PrivateRoute>
            <SearchResultsPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/marketplace/:listingId"
        element={
          <PrivateRoute>
            <ListingDetailsPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/score"
        element={
          <PrivateRoute>
            <ScorePage />
          </PrivateRoute>
        }
      />

      <Route
        path="/messages"
        element={
          <PrivateRoute>
            <MessagesPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/messages/:messageId"
        element={
          <PrivateRoute>
            <MessageThreadPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/news"
        element={
          <PrivateRoute>
            <NewsPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/news/all"
        element={
          <PrivateRoute>
            <AllNewsPage platform={false} />
          </PrivateRoute>
        }
      />

      <Route
        path="/news/platform"
        element={
          <PrivateRoute>
            <AllNewsPage platform={true} />
          </PrivateRoute>
        }
      />

      <Route
        path="/news/:articleId"
        element={
          <PrivateRoute>
            <NewsArticlePage />
          </PrivateRoute>
        }
      />

      <Route
        path="/profile"
        element={
          <PrivateRoute>
            <ProfilePage />
          </PrivateRoute>
        }
      />

      <Route
        path="/help"
        element={
          <PrivateRoute>
            <HelpPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/settings"
        element={
          <PrivateRoute>
            <SettingsPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/notifications"
        element={
          <PrivateRoute>
            <NotificationsPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/users"
        element={
          <PrivateRoute>
            <AdminUsers />
          </PrivateRoute>
        }
      />

      <Route
        path="/departments"
        element={
          <PrivateRoute>
            <AdminDepartments />
          </PrivateRoute>
        }
      />

      <Route
        path="/reports"
        element={
          <PrivateRoute>
            <AdminReports />
          </PrivateRoute>
        }
      />

      <Route
        path="/articles"
        element={
          <PrivateRoute>
            <AdminNews />
          </PrivateRoute>
        }
      />

      <Route path="/listings/" element={<PrivateRoute>{<AdminListings />}</PrivateRoute>} />

      <Route path="/listings/:listingId" element={<PrivateRoute>{<AdminListingDetails />}</PrivateRoute>} />
    </>
  );
};
