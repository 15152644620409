import React from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from '@chakra-ui/react';

import { useUIContext } from '../UIContext';
interface Props {
  children: React.ReactNode;
  label: string;
  to?: string;
  onClick?: () => void;
}
const SideBarLink: React.FC<Props> = ({ children, to, label, onClick }) => {
  const { isSidebarOpen } = useUIContext();

  if (to) {
    return (
      <NavLink onClick={onClick} className="SideBarLink" to={to}>
        {children}
        {isSidebarOpen && <span>{label}</span>}
      </NavLink>
    );
  }

  return (
    <Link onClick={onClick} className="SideBarLink">
      {children}
      {isSidebarOpen && <span>{label}</span>}
    </Link>
  );
};

export default SideBarLink;
