import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Box, Button, Input, Stack, Text, SimpleGrid, GridItem, Flex, Spacer, InputGroup, InputRightElement } from '@chakra-ui/react';

import { FormField } from './FormField';

interface Props {
  onSubmit: (data: any) => any;
  department?: any;
  saving: boolean;
  onDone: () => void;
}

const schema = yup.object().shape({
  oldPassword: yup.string().required(),
  newPassword: yup.string().required().min(8, 'Please choose a password that is at least 8 characters long.'),
});

export const ChangePasswordForm: React.FC<Props> = ({ onSubmit, saving, onDone }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleFormSubmit = async (formData: any) => {
    try {
      await onSubmit(formData);
      onDone();
    } catch (error: any) {
      console.error(error);
    }
  };

  const [showOld, setShowOld] = React.useState(false);
  const [showNew, setShowNew] = React.useState(false);

  return (
    <Box as="form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Text color="brand.900" fontSize={'md'} mb={6}>
        Please enter your old password and new password bellow and click "Change Password" button.
      </Text>

      <SimpleGrid columns={2} gap={4} rowGap={1} mt={1}>
        <GridItem colSpan={2}>
          <Stack gap={4} rowGap={1}>
            <FormField
              fieldName={'oldPassword'}
              errors={errors}
              control={control}
              render={({ field }: any) => (
                <InputGroup size="md">
                  <Input pr="3" type={showOld ? 'text' : 'password'} placeholder="Enter old password" autoFocus {...field} />
                  <InputRightElement width="3">
                    <Button
                      h="calc(100% - 4px)"
                      tabIndex={3}
                      size="xs"
                      px={6}
                      variant={'ghost'}
                      onClick={() => setShowOld(!showOld)}
                      mr="10"
                      bg="#fff"
                      my="2px"
                      borderRadius={'60px'}
                    >
                      {showOld ? 'Hide' : 'Show'}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              )}
            />
            <FormField
              fieldName={'newPassword'}
              errors={errors}
              control={control}
              render={({ field }: any) => (
                <InputGroup size="md">
                  <Input pr="3" type={showNew ? 'text' : 'password'} placeholder="Enter new password" {...field} />
                  <InputRightElement width="3">
                    <Button
                      h="calc(100% - 4px)"
                      size="xs"
                      px={6}
                      tabIndex={4}
                      variant={'ghost'}
                      onClick={() => setShowNew(!showNew)}
                      mr="10"
                      bg="#fff"
                      my="2px"
                      borderRadius={'60px'}
                    >
                      {showNew ? 'Hide' : 'Show'}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              )}
            />
          </Stack>
        </GridItem>
      </SimpleGrid>

      <Flex direction="row" pt={6} justifyContent={'space-between'}>
        <Spacer></Spacer>
        <Button type="submit" isLoading={saving} mr={2}>
          Change password
        </Button>
      </Flex>
    </Box>
  );
};

export default ChangePasswordForm;
