import React, { useEffect, useState } from 'react';
import { Card, CardBody, useDisclosure } from '@chakra-ui/react';

import api from '../../api';

import { AdminDataTable, Column } from '../../components/AdminDataTable';
import { useToastContext } from '../../ToastContext';
import { Utils } from '../../services';
import { ModalDialog } from '../../components/XModal';
import { SubCategoryForm } from '../../components/SubCategoryForm';
import { AddButton } from '../../components/AddButton';

export const AdminSubCategories: React.FC = () => {
  const [data, setData]: any = useState(null);
  const [loading, setLoading] = useState(true);
  const [deleteing, setDeleteing] = useState(false);
  const [saving, setSaving] = useState(false);

  const { isOpen: isAddNewOpen, onOpen: onAddNewOpen, onClose: onAddNewClose } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();

  const toast = useToastContext();

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/subcategories`);

      setData(
        response.data.data.map(({ id, name, category }: any) => ({
          id,
          name,
          category: category.name,
        }))
      );
      setLoading(false);
    } catch (error) {
      toast && toast('An error occurred', Utils.formatErrorMessage(error), 'error');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns: Column[] = [
    {
      header: 'Sub-Category Name',
      accessor: 'name',
      align: 'left',
    },
    {
      header: 'Category',
      accessor: 'category',
      align: 'left',
    },
  ];

  const deleteSubCategory = async (id: string) => {
    setDeleteing(true);
    try {
      await api.delete(`/subcategories/${id}`);
      toast('Successfully deleted', 'SubCategory successfully deleted', 'success');
      fetchData();
    } catch (error) {
      toast && toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }
    setDeleteing(false);
  };

  const addSubCategory = async (data: any) => {
    setSaving(true);
    try {
      await api.post(`/subcategories/`, {
        ...Utils.cleanPayload(data),
      });
      toast('Successfully created', 'SubCategory successfully created', 'success');
      fetchData();
      onAddNewClose();
    } catch (error) {
      toast && toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }
    setSaving(false);
  };

  const [toEdit, setToEdit] = useState(null);

  const editCategory = async (data: any) => {
    setSaving(true);
    try {
      const { id, ...payload } = data;
      await api.patch(`/subcategories/${id}`, payload);
      toast('Successfully updated', 'SubCategory successfully updated', 'success');
      fetchData();
    } catch (error) {
      toast && toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }
    onEditClose();
    setSaving(false);
  };

  const handleEditClick = async ({ id }: any) => {
    const response = await api.get(`/subcategories/${id}`);

    setToEdit(response.data.data);
    onEditOpen();
  };

  return (
    <>
      <ModalDialog size="md" title={`Add Sub-Category`} isOpen={isAddNewOpen} onClose={onAddNewClose}>
        <SubCategoryForm onSubmit={addSubCategory} saving={saving} />
      </ModalDialog>
      <ModalDialog size="md" title={`Edit Sub-Category`} isOpen={isEditOpen} onClose={onEditClose}>
        <SubCategoryForm onSubmit={editCategory} saving={saving} subCategory={toEdit} />
      </ModalDialog>
      <Card>
        <CardBody>
          <AddButton onClick={onAddNewOpen} />
          <AdminDataTable
            data={data}
            loading={loading}
            columns={columns}
            deleteing={deleteing}
            onDelete={deleteSubCategory}
            onEditClick={handleEditClick}
            enableEdit
            noAvatar
          ></AdminDataTable>
        </CardBody>
      </Card>
    </>
  );
};

export default AdminSubCategories;
