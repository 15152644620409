import { alertAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, AlertProps } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(alertAnatomy.keys);

const baseStyle = definePartsStyle((props: AlertProps) => {
  const { status } = props;

  const successBase = status === 'success' && {
    container: {
      bg: '#2dbf3e',
      color: '#fff',
    },
    title: {},
    icon: {
      bg: '#2dbf3e',
      color: '#fff',
    },
  };

  // const warningBase = status === "warning" && {
  //     container: {
  //         background: 'yellow-light.50',
  //         color: 'black',
  //     }
  // };

  // const errorBase = status === "error" && {
  //     container: {
  //         background: 'danger-light.50',
  //         color: 'danger.500',
  //     }
  // };

  // const infoBase = status === "info" && {
  //     container: {
  //         background: 'primary-light.50',
  //         color: 'primary.500',
  //     }
  // };

  return {
    ...successBase,
    // ...warningBase,
    // ...errorBase,
    // ...infoBase
  };
});

export const Alert = defineMultiStyleConfig({ baseStyle });
