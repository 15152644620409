import React, { useState, useEffect } from 'react';
import { Flex, Box, Button, Input, Stack, Text } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { FormField } from './FormField';
import { FieldValidationMessage } from './FieldValidationMessage';

interface Props {
  onSubmit: (data: any) => void;
  category?: any;
  saving: boolean;
}

const schema = yup.object().shape({
  name: yup.string().required(),
});

export const CategoryForm: React.FC<Props> = ({ onSubmit, category, saving }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...(!category
        ? {}
        : {
            name: category.name || '',
          }),
    },
    resolver: yupResolver(schema),
  });

  const [errorMessage, setErrorMessage] = useState('');

  const handleFormSubmit = async (data: any) => {
    try {
      onSubmit({
        ...(category ? { id: category.id } : {}),
        ...data,
      });
    } catch (error: any) {
      let toastMessage = `An error occurred: \n`;

      error.response.data.errors.forEach(({ message }: any) => (toastMessage += '* ' + message + '\n'));

      setErrorMessage(toastMessage);
    }
  };

  return (
    <Box as="form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack gap={4} rowGap={1}>
        <Text color="brand.900" fontSize={'md'} mb={4}>
          Type a new Category name and hit the submit button here.
        </Text>
      </Stack>

      <Stack gap={4} rowGap={1}>
        <FormField
          fieldName={'name'}
          errors={errors}
          control={control}
          render={({ field }: any) => <Input {...field} autoFocus placeholder={'Category Name*'} />}
        />
      </Stack>

      {errorMessage && (
        <FieldValidationMessage mt={-2} pb={6}>
          {errorMessage}
        </FieldValidationMessage>
      )}

      <Flex direction="row" pt={6} justifyContent={'space-between'}>
        <Box />
        <Button type="submit" isLoading={saving} mr={2}>
          Submit
        </Button>
      </Flex>
    </Box>
  );
};

export default CategoryForm;
