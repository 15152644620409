import React, { useState, useEffect } from 'react';
import { Flex, Box, Button, Input, Tabs, TabPanels, TabPanel, Stack, Text, Center, Textarea } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useToastContext } from '../ToastContext';
import { Utils } from '../services';

import { FormField } from './FormField';
import { FieldValidationMessage } from './FieldValidationMessage';
import { ImageUpload } from './ImageUpload';
import api from '../api';

interface Props {
  onSubmit: (data: any) => any;
  onDone: () => void;
  news?: any;
  saving: boolean;
}

const schema = yup.object().shape({
  title: yup.string().required(), //.max(100, 'The maximum character limit is 100.'),
  subtitle: yup.string().required(), //.max(200, 'The maximum character limit is 200.'),
  description: yup.string().required(), //.max(2000, 'The maximum character limit is 2000.'),
});

export const NewsForm: React.FC<Props> = ({ onSubmit, news, saving, onDone }) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      ...(!news
        ? {}
        : {
            title: news.title || '',
            subtitle: news.subtitle || '',
            description: news.description || '',
          }),
    },
    resolver: yupResolver(schema),
  });

  const toast = useToastContext();

  const [newsData, setNewsData] = useState(news || null);

  const [errorMessage, setErrorMessage] = useState('');
  const [uploading, setUploading] = useState(false);

  const [tabIndex, setTabIndex] = useState(0);

  const handleFormSubmit = async (formData: any) => {
    if (tabIndex === 1) {
      onDone();
      return;
    }

    if (!isDirty) {
      setErrorMessage('');
      setTabIndex(1);
      return;
    }
    const payload = {
      title: formData.title || '',
      subtitle: formData.subtitle || '',
      description: formData.description || '',
    };

    try {
      const resp = await onSubmit({
        ...(news ? { id: news.id } : {}),
        ...Utils.cleanPayload(payload),
      });

      if (resp?.data.errors) {
        let toastMessage = `An error occurred: \n`;

        resp.data.errors.forEach(({ message }: any) => (toastMessage += '* ' + message + '\n'));

        setErrorMessage(toastMessage);
      } else {
        setErrorMessage('');
        setNewsData(resp.data);
        setTabIndex(1);
      }
    } catch (error: any) {
      toast && toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }
  };

  const handleImageUploadChange = async (file: File) => {
    setUploading(true);
    let formData = new FormData();
    formData.append('file', file);

    try {
      await api.patch(`/news/image/${newsData.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      setErrorMessage('An error occurred');
    }

    setUploading(false);
  };

  return (
    <Box as="form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Tabs index={tabIndex}>
        <TabPanels>
          <TabPanel p="0">
            <Stack gap={4} rowGap={1}>
              <FormField
                fieldName={'title'}
                errors={errors}
                control={control}
                render={({ field }: any) => <Input {...field} autoFocus placeholder="Title*" />}
              />
              <FormField
                fieldName={'subtitle'}
                errors={errors}
                control={control}
                render={({ field }: any) => <Input {...field} placeholder="Subtitle*" />}
              />
              <FormField
                fieldName={'description'}
                errors={errors}
                control={control}
                render={({ field }: any) => <Textarea {...field} placeholder="Article Description*" minH={'200px'} />}
              />
            </Stack>
          </TabPanel>
          <TabPanel p="0">
            <Text color="brand.900" fontSize={'md'} mb={6}>
              Here you can easily upload an image or skip by clicking save.
            </Text>
            <Center>
              <ImageUpload onImageChange={handleImageUploadChange} imageUrl={newsData?.image} />
            </Center>
          </TabPanel>
        </TabPanels>
      </Tabs>
      {errorMessage && (
        <FieldValidationMessage mt={-2} pb={6}>
          {errorMessage}
        </FieldValidationMessage>
      )}

      <Flex direction="row" pt={6} justifyContent={'space-between'}>
        <Center></Center>
        <Button type="submit" isLoading={saving || uploading} mr={2}>
          {tabIndex === 0 ? 'Next' : 'Done'}
        </Button>
      </Flex>
    </Box>
  );
};

export default NewsForm;
