import React, { useEffect, useState } from 'react';
import { Grid, GridItem, Box, Heading, Center } from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useAuth } from '../AuthContext';
import { useUIContext } from '../UIContext';

import SideBar from '../components/SideBar';
import { AccountPane } from '../components/AccountPane';

import { ReactComponent as IcnHome } from '../icons/icn-home.svg';
import { ReactComponent as IcnUser } from '../icons/icn-user.svg';
import { ReactComponent as IcnNews } from '../icons/icn-news.svg';
import { ReactComponent as IcnHelp } from '../icons/icn-help.svg';
import { ReactComponent as IcnUsers } from '../icons/icn-users.svg';
import { ReactComponent as IcnReports } from '../icons/icn-reports.svg';
import { ReactComponent as IcnSetting } from '../icons/icn-settings.svg';

interface AdminRouteProps {
  children: React.ReactNode;
}

const AdminRoute: React.FC<AdminRouteProps> = ({ children }) => {
  const { user, userLoading, getUserData } = useAuth();
  const { isSidebarOpen } = useUIContext();
  const [pageTitle, setPageTitle] = useState<string>('');

  const navigate = useNavigate();

  const getUser = () => {
    getUserData();
  };

  useEffect(() => {
    if (!user) {
      getUser();
    }
  }, []);

  let location = useLocation();

  const topRoutes = [
    {
      route: '/admin/home',
      label: 'Home',
      icon: <IcnHome />,
      title: 'Xsilio Admin Dashboard',
    },
    {
      route: '/admin/organizations',
      label: 'Organisations',
      icon: <IcnUsers />,
      title: 'Organisations',
    },
    {
      route: '/admin/affiliates',
      label: 'Affiliates',
      icon: <IcnUsers />,
      title: 'Affiliates',
    },
    {
      route: '/admin/users',
      label: 'Users',
      icon: <IcnUsers />,
      title: 'Users',
    },
    {
      route: '/admin/categories',
      label: 'Categories',
      icon: <IcnSetting />,
      title: 'Categories',
    },
    {
      route: '/admin/sub-categories',
      label: 'Sub-Categories',
      icon: <IcnSetting />,
      title: 'Sub-Categories',
    },
    {
      route: '/admin/assets',
      label: 'Assets',
      icon: <IcnSetting />,
      title: 'Assets',
    },
    {
      route: '/admin/reports',
      label: 'Reports',
      icon: <IcnReports />,
      title: 'Reports',
    },
    {
      route: '/admin/news',
      label: 'News & Updates',
      icon: <IcnNews />,
      title: 'News & Updates',
    },
    {
      route: '/admin/profile',
      label: 'Profile',
      icon: <IcnUser />,
      title: 'Profile',
    },
  ];

  const bottomRoutes = [
    {
      route: '/help',
      label: 'Help & Support',
      icon: <IcnHelp />,
      title: 'Help & Support',
    },
  ];
  const handleLocationChange = () => {
    setPageTitle([...topRoutes, ...bottomRoutes].find(({ route }) => location.pathname === route)?.title || '');
  };

  useEffect(() => {
    handleLocationChange();
  }, [location]);

  useEffect(() => {
    if (!userLoading && user === null) {
      navigate('/login');
    }
  }, [userLoading]);

  if (user === null) {
    return null;
  }

  if (userLoading) {
    return null;
  }

  if (user.userType !== 'xsilio admin' && user.userType !== 'systems admin') {
    return <Box>Unauthorized</Box>;
  }

  return (
    <Grid
      templateAreas={`"nav header"
                  "nav main"`}
      gridTemplateColumns={`${!isSidebarOpen ? 86 : 250}px 1fr`}
      gridTemplateRows={'120px 1fr'}
      minHeight={'720px'}
    >
      <GridItem area={'nav'} position={'fixed'} h="100%" zIndex={5}>
        <SideBar topRoutes={topRoutes} bottomRoutes={bottomRoutes} />
      </GridItem>
      <GridItem area={'header'}>
        <Center h="100%" alignItems={'center'} justifyContent={'space-between'}>
          <Heading width={'100%'} fontWeight={'normal'} pl="10">
            {pageTitle}
          </Heading>
          <Grid gridTemplateColumns="repeat(3, 1fr)" width={'100%'} pl={6} pr={8}>
            <GridItem colSpan={2}></GridItem>
            <GridItem minWidth={'250px'}>
              <AccountPane user={user} />
            </GridItem>
          </Grid>
        </Center>
      </GridItem>
      <GridItem area={'main'} px={6}>
        <Box>{children}</Box>
      </GridItem>
    </Grid>
  );
};

export default AdminRoute;
