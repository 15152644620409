export enum ListingType {
  Available = 'available',
  Wishlist = 'wishlist',
  Disposing = 'disposing',
  Closed = 'closed',
  Drafts = 'draft',
  Expired = 'expired',
}

export enum UserType {
  ClentAdmin = 'client admin',
  ClientUser = 'client user',
  AffiliatePartnerUser = 'affiliate partner user',
  AffiliatePartnerAdmin = 'affiliate partner admin',
  XsilioAdmin = 'xsilio admin',
}

const LOOKUP_TABLE_STRING = `1	122 smartphone charges!
10	26 miles driven by car! Nearly a marathon! 
20	nearly 7,000 plastic carrier bags you've prevented from reaching landfill! 
30	20 miles of crawling by car in a congested commute!
40	travelling from London to Glasgow and back by coach! And far less painful.
50	planting a tree and growing it from seedling! That's impressive!
60	using your smartphone for an hour a day for a year! If only we just used them for an hour a day!
70	driving from Leeds to London by petrol car! 
80	nearly 10,000 smartphone charges!
90	binge watching Netflix for 3 hours a day, for 3 years! 
100	5 propane cylinders used for home BBQs!
125	140 pounds of coal burned!
150	385 miles driven by car!
175	21,000 smartphone charges!
200	£500 worth of new welsh gold produced! 
225	switching 9 old incandesent lamps to LEDs!
250	having planted 4 trees! That's awesome! 
275	charging your phone every night for 9 years! 
300	1,000 loads of laundry! If only we could avoid actual laundry! 
350	driving from John O'Groats to Land's End! 
400	a large hot bath every week night for a year! Sounds lovely but this has a huge impact on our environment!
450	savings of a flight from Amsterdam to Rome! 
500	having planted 8 trees! You're actively helping our planet!
550	183,400 carrier bags you've stopped going into landfiull and our seas!
600	two tonnes of nitrogen fertiliser! 
650	3,250 hours of scrolling instagram! That's nearly 9 hours a day! 
700	running the dishwasher twice a day for a year!
750	91,000 smart phone charges!
800	an acre of forest planted! Imagine that!
850	leaving the lights on constantly in a 60 storey building for a year! 
900	removing 500,000 plastic straws from our seas!
950	a whole month of a family's entire electricity consumption! 
1000	fifty trees growing for a year! Yes!
1100	preventing the use of 366,800 plastic carrier bags!
1200	preventing 18 tonnes of glacial ice melting! 
1300	146 gallons of petrol being used up!
1400	streaming the Lord of the Rings trilogy 653 times! [Yawn!]
1500	preventing the production and use of ONE MILLION plastic straws!
1700	a whole garbage truck of waste that doesn't have to ruin our planet!
1800	22,000 single use plastic bottles reaching landfill!
1900	everyone in Portsmouth charging their phone tonight! 
2000	four whole months of an average family's entire electrcity consumption!
2100	planting 2.5 acres of forest! Go you!
2200	preventing 33 tonnes of glacial ice melting! 
2300	100 large trash bags being recycled instead of reaching landfill!
2400	six months worth of an average family driving their petrol car! Imagine taking that off the road!
2500	planting 40 trees and growing them for 10 years! Great news for our planet! 
2600	saving 15,000 drinks cans being thrown into landfill! 
2700	everyone in Cardiff charging their smartphones! 
2800	315 gallons of petrol being burned off into our atmosphere!
2900	nearly ONE MILLION plastic carrier bags!
3000	six months worth of total emissions from one European person!`;

export const lookupTable = LOOKUP_TABLE_STRING.split('\n').map((row: string) => {
  const [key, value] = row.split('\t');
  return [Number(key), value];
});

const BADGE_LOOKUP_TABLE_STRING = `None	Start listing assets that you no longer need or search for assets to repurpose instead of buying new! You can work towards achieveing these badges!
Green Idol	You have earned your Green Idol badge by posting your first listing! Keep going and help us all reduce our carbon impact.
Planet Protector	You have earned your Planet Protector badge by closing the loop and repurposing an asset! You're already having an impact!
Footprint Friend	Yes! By collaborating with your colleagues, you have earned the Footprint Friend badge! Keep working together to reuse assets and save them from reaching landfill. Let's reduce our carbon impact together!
Sustainablity Warrior	You're a Sustainability Warrior! By making available those assets you no longer need, you are helping us build a circular economy!
Eco Champion	You've done it! You've closed the loop on 5 listings and contributed to reducing our carbon footprint. You truly are an Eco Champion!
Net Zero Hero	You've earned the title of Net Zero Hero! By closing the loop on 10 listings, you've helped us get closer to net zero goals!
Circular Collaborator	You have earned your Circular Collaborator badge! Continue to work together with your colleagues to create a circular economy! When you no longer need it, others might!
Resourceful Renovator	Yes! You've earned the Resourceful Renovator badge by exceeding 50 impactX points! Keep going!
RePurpose Prodigy	You earned your Repurpose Prodigy Badge for exceeding 150 impactX points! You are really making an impact on our planet!
SustainaStar	Yes! You're a SustainaStar! You have reached 500 impactX points! Helping us all become more sustainable.
Impact Pioneer	Wow! 1000 impactX points! You earned your Impact Pioneer badge! Keep raising environmental awareness.
Green Guardian	A true Green Guardian! You're top of the tree with 1000 impactX points and 20 closed loops! What you're doing is so important and will help to motivate others!`;

export const badgeLookupTable = BADGE_LOOKUP_TABLE_STRING.split('\n').reduce((acc: any, row: string) => {
  const [key, value] = row.split('\t');

  acc[key] = value;

  return acc;
}, {});
