import React from 'react';
import { Box, Card, CardHeader, CardBody, Stack } from '@chakra-ui/react';

import TextHeading from '../components/TextHeading';
import { Score } from '../components/Score';
import { ScoreGraph } from './ScoreGraph';

interface Props {
  graphData?: any[];
  score: number;
  title: string;
}

const tabData = [
  {
    label: 'impactX',
    content: 'score',
  },
  {
    label: 'Closed loops',
    content: 'closed',
  },
];

const ScorePageCard: React.FC<Props> = ({ graphData, title, score }) => {
  return (
    <Card>
      <CardHeader>
        <TextHeading title={title}></TextHeading>
      </CardHeader>
      <CardBody p={0}>
        <Stack pt={6}>
          <Box width={'100%'}>
            <Score score={score} />
          </Box>
          <Box width={'100%'}>
            <Card bg={'gray.100'} border={'1px solid gray.200'} p="1px" mt={6}>
              {graphData && graphData.length > 0 && <ScoreGraph data={graphData} />}
            </Card>
          </Box>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default ScorePageCard;
