import React from 'react';
import { Grid, GridItem } from '@chakra-ui/react';

import { useUIContext } from '../UIContext';
interface Props {
  children: React.ReactNode;
  sideBar?: React.ReactNode;
}

const PageGrid: React.FC<Props> = ({ children, sideBar }) => {
  const { isWidgetBarVisible } = useUIContext();

  const showWidgets = isWidgetBarVisible && sideBar;

  return (
    <Grid gridTemplateColumns="repeat(3, 1fr)" width={'100%'} gap={6}>
      <GridItem colSpan={showWidgets ? 2 : 3}>{children}</GridItem>
      {showWidgets && <GridItem minWidth={'250px'}>{sideBar}</GridItem>}
    </Grid>
  );
};

export default PageGrid;
