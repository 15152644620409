import React from 'react';
import { Box } from '@chakra-ui/react';

const NotFoundPage: React.FC = () => {
  return (
    <Box>
      <h1>404 Not Found</h1>
    </Box>
  );
};

export default NotFoundPage;
