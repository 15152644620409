import React, { useEffect, useState } from 'react';
import { Card, CardBody, useDisclosure } from '@chakra-ui/react';

import api from '../../api';

import { AdminDataTable, Column } from '../../components/AdminDataTable';
import { useToastContext } from '../../ToastContext';
import { Utils } from '../../services';
import { ModalDialog } from '../../components/XModal';
import { ProductForm } from '../../components/ProductForm';
import { AddButton } from '../../components/AddButton';

export const AdminProducts: React.FC = () => {
  const [data, setData]: any = useState(null);
  const [loading, setLoading] = useState(true);
  const [deleteing, setDeleteing] = useState(false);
  const [saving, setSaving] = useState(false);

  const { isOpen: isAddNewOpen, onOpen: onAddNewOpen, onClose: onAddNewClose } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();

  const toast = useToastContext();

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/products`);

      setData(
        response.data.data.map(({ id, name, subCategory, score }: any) => ({
          id,
          name,
          subCategory,
          score,
        }))
      );
      setLoading(false);
    } catch (error) {
      toast && toast('An error occurred', Utils.formatErrorMessage(error), 'error');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns: Column[] = [
    {
      header: 'Asset Name',
      accessor: 'name',
      align: 'left',
    },
    {
      header: 'Category',
      accessor: 'subCategory.category.name',
      align: 'left',
    },
    {
      header: 'Sub-Category',
      accessor: 'subCategory.name',
      align: 'left',
    },
  ];

  const deleteProduct = async (id: string) => {
    setDeleteing(true);
    try {
      await api.delete(`/products/${id}`);
      fetchData();
      toast('Successfully deleted', 'Asset successfully deleted', 'success');
    } catch (error) {
      toast && toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }
    setDeleteing(false);
  };

  const addProduct = async (data: any) => {
    setSaving(true);
    try {
      await api.post(`/products/`, {
        ...Utils.cleanPayload(data),
      });
      toast('Successfully created', 'Asset successfully created', 'success');
      fetchData();
      onAddNewClose();
    } catch (error) {
      toast && toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }
    setSaving(false);
  };

  const [toEdit, setToEdit] = useState(null);

  const editProduct = async (data: any) => {
    setSaving(true);
    try {
      const { id, ...payload } = data;
      await api.patch(`/products/${id}`, payload);
      toast('Successfully updated', 'Asset successfully updated', 'success');
      fetchData();
    } catch (error) {
      toast && toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }
    onEditClose();
    setSaving(false);
  };

  const handleEditClick = async (product: any) => {
    try {
      setToEdit(product);
      onEditOpen();
    } catch (error) {
      toast && toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }
  };

  return (
    <>
      <ModalDialog size="md" title={`Add Asset`} isOpen={isAddNewOpen} onClose={onAddNewClose}>
        <ProductForm onSubmit={addProduct} saving={saving} />
      </ModalDialog>
      <ModalDialog size="md" title={`Edit Sub-Category`} isOpen={isEditOpen} onClose={onEditClose}>
        <ProductForm onSubmit={editProduct} saving={saving} product={toEdit} />
      </ModalDialog>
      <Card>
        <CardBody>
          <AddButton onClick={onAddNewOpen} />
          <AdminDataTable
            data={data}
            loading={loading}
            columns={columns}
            deleteing={deleteing}
            onDelete={deleteProduct}
            onEditClick={handleEditClick}
            enableEdit
            noAvatar
          ></AdminDataTable>
        </CardBody>
      </Card>
    </>
  );
};

export default AdminProducts;
