import React, { useState, useEffect } from 'react';
import {
  Flex,
  Box,
  Button,
  Input,
  Tabs,
  TabPanels,
  TabPanel,
  Stack,
  Text,
  SimpleGrid,
  GridItem,
  Center,
  Select,
  Textarea,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useToastContext } from '../ToastContext';
import { Utils } from '../services';

import { FormField } from './FormField';
import { FieldValidationMessage } from './FieldValidationMessage';
import { ImageUpload } from './ImageUpload';
import api from '../api';

interface Props {
  onSubmit: (data: any) => any;
  onDone: () => void;
  organization?: any;
  saving: boolean;
}

const schema = yup.object().shape({
  name: yup.string().required(),
  region: yup.string().required(),
  affiliateCategory: yup.string().required(),
  contactName: yup.string(),
  email: yup.string().email(),
  phone: yup.string(),
});

export const AffiliatePartnerForm: React.FC<Props> = ({ onSubmit, organization, saving, onDone }) => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...(!organization
        ? {}
        : {
            name: organization.name || '',
            region: organization.address.state || '',
            category: organization.category || '',
            affiliateCategory: organization.affiliateCategory || '',
            contactName: organization.contactName || '',
            email: organization.email || '',
            phone: organization.phone || '',
          }),
    },
    resolver: yupResolver(schema),
  });

  const toast = useToastContext();

  const [affiliatePartnerData, setAffiliatePartnerData] = useState(organization || null);
  const [errorMessage, setErrorMessage] = useState('');
  const [numberOfLicences, setNumberOfLicences] = useState(null);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    const subscription = watch(async (value, { name, type }: any) => {
      if (type === 'change') {
        if (name === 'affi') {
          const {
            data: { data },
          }: // @ts-ignore
          any = await api.get('/affiliate-partner/' + value.organisationId);

          setNumberOfLicences(data.numberOflicences);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const [tabIndex, setTabIndex] = useState(0);

  const handleFormSubmit = async (formData: any) => {
    if (tabIndex === 1) {
      onDone();
      return;
    }

    const payload = {
      name: formData.name,
      category: formData.category,
      affiliateCategory: formData.affiliateCategory,
      contactName: formData.contactName,
      email: formData.email,
      phone: formData.phone,
      address: {
        state: formData.region,
      },
    };

    try {
      const resp = await onSubmit({
        ...(affiliatePartnerData ? { id: affiliatePartnerData.id } : {}),
        ...Utils.cleanPayload(payload),
      });

      if (resp.data.errors) {
        let toastMessage = `An error occurred: \n`;

        resp.data.errors.forEach(({ message }: any) => (toastMessage += '* ' + message + '\n'));

        setErrorMessage(toastMessage);
      } else {
        setErrorMessage('');
        setAffiliatePartnerData(resp.data);
        setTabIndex(1);
      }
    } catch (error: any) {
      toast && toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }
  };

  const handleImageUploadChange = async (file: File) => {
    setUploading(true);
    let formData = new FormData();
    formData.append('file', file);

    try {
      await api.patch(`/affiliate-partner/${affiliatePartnerData.id}/logo`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      setErrorMessage('An error occurred');
    }

    setUploading(false);
  };

  return (
    <Box as="form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Tabs index={tabIndex}>
        <TabPanels>
          <TabPanel p="0">
            <Text color="brand.900" fontSize={'md'} mb={6}>
              {organization ? `Here you can easily edit an Affiliate Partner.` : `Here you can easily add a new Affiliate Partner.`}
            </Text>

            <SimpleGrid columns={4} gap={4} rowGap={1} mt={1}>
              <GridItem colSpan={2}>
                <Stack gap={4} rowGap={1}>
                  <FormField
                    fieldName={'name'}
                    errors={errors}
                    control={control}
                    render={({ field }: any) => <Input {...field} autoFocus placeholder={'Affiliate Partner Name*'} />}
                  />
                  <FormField
                    fieldName={'region'}
                    errors={errors}
                    control={control}
                    render={({ field }: any) => (
                      <Select {...field} placeholder={'Affiliate Partner Region*'}>
                        <option value="East (England)">East (England)</option>
                        <option value="East Midlands (England)">East Midlands (England)</option>
                        <option value="West Midlands (England)">West Midlands (England)</option>
                        <option value="London">London</option>
                        <option value="North East (England)">North East (England)</option>
                        <option value="North West (England)">North West (England)</option>
                        <option value="South East (England)">South East (England)</option>
                        <option value="South West (England)">South West (England)</option>
                        <option value="Yorkshire and The Humber">Yorkshire and The Humber</option>
                        <option value="Scotland">Scotland</option>
                        <option value="Wales">Wales</option>
                        <option value="Northern Ireland">Northern Ireland</option>
                      </Select>
                    )}
                  />
                  <FormField
                    fieldName={'affiliateCategory'}
                    errors={errors}
                    control={control}
                    render={({ field }: any) => (
                      <Select {...field} placeholder={'Affiliate Category*'}>
                        <option value="Charity">Charity</option>
                        <option value="School">School</option>
                        <option value="Start up">Start up</option>
                      </Select>
                    )}
                  />
                </Stack>
              </GridItem>
              <GridItem colSpan={2}>
                <FormField
                  fieldName={'contactName'}
                  errors={errors}
                  control={control}
                  render={({ field }: any) => <Input {...field} placeholder="Contact Name" />}
                />
                <FormField
                  fieldName={'email'}
                  errors={errors}
                  control={control}
                  render={({ field }: any) => <Input {...field} placeholder="Contact Email" />}
                />
                <FormField
                  fieldName={'phone'}
                  errors={errors}
                  control={control}
                  render={({ field }: any) => <Input {...field} placeholder="Contact Phone" />}
                />
              </GridItem>
              <GridItem colSpan={4}>
                <Stack gap={4} rowGap={1}>
                  <FormField
                    fieldName={'description'}
                    errors={errors}
                    control={control}
                    render={({ field }: any) => <Textarea {...field} placeholder={'Affiliate Partner description'} />}
                  />
                </Stack>
              </GridItem>
            </SimpleGrid>
          </TabPanel>
          <TabPanel p="0">
            <Text color="brand.900" fontSize={'md'} mb={6}>
              Here you can easily upload an image or skip by clicking save.
            </Text>
            <Center>
              <ImageUpload onImageChange={handleImageUploadChange} />
            </Center>
          </TabPanel>
        </TabPanels>
      </Tabs>
      {errorMessage && (
        <FieldValidationMessage mt={-2} pb={6}>
          {errorMessage}
        </FieldValidationMessage>
      )}

      <Flex direction="row" pt={6} justifyContent={'space-between'}>
        <Center>
          {numberOfLicences && (
            <Text color="brand.900" fontSize={'md'}>
              Number of Licenses: <strong>{numberOfLicences}</strong>
            </Text>
          )}
        </Center>
        <Button type="submit" isLoading={saving || uploading} mr={2}>
          {tabIndex === 0 ? 'Next' : 'Save'}
        </Button>
      </Flex>
    </Box>
  );
};

export default AffiliatePartnerForm;
