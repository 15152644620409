import React, { useEffect, useState } from 'react';
import { Card, CardBody, useDisclosure } from '@chakra-ui/react';

import api from '../../api';
import { AdminDataTable, Column } from '../../components/AdminDataTable';
import { ModalDialog } from '../../components/XModal';
import { UserForm } from '../../components/UserForm';
import { AddButton } from '../../components/AddButton';

import { useToastContext } from '../../ToastContext';
import { Utils } from '../../services';
import { useAuth } from '../../AuthContext';

export const AdminUsers: React.FC = () => {
  const { user } = useAuth();
  const [data, setData]: any = useState(null);
  const [loading, setLoading] = useState(true);
  const [deleteing, setDeleteing] = useState(false);
  const [saving, setSaving] = useState(false);

  const toast = useToastContext();

  const { isOpen: isAddNewOpen, onOpen: onAddNewOpen, onClose: onAddNewClose } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();

  const fetchData = async () => {
    try {
      setLoading(true);

      let params: any = {};

      if (user?.organisation) {
        params.organisationId = user.organisation.id;
      }

      if (user?.affiliatePartner) {
        params.affiliatePartnerId = user.affiliatePartner.id;
      }

      const response = await api.get(`/users`, { params });

      setData(
        response.data.data.map(({ id, fullName, score, email, profilePhoto, userType, organisation, department }: any) => ({
          id,
          name: fullName,
          email,
          userType,
          impactX: score.impactXScore,
          image: profilePhoto,
          organisation: organisation?.name,
          department: department?.name,
        }))
      );
      setLoading(false);
    } catch (error) {
      toast && toast('An error occurred', Utils.formatErrorMessage(error), 'error');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const orgColumn: Column = {
    header: 'Organisation',
    accessor: 'organisation',
    align: 'left',
  };

  const columns: Column[] = [
    {
      header: 'User Name',
      accessor: 'fullName',
      align: 'left',
    },
    {
      header: 'Email',
      accessor: 'email',
      align: 'left',
    },
    ...(user?.userType === 'xsilio admin' ? [orgColumn] : []),
    {
      header: 'Department',
      accessor: 'department',
      align: 'left',
    },
    {
      header: 'User Role',
      accessor: 'userType',
      align: 'left',
    },
    {
      header: 'impactX',
      accessor: 'impactX',
      align: 'center',
    },
  ];

  const deleteUser = async (id: string) => {
    setDeleteing(true);
    try {
      await api.delete(`/users/${id}`);
      fetchData();
    } catch (error) {
      toast && toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }
    setDeleteing(false);
  };

  const addUser = async (userData: any) => {
    setSaving(true);
    try {
      const { data } = await api.post(`/users`, userData);
      setSaving(false);
      toast('Successfully created', 'User successfully created', 'success');
      return data;
    } catch (error: any) {
      setSaving(false);
      return error.response;
    }
  };

  const [userToEdit, setUserToEdit] = useState(null);

  const editUser = async (userData: any) => {
    setSaving(true);
    try {
      const { id, ...payload } = userData;
      const { data } = await api.patch(`/users/${id}`, payload);
      toast('Successfully updated', 'User successfully updated', 'success');
      setSaving(false);
      return data;
    } catch (error: any) {
      toast('An error occurred', Utils.formatErrorMessage(error), 'error');
      setSaving(false);
      return error.response;
    }
  };

  const handleEditClick = async ({ id }: any) => {
    const response = await api.get(`/users/${id}`);

    setUserToEdit(response.data.data);
    onEditOpen();
  };

  const handleDone = () => {
    fetchData();
    onAddNewClose();
    onEditClose();
  };

  return (
    <>
      <ModalDialog title={`Add New User`} isOpen={isAddNewOpen} onClose={onAddNewClose}>
        <UserForm onSubmit={addUser} saving={saving} onDone={handleDone} />
      </ModalDialog>
      <ModalDialog title={`Edit User`} isOpen={isEditOpen} onClose={onEditClose}>
        <UserForm onSubmit={editUser} saving={saving} user={userToEdit} onDone={handleDone} />
      </ModalDialog>
      <Card>
        <CardBody>
          <AddButton onClick={onAddNewOpen} />
          <AdminDataTable
            data={data}
            loading={loading}
            columns={columns}
            deleteing={deleteing}
            onDelete={deleteUser}
            onEditClick={handleEditClick}
            enableEdit
          ></AdminDataTable>
        </CardBody>
      </Card>
    </>
  );
};

export default AdminUsers;
