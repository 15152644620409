import React from 'react';
import { Card, CardBody, Text, Box, Center } from '@chakra-ui/react';

import { useAuth } from '../AuthContext';

interface Props {
  listing?: any;
  listings?: any;
}

export const IncreaseCTACard: React.FC<Props> = ({ listing, listings }) => {
  const { user, isAffiliate } = useAuth();
  if ((!listing || (!isAffiliate() && listing.status === 'closed') || listing.createdBy.id === user?.id) && (!listings || !listings.length))
    return null;

  let maxScore = 0;

  if (listings) {
    maxScore = Math.max(...listings.map((item: any) => item.score));
  } else {
    maxScore = listing.score;
  }

  return (
    <Card bg={'brand.900'}>
      <CardBody color={'#fff'}>
        <Text fontSize={'36px'} lineHeight={'42px'} textAlign={'center'} mb={2}>
          <strong>Earn</strong> Points!
        </Text>
        <Box textAlign={'center'} fontSize={'14px'} px={2} lineHeight={'normal'}>
          You can earn {maxScore} impactX points by acquiring the assets in this listing!
        </Box>
        <Center pt={3} pb={4}>
          <Box
            fontSize={'48px'}
            letterSpacing={'2.4px'}
            fontWeight={'600'}
            bg={'#63887b'}
            lineHeight={1.2}
            borderRadius={'36px'}
            textAlign={'center'}
            px={6}
          >
            +{maxScore}
          </Box>
        </Center>
        <Box textAlign={'center'} fontSize={'12px'} px={2} lineHeight={'normal'} color="#DEE5E2">
          That’s the equivalent of cutting your milage by nearly 6000 miles or 99 bags of rubbish being recycled instead of landfilled!
        </Box>
      </CardBody>
    </Card>
  );
};

export default IncreaseCTACard;
