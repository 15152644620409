import React from 'react';
import { Stack, Box, BoxProps, Skeleton } from '@chakra-ui/react';

interface Props extends BoxProps {
  title: string;
  fontSize?: string;
  children?: React.ReactNode;
  borderColor?: string;
  color?: string;
  paddingTop?: number;
  paddingBottom?: number;
  loading?: boolean;
}

const TextHeading: React.FC<Props> = ({
  children,
  title,
  borderColor = '#315D4F',
  paddingBottom,
  paddingTop,
  color,
  fontSize,
  loading,
  ...restProps
}) => {
  return (
    <Stack
      direction={'row'}
      gap={6}
      display={'flex'}
      justifyContent={'space-between'}
      borderBottom={`1px solid ${borderColor}`}
      fontSize={fontSize}
      {...restProps}
    >
      <Box paddingTop={paddingTop || 4} color={color} paddingBottom={paddingBottom || 2} borderBottom={`4px solid ${borderColor}`}>
        <Skeleton isLoaded={!loading}> {title}</Skeleton>
      </Box>
      <Box paddingTop={paddingTop || 5} paddingBottom={paddingBottom}>
        {children}
      </Box>
    </Stack>
  );
};

export default TextHeading;
