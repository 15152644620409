import React, { useState, useEffect, useRef } from 'react';
import { Box, Card, CardHeader, CardBody, SimpleGrid, Button } from '@chakra-ui/react';
import { subMonths, format } from 'date-fns';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, BarElement } from 'chart.js';
import { Bar } from 'react-chartjs-2';

import TextHeading from '../../components/TextHeading';
import api from '../../api';

import { useAuth } from '../../AuthContext';
import { Utils } from '../../services';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, BarElement);

const historyLables: any = [];

for (let i = 12; i > 0; i--) {
  historyLables.push(format(subMonths(new Date(), i), 'MMM'));
}

const getOptions = () => {
  return {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
    },
  };
};

const colors = ['#63b3bf', '#ed15d0', '#a49ff2', '#C9403E', '#f2dc9b', '#d6543e', '#9ef7cf', '#c303c6', '#1f63a3'];

let colorIndex = 0;
const getColor = () => {
  const color = colors[colorIndex % colors.length];
  colorIndex++;
  return color;
};

const AdminReports: React.FC = () => {
  const { user } = useAuth();

  const [currentScoresData, setCurrentScoresData] = useState();
  const [currentLoopsData, setCurrentLoopsData] = useState();

  const [organisationCurrentScore, setOrganisationCurrentScore] = useState(0);
  const [organisationClosedLoops, setOrganisationClosedLoops] = useState(0);

  const [monthlyScoresData, setMonthlyScoresData] = useState();
  const [monthlyLoopsData, setMonthlyLoopsData] = useState();

  const [monthlyScoresUserData, setMonthlyScoresUserData] = useState();
  const [monthlyLoopsUserData, setMonthlyLoopsUserData] = useState();

  const fetchClientAdminData = async () => {
    try {
      api.get(`/organisations/${user?.organisation?.id}`).then(({ data }: any) => {
        setOrganisationCurrentScore(data.data.score.impactXScore);
        setOrganisationClosedLoops(data.data.score.closeLoop);
      });

      api.get(`/departments/organization/${user?.organisation?.id}`).then(({ data }: any) => {
        const labels = data.data.map(({ name }: any) => name);
        const scores = data.data.map(({ score }: any) => score.impactXScore);
        const loops = data.data.map(({ score }: any) => score.closeLoop);

        const currentScores = {
          labels,
          datasets: [
            {
              label: 'impactX score',
              data: scores,
              backgroundColor: `${getColor()}`,
            },
          ],
        };

        const currentLoops = {
          labels,
          datasets: [
            {
              label: 'Closed loops',
              data: loops,
              backgroundColor: `${getColor()}`,
            },
          ],
        };

        setCurrentScoresData(currentScores as any);
        setCurrentLoopsData(currentLoops as any);
      });

      api.get(`/records/annual-report/organisation-departments/${user?.organisation?.id}`).then(({ data }: any) => {
        const labels = historyLables;
        const EMPTY_DATA = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        const scoreDatasets = data.data.map(({ departmentName, reports }: any) => {
          // @ts-ignore
          const data = [...EMPTY_DATA, ...reports.map(({ impactXScore }) => impactXScore)];
          return {
            label: departmentName,
            data: data.splice(data.length - 12, data.length),
            backgroundColor: `${getColor()}`,
          };
        });

        const loppsDatasets = data.data.map(({ departmentName, reports }: any) => {
          // @ts-ignore
          const data = [...EMPTY_DATA, ...reports.map(({ closeLoop }) => closeLoop)];
          return {
            label: departmentName,

            data: data.splice(data.length - 12, data.length),
            backgroundColor: `${getColor()}`,
          };
        });

        setMonthlyScoresData({
          labels,
          datasets: scoreDatasets,
        } as any);

        setMonthlyLoopsData({
          labels,
          datasets: loppsDatasets,
        } as any);
      });

      //     const [monthlyScoresUserData, setMonthlyScoresUserData] = useState();
      // const [monthlyLoopsUserData, setMonthlyLoopsUserData] = useState();
      api.get(`/records/annual-report/organisation-users/${user?.organisation?.id}`).then(({ data }: any) => {
        const labels = historyLables;
        const EMPTY_DATA = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        let graphData = data.data;

        graphData = graphData.splice(0, 5);

        const scoreDatasets = graphData.map(({ userName, reports }: any) => {
          // @ts-ignore
          const data = [...EMPTY_DATA, ...reports.map(({ impactXScore }) => impactXScore)];
          return {
            label: userName,

            data: data.splice(data.length - 12, data.length),
            backgroundColor: `${getColor()}`,
          };
        });

        const loppsDatasets = graphData.map(({ userName, reports }: any) => {
          // @ts-ignore
          const data = [...EMPTY_DATA, ...reports.map(({ closeLoop }) => closeLoop)];
          return {
            label: userName,

            data: data.splice(data.length - 12, data.length),
            backgroundColor: `${getColor()}`,
          };
        });

        setMonthlyScoresUserData({
          labels,
          datasets: scoreDatasets,
        } as any);

        setMonthlyLoopsUserData({
          labels,
          datasets: loppsDatasets,
        } as any);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const [organisationScoresData, setOrganisationScoresData] = useState();
  const [organisationLoopsData, setOrganisationLoopsData] = useState();
  const fetchXsilioAdminData = async () => {
    try {
      api.get(`/organisations`).then(({ data }: any) => {
        const labels = data.data.map(({ name }: any) => Utils.ellipsify(name, 15));
        const scores = data.data.map(({ score }: any) => score.impactXScore);
        const loops = data.data.map(({ score }: any) => score.closeLoop);

        const currentScores = {
          labels,
          datasets: [
            {
              label: 'impactX score',
              data: scores,
              backgroundColor: `${getColor()}`,
            },
          ],
        };

        const currentLoops = {
          labels,
          datasets: [
            {
              label: 'Closed loops',
              data: loops,
              backgroundColor: `${getColor()}`,
            },
          ],
        };

        setOrganisationScoresData(currentScores as any);
        setOrganisationLoopsData(currentLoops as any);
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (user?.userType === 'xsilio admin') {
      fetchXsilioAdminData();
    } else {
      fetchClientAdminData();
    }
  }, [user]);

  const pageRef = useRef<any>(null);

  const [savingPDF, setSavingPDF] = useState(false);

  const getReportTitle = () => (user?.organisation ? 'Reports - ' + user?.organisation.name : 'Reports - Xsilio platform ');

  const handleDownloadClick = () => {
    setSavingPDF(true);
    // @ts-ignore
    const html2pdf = window.html2pdf;

    // add a small delat while PDF is being generated
    setTimeout(() => {
      html2pdf()
        .from(pageRef.current)
        .set({
          margin: [2, 3, 2, 4],
          filename: getReportTitle(),
          jsPDF: {
            orientation: 'landscape',
          },
          pagebreak: { mode: 'avoid-all' },
        })
        .toPdf()
        .save()
        .then(() => {
          setSavingPDF(false);
        });
    }, 50);
  };

  return (
    <Card ref={pageRef} width={savingPDF ? '1024px' : '100%'}>
      {/* <Card ref={pageRef} width='1024px'> */}
      <CardHeader>
        <TextHeading title={getReportTitle()}>
          {!savingPDF && (
            <Button onClick={handleDownloadClick} size="xs">
              Downaload as PDF
            </Button>
          )}
        </TextHeading>
      </CardHeader>
      <CardBody>
        <SimpleGrid columns={2} spacing={4}>
          <Box pr={6} pl={0}>
            {organisationCurrentScore > 0 && (
              <Box mt={6} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Box fontSize={'20px'} color="#315D4F">
                  impactX scores by department
                </Box>{' '}
                <Box fontSize={'30'} color="#315D4F" fontWeight={'bold'}>
                  {organisationCurrentScore}
                </Box>
              </Box>
            )}
            {currentScoresData && <Bar data={currentScoresData} options={getOptions()}></Bar>}
          </Box>

          <Box pl={6} pr={0}>
            {organisationClosedLoops > 0 && (
              <Box mt={6} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Box fontSize={'20px'} color="#315D4F">
                  Closed loops by department
                </Box>{' '}
                <Box fontSize={'30'} color="#315D4F" fontWeight={'bold'}>
                  {organisationClosedLoops}
                </Box>
              </Box>
            )}

            {currentLoopsData && <Bar data={currentLoopsData} options={getOptions()}></Bar>}
          </Box>
        </SimpleGrid>
        <SimpleGrid columns={1} spacing={4}>
          {monthlyScoresData && (
            <Box pt={6}>
              <Box fontSize={'20px'} color="#315D4F">
                Monthly impactX scores by department
              </Box>{' '}
              <Bar data={monthlyScoresData} options={getOptions()}></Bar>
            </Box>
          )}
          {monthlyLoopsData && (
            <Box pt={6}>
              <Box fontSize={'20px'} color="#315D4F">
                Monthly closed loops by department
              </Box>{' '}
              <Bar data={monthlyLoopsData} options={getOptions()}></Bar>
            </Box>
          )}

          {monthlyScoresUserData && (
            <Box pt={6}>
              <Box fontSize={'20px'} color="#315D4F">
                Monthly impactX scores by user
              </Box>{' '}
              <Bar data={monthlyScoresUserData} options={getOptions()}></Bar>
            </Box>
          )}

          {monthlyLoopsUserData && (
            <Box pt={6}>
              <Box fontSize={'20px'} color="#315D4F">
                Monthly closed loops by user
              </Box>{' '}
              <Bar data={monthlyLoopsUserData} options={getOptions()}></Bar>
            </Box>
          )}

          {organisationScoresData && (
            <Box pt={6}>
              <Box fontSize={'20px'} color="#315D4F">
                Scores by organisation
              </Box>{' '}
              <Bar data={organisationScoresData} options={getOptions()}></Bar>
            </Box>
          )}
          {organisationLoopsData && (
            <Box pt={6}>
              <Box fontSize={'20px'} color="#315D4F">
                Closed loops by organisation
              </Box>{' '}
              <Bar data={organisationLoopsData} options={getOptions()}></Bar>
            </Box>
          )}
        </SimpleGrid>
      </CardBody>
    </Card>
  );
};

export default AdminReports;
