import { defineStyleConfig } from '@chakra-ui/react';

export const CloseButton = defineStyleConfig({
  baseStyle: {
    background: 'brand.900',
    borderRadius: 'full',
    color: '#fff',
    width: '48px',
    height: '48px',
  },
});
