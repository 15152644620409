import React from 'react';
import { Button, Box } from '@chakra-ui/react';

import { ReactComponent as IcnPlus } from '../icons/icn-plus.svg';

interface Props {
  onClick: () => void;
}

export const AddButton: React.FC<Props> = ({ onClick }) => {
  return (
    <Button variant={'ghost'} onClick={onClick}>
      <Box pr={3}>
        <IcnPlus />
      </Box>{' '}
      Add new
    </Button>
  );
};
