import React, { createContext, useContext, useCallback } from 'react';
import { useToast } from '@chakra-ui/react';

// Define a default function that can be used as a fallback
const defaultToastFunction = (title: string, description: string, status: 'success' | 'error' | 'warning' = 'success') => {
  console.warn('Toast function is not available.');
};

// Use the default function instead of null
const ToastContext =
  createContext<(title: string, description: string, status?: 'success' | 'error' | 'warning') => void>(defaultToastFunction);

export const useToastContext = () => {
  return useContext(ToastContext);
};

interface ToastProviderProps {
  children: React.ReactNode;
}

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const toast = useToast();

  const showToast = useCallback(
    (title: string, description: string, status: 'success' | 'error' | 'warning' = 'success') => {
      toast({
        title,
        description,
        status: status,
        duration: 3000,
      });
    },
    [toast]
  );

  return <ToastContext.Provider value={showToast}>{children}</ToastContext.Provider>;
};
