import React from 'react';
import { Box, BoxProps, AvatarGroup } from '@chakra-ui/react';
import Badge from './Badge';

import { useAuth } from '../AuthContext';

interface Props extends BoxProps {
  max?: number;
  align?: 'left' | 'center' | 'right';
}

export const Badges: React.FC<Props> = ({ max = 10, align = 'left', ...boxProps }) => {
  const { user } = useAuth();
  const justifyContent = align === 'left' ? 'flex-end' : align === 'right' ? 'flex-start' : 'center'; // Reverse justify content because avatar group has row-reverse direction and justify content left/rigt doesn't work

  return (
    <Box {...boxProps}>
      {!user?.userBadges || user?.userBadges.length === 0 ? (
        <Box textAlign={align}>You have no earned badges yet.</Box>
      ) : (
        <>
          <Box textAlign={align}>Earned badges:</Box>
          <AvatarGroup max={max} justifyContent={justifyContent}>
            {user?.userBadges.map((badge: any) => (
              <Badge iconUrl={badge.icon} name={badge.name} key={badge.name} />
            ))}
          </AvatarGroup>
        </>
      )}
    </Box>
  );
};

export default Badges;
