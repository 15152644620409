import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

interface Props extends BoxProps {}

const Border: React.FC<Props> = ({ marginTop = 1, marginBottom = 1, ...restProps }) => {
  return <Box height={'2px'} marginTop={marginTop} marginBottom={marginBottom} borderBottom={'2px dotted #d4d4d4'} {...restProps}></Box>;
};

export default Border;
