import React, { useState } from 'react';
import {
  SimpleGrid,
  Box,
  Stack,
  GridItem,
  Card,
  CardHeader,
  CardBody,
  Avatar,
  Text,
  AspectRatio,
  useDisclosure,
  useEditableControls,
  Flex,
  IconButton,
  Editable,
  EditablePreview,
  EditableInput,
  Input,
  Center,
} from '@chakra-ui/react';

import PageGrid from '../components/PageGrid';
import ScoreCard from '../components/ScoreCard';
import NewsCard from '../components/NewsCard';
import TextHeading from '../components/TextHeading';
import XButton from '../components/XButton';
import { ImageUpload } from '../components/ImageUpload';
import { ChangePasswordForm } from '../components/ChangePasswordForm';
import ModalDialog from '../components/XModal';

import { useToastContext } from '../ToastContext';
import { useAuth } from '../AuthContext';
import { User } from '../types';
import api from '../api';
import { Utils } from '../services';

import { ReactComponent as IcnEdit } from '../icons/icn-edit.svg';

// TODO move
function getValue(obj: any, key: string): any {
  const keys = key.split('.');
  let value = obj;

  if (Object.entries(value).length === 0) {
    return '';
  }

  for (let i = 0; i < keys.length; i++) {
    if (typeof value[keys[i]] !== 'undefined' && value[keys[i]] !== null) {
      value = value[keys[i]];
    } else {
      return '';
    }
  }

  return value;
}

function EditableControls() {
  const { isEditing, getEditButtonProps } = useEditableControls();

  return isEditing ? (
    <Box></Box>
  ) : (
    <Flex justifyContent="center">
      <IconButton size="sm" icon={<IcnEdit />} aria-label="Edit" {...getEditButtonProps()} />
    </Flex>
  );
}

const ProfileItem: React.FC<{
  label: string;
  user: User | null;
  accessor: string;
  editable?: boolean;
  onChange?: (userData: any) => any;
}> = ({ label, user, accessor, editable, onChange }) => {
  const toast = useToastContext();
  const handleSubmit = async (newValue: any) => {
    let payload = { ...user };
    const keys = accessor.split('.');

    if (keys.length > 1) {
      // @ts-ignore
      if (!payload[keys[0]]) {
        // @ts-ignore
        payload[keys[0]] = {};
      }

      // @ts-ignore
      payload[keys[0]][keys[1]] = newValue;
    } else {
      // @ts-ignore
      payload[keys[0]] = newValue;
    }

    try {
      const { data } = await api.patch(`/users`, payload);

      if (onChange) {
        onChange(data.data);
      }
    } catch (error) {
      toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }
  };

  let value = user ? getValue(user, accessor) : '-';

  return (
    <Box py={3} overflow={'hidden'} wordBreak={'break-word'}>
      <Text fontSize={'14px'} lineHeight={'normal'}>
        {label}
      </Text>
      {editable && (
        <Editable
          defaultValue={value || '-'}
          fontSize="2xl"
          isPreviewFocusable={false}
          width={'100%'}
          display={'flex'}
          alignItems="center"
          justifyContent={'space-between'}
          onSubmit={handleSubmit}
        >
          <EditablePreview />
          <Input as={EditableInput} />
          <EditableControls />
        </Editable>
      )}
      {!editable && (
        <Center fontSize={'18px'} fontWeight={600} height="44px" justifyContent={'flex-start'}>
          {value || '-'}
        </Center>
      )}
    </Box>
  );
};

const ProfilePage: React.FC = () => {
  const toast = useToastContext();
  const { user, setUser, isAffiliate } = useAuth();
  const [userData, setUserData] = useState<any>(user);
  const [uploading, setUploading] = useState(false);

  const handleImageUploadChange = async (file: File) => {
    try {
      setUploading(true);

      let formData = new FormData();
      formData.append('file', file);

      const { data } = await api.patch(`/users/picture`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const newUser = {
        ...userData,
        profilePhoto: data.data.data.profileUrl,
      };

      toast('Profile photo uploaded.', 'Profile image uploaded successfully');
      setUser(newUser);
      setUserData(newUser);

      setShowProfilePhoto(false);
      setTimeout(() => {
        setShowProfilePhoto(true);
      }, 10);
      setUploading(false);
    } catch (error) {
      toast('An error occurred', Utils.formatErrorMessage(error), 'error');
      setUploading(false);
    }
  };

  const [showProfilePhoto, setShowProfilePhoto] = useState(true);
  const handleImageRemove = async () => {
    try {
      await api.patch(`/users`, {
        profilePhoto: '',
      });

      setShowProfilePhoto(false);
      setTimeout(() => {
        setShowProfilePhoto(true);
      }, 10);

      const newData = {
        ...userData,
        profilePhoto: '',
      };

      setUserData(newData);
      setUser(newData);

      toast('Profile photo removed.', 'Profile image removed successfuly');
    } catch (error) {
      toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }
  };

  const handleUserChange = (newData: any) => {
    setUser(newData);
    setUserData(newData);
    toast('Profile updated.', 'Your profile has been successfully updated');
  };

  const [nameFieldVisible, setNameFieldVisible] = useState(true);
  const handleNameSubmit = async (value: any) => {
    if (value === userData.fullName) {
      return;
    } else if (value.trim().length === 0) {
      setNameFieldVisible(false);
      setTimeout(() => {
        setNameFieldVisible(true);
      });

      toast('Name cannot be empty.', 'Please enter a name', 'warning');
      return;
    }

    try {
      await api.patch(`/users`, {
        fullName: value,
      });
      handleUserChange({
        ...userData,
        fullName: value,
      });
    } catch (error) {
      toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }
  };

  const [changingPassword, setChangingPassword] = useState(false);
  const { isOpen: isChangePasswordOpen, onOpen: onChangePasswordOpen, onClose: onChangePasswordClose } = useDisclosure();
  const handleChangePasswordSubmit = async (formData: any) => {
    setChangingPassword(true);

    try {
      await api.patch(`/users/password`, formData);
      toast('Password changed.', 'You have successfully changed your password');
    } catch (error) {
      toast('An error occurred while changing password', Utils.formatErrorMessage(error), 'error');
    }

    setChangingPassword(false);
    onChangePasswordClose();
  };

  return (
    <>
      <ModalDialog title={`Change password`} size="md" isOpen={isChangePasswordOpen} onClose={onChangePasswordClose}>
        <ChangePasswordForm onSubmit={handleChangePasswordSubmit} saving={changingPassword} onDone={onChangePasswordClose} />
      </ModalDialog>

      <PageGrid
        sideBar={
          user?.userType !== 'xsilio admin' && (
            <GridItem minWidth={'250px'}>
              <Stack gap="6">
                <ScoreCard />
                {!isAffiliate() && <NewsCard />}
              </Stack>
            </GridItem>
          )
        }
      >
        <Card>
          <CardHeader>
            <TextHeading title="Your profile"></TextHeading>
          </CardHeader>
          <CardBody color="brand.900">
            <SimpleGrid columns={2} spacing={4} paddingBottom={6} px={4}>
              <Stack pr="10">
                {showProfilePhoto && (
                  <AspectRatio ratio={1} mb="10">
                    <ImageUpload
                      onImageChange={handleImageUploadChange}
                      size="100%"
                      borderRadius="50%"
                      message={userData?.profilePhoto ? 'Change profile photo' : 'Upload profile photo'}
                      remove={userData.profilePhoto}
                      removeMessage={'Remove profile photo'}
                      onRemove={handleImageRemove}
                      uploading={uploading}
                    >
                      <Avatar
                        size="3xl"
                        width="100%"
                        height="100%"
                        name={userData?.fullName}
                        src={userData?.profilePhoto}
                        position="relative"
                      ></Avatar>
                    </ImageUpload>
                  </AspectRatio>
                )}
                {isAffiliate() && (
                  <>
                    <ProfileItem label={'Company'} user={userData} accessor={'affiliatePartner.name'} />
                  </>
                )}
                {user?.userType !== 'xsilio admin' && !isAffiliate() && (
                  <>
                    <ProfileItem label={'Company'} user={userData} accessor={'organisation.name'} />
                    <ProfileItem label={'Department'} user={userData} accessor={'department.name'} />
                  </>
                )}
                <ProfileItem label={'Email'} user={userData} accessor={'email'} />
                <ProfileItem label={'Phone'} user={userData} accessor={'phone'} editable onChange={handleUserChange} />
                <XButton onClick={onChangePasswordOpen} mt={10}>
                  Change password
                </XButton>
              </Stack>
              <Stack pr="10">
                <AspectRatio ratio={1} mb="10">
                  <Stack>
                    <Text fontSize={'14px'} lineHeight={'normal'} textAlign={'left'} width={'100%'}>
                      Profile holder:
                    </Text>
                    {nameFieldVisible && (
                      <Box fontSize={'36px'} fontWeight={600} lineHeight={'normal'} textAlign={'left'} width={'100%'}>
                        <Editable defaultValue={userData.fullName} onSubmit={handleNameSubmit}>
                          <EditablePreview py="0" />
                          <EditableInput />
                        </Editable>
                      </Box>
                    )}
                  </Stack>
                </AspectRatio>
                <ProfileItem label={'Street'} user={userData} accessor={'address.street'} editable onChange={handleUserChange} />
                <ProfileItem label={'City'} user={userData} accessor={'address.city'} editable onChange={handleUserChange} />
                <ProfileItem label={'Postal Code'} user={userData} accessor={'address.postCode'} editable onChange={handleUserChange} />
                <ProfileItem label={'Country'} user={userData} accessor={'address.country'} editable onChange={handleUserChange} />
              </Stack>
            </SimpleGrid>
          </CardBody>
        </Card>
      </PageGrid>
    </>
  );
};

export default ProfilePage;
