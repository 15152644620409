import { NewsItem } from '../components/NewsItem';
import React, { useEffect, useState } from 'react';
import { Box, Stack, SimpleGrid, Card, CardHeader, CardBody } from '@chakra-ui/react';

import PageGrid from '../components/PageGrid';
import ScoreCard from '../components/ScoreCard';
import TextHeading from '../components/TextHeading';
import Border from '../components/XBorder';
import XButton from '../components/XButton';
import ExploreCTACard from '../components/ExploreCTACard';

import api from '../api';
import { useAuth } from '../AuthContext';
import { NavLink } from 'react-router-dom';

const NewsPage: React.FC = () => {
  const { user, isAffiliate } = useAuth();
  const [xsilioNews, setXsilioNews]: any = useState([]);
  const [organisationNews, setOrganisationNews]: any = useState([]);
  const affiliate = isAffiliate();

  const fetchData = async () => {
    try {
      const response = await api.get(`/news/platform`, { params: { limit: 2 } });
      setXsilioNews(response.data.data);
    } catch (error) {
      console.error(error);
    }

    if (!affiliate) {
      try {
        const response = await api.get(`/news/organisation`, { params: { limit: 2 } });
        setOrganisationNews(response.data.data);
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PageGrid
      sideBar={
        <Stack gap="6">
          <ScoreCard />
          <ExploreCTACard />
        </Stack>
      }
    >
      <SimpleGrid gap={6} columns={affiliate ? 1 : 2}>
        {!affiliate && (
          <Box>
            <Card>
              <CardHeader>
                <TextHeading fontSize={'21px'} title={`${user?.organisation.name} Updates`} borderColor="#315D4F"></TextHeading>
              </CardHeader>
              <CardBody>
                {organisationNews && organisationNews.length > 0 ? (
                  <>
                    {organisationNews.map((article: any) => (
                      <div key={article.id}>
                        <NewsItem article={article} />
                        <Border mt={5} mb={6} />
                      </div>
                    ))}{' '}
                    <XButton width={'100%'} mt={6} as={NavLink} to="/news/all">
                      All News
                    </XButton>
                  </>
                ) : (
                  <Box py={4} px={2}>
                    No news to display
                  </Box>
                )}
              </CardBody>
            </Card>
          </Box>
        )}
        <Box>
          <Card>
            <CardHeader>
              <TextHeading fontSize={'21px'} title={'XSILIO News & Updates'} borderColor="#315D4F"></TextHeading>
            </CardHeader>
            <CardBody>
              {xsilioNews && xsilioNews.length > 0 ? (
                <>
                  {xsilioNews.map((article: any) => (
                    <div key={article.id}>
                      <NewsItem article={article} />
                      <Border mt={5} mb={6} />
                    </div>
                  ))}{' '}
                  <XButton width={'100%'} mt={6} as={NavLink} to="/news/platform">
                    All News
                  </XButton>
                </>
              ) : (
                <Box py={4} px={2}>
                  No news to display
                </Box>
              )}
            </CardBody>
          </Card>
        </Box>
      </SimpleGrid>
    </PageGrid>
  );
};

export default NewsPage;
