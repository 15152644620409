import React from 'react';
import { Heading, Card, CardHeader, CardBody, UnorderedList, ListItem, OrderedList } from '@chakra-ui/react';

import TextHeading from '../components/TextHeading';
const HelpPage: React.FC = () => {
  return (
    <Card>
      <CardHeader>
        <TextHeading title="Help & Support"></TextHeading>
      </CardHeader>

      <CardBody>
        <p>
          Welcome to XSILIO&#39;s Help &amp; Support page! Our platform is designed to empower organizations in their journey towards
          sustainability by promoting asset reuse and fostering a circular economy. Below, you&#39;ll find comprehensive information and
          resources to guide you through your XSILIO experience.&nbsp;
        </p>

        <p>
          <br />
          <strong>Why XSILIO Matters:</strong>
        </p>
        <br />

        <p>
          XSILIO is more than just a platform &ndash; it&#39;s a catalyst for change. Here&#39;s why XSILIO is crucial for organizations
          committed to environmental responsibility: <br />
        </p>

        <UnorderedList>
          <ListItem>
            <strong>Circular Economy:</strong> XSILIO promotes the reuse, reallocation, and repurposing of assets, reducing waste and
            minimizing environmental impact.&nbsp;
          </ListItem>
          <ListItem>
            <strong>Cost Savings:</strong> By facilitating internal asset transfers, XSILIO helps organizations save on disposal costs and
            avoid unnecessary purchases, leading to financial efficiency.&nbsp;
          </ListItem>
          <ListItem>
            <strong>Carbon Mitigation:</strong> Through asset reuse, XSILIO contributes to reducing carbon emissions and advancing
            organizations towards net zero targets.&nbsp;
          </ListItem>
          <ListItem>
            <strong>Transparency &amp; Accountability:</strong> XSILIO enables organizations to track, measure, and report their
            sustainability efforts, fostering transparency and accountability.&nbsp;
          </ListItem>
        </UnorderedList>
        <br />
        <p>
          <strong>How XSILIO Works:</strong>&nbsp;
        </p>

        <br />
        <OrderedList>
          <ListItem>
            <strong>Asset Upload &amp; Listing:</strong> Users can easily upload and list assets they no longer need within their department
            or location onto the XSILIO platform, including descriptions, specifications, images, and condition details.&nbsp;
          </ListItem>
          <ListItem>
            <strong>Marketplace Access:</strong> Other users can explore the XSILIO marketplace to discover available assets and acquire
            them for reuse, helping to close the loop and extend the lifetime of assets.&nbsp;
          </ListItem>
          <ListItem>
            <strong>impactX Scoring:</strong> XSILIO&#39;s proprietary impactX scoring system assigns a unique carbon footprint score to
            each asset, providing valuable insights into environmental impact and facilitating informed decision-making.&nbsp;
          </ListItem>
          <ListItem>
            <strong>impactX Badges:</strong> Users earn ImpactX badges based on their contributions to carbon mitigation and circular
            economy principles, fostering a sense of achievement and recognition.&nbsp;
          </ListItem>
        </OrderedList>

        <p>
          <br />
          <strong>Get Help &amp; Support:</strong>
        </p>
        <br />

        <p>
          If you have any questions, encounter issues, or need assistance while using XSILIO, our dedicated support team is here to help.
          Contact us via:&nbsp;
        </p>

        <UnorderedList>
          <ListItem>
            <strong>Email:</strong> <a href="mailto:support@xsilio.com">support@xsilio.com</a>&nbsp;
          </ListItem>
        </UnorderedList>

        <br />
        <p>
          <strong>Resources:</strong>
        </p>
        <br />

        <p>Explore additional resources to enhance your XSILIO experience:&nbsp;</p>

        <UnorderedList>
          <ListItem>
            <strong>Knowledge Base:</strong> Access guides, tutorials, and FAQs to troubleshoot common issues and optimize your use of
            XSILIO.&nbsp;
          </ListItem>
          <ListItem>
            <strong>Webinars &amp; Workshops:</strong> Attend live sessions to deepen your understanding of XSILIO features and best
            practices.&nbsp;
          </ListItem>
          <ListItem>
            <strong>Community Forum:</strong> Engage with fellow XSILIO users, share insights, and learn from each other&#39;s
            experiences.&nbsp;
          </ListItem>
        </UnorderedList>

        <p>
          Thank you for joining us on this journey towards a greener, more sustainable future with XSILIO. Together, we can make a positive
          impact and build a better world.&nbsp;
        </p>
        <br />
        <p>Sincerely,</p>
        <p>The XSILIO Team </p>

        <br />

        <Heading as="h4" size="md">
          Some FAQs...
        </Heading>

        <br />

        <OrderedList>
          <ListItem>
            <p>
              <strong>What is XSILIO and how does it work?</strong>&nbsp;
            </p>

            <p>
              XSILIO is a software platform designed to facilitate the reuse, reallocation, and repurposing of assets within organizations.
              Users can upload assets they no longer need, which are then listed on a marketplace for other users to acquire.&nbsp;
            </p>
          </ListItem>

          <ListItem>
            <p>
              <strong>How does XSILIO contribute to a circular economy?</strong>&nbsp;
            </p>
            <p>
              XSILIO promotes a circular economy by enabling organizations to reuse assets internally and externally, thus extending the
              lifetime of assets and reducing waste.&nbsp;
            </p>
          </ListItem>

          <ListItem>
            <p>
              <strong>What types of assets can be listed on XSILIO?</strong>&nbsp;
            </p>
            <p>
              Users can list various types of assets, including furniture, equipment, and products, that they no longer need within their
              department or location.&nbsp;
            </p>
          </ListItem>

          <ListItem>
            <p>
              <strong>How do users benefit from using XSILIO?</strong>&nbsp;
            </p>

            <p>
              Users can save costs associated with asset disposal and acquisition, minimize environmental impact by reducing carbon
              emissions, and contribute to building a greener environment.&nbsp;
            </p>
          </ListItem>
          <ListItem>
            <p>
              <strong>How can users interact on XSILIO?</strong>&nbsp;
            </p>
            <p>
              Users can set up profiles and interact with others in their organization through a peer-to-peer marketplace. They can upload
              assets for disposal or search for assets they need within their organization.&nbsp;
            </p>
          </ListItem>

          <ListItem>
            <p>
              <strong>How does XSILIO calculate carbon impact and scoring?</strong>&nbsp;
            </p>
            <p>
              XSILIO uses artificial intelligence, machine learning, and statistical analysis to calculate carbon impact through its
              proprietary CarbonX scoring system. This system assigns a unique impactX score to each asset, providing insights into its
              environmental footprint.&nbsp;
            </p>
          </ListItem>
          <ListItem>
            <p>
              <strong>What is KGCO2e?</strong>&nbsp;
            </p>
            <p>
              KGCO2e stands for kilograms of carbon dioxide equivalent. It is a unit of measurement used to quantify the impact of carbon
              emissions on the environment.&nbsp;
            </p>
          </ListItem>
          <ListItem>
            <p>
              <strong>How can users track their sustainability efforts on XSILIO?</strong>&nbsp;
            </p>
            <p>
              Users can view their impactX score, which reflects their contributions to carbon mitigation and circular economy principles.
              They can also see their department and organization&#39;s performance, view league tables, and track historical data.&nbsp;
            </p>
          </ListItem>
          <ListItem>
            <p>
              <strong>What support is available for users of XSILIO?</strong>&nbsp;
            </p>
            <p>
              Users can reach out to our support team via email at{' '}
              <a href="mailto:support@xsilio.com" title="mailto:support@xsilio.com">
                support@xsilio.com
              </a>{' '}
              for assistance with any questions or issues they may encounter while using the platform.&nbsp;
            </p>
          </ListItem>
          <ListItem>
            <p>
              <strong>How does XSILIO ensure the security and privacy of user data?</strong>&nbsp;
            </p>
            <p>
              XSILIO employs industry-standard security measures to protect user data and ensure privacy. We adhere to strict data
              protection regulations and guidelines to safeguard user information.&nbsp;
            </p>
          </ListItem>
        </OrderedList>
      </CardBody>
    </Card>
  );
};

export default HelpPage;
