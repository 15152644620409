import { ScoreGraph } from '../components/ScoreGraph';
import React, { useEffect, useState } from 'react';
import { Box, Card, CardHeader, CardBody, Stack, SimpleGrid, GridItem } from '@chakra-ui/react';

import PageGrid from '../components/PageGrid';
import TextHeading from '../components/TextHeading';
import { Score } from '../components/Score';
import { Badges } from '../components/Badges';
import ChallengeCard from '../components/ChallengeCard';
import ScorePageCard from '../components/ScorePageCard';
import ExploreCTACard from '../components/ExploreCTACard';

import { useAuth } from '../AuthContext';
import api from '../api';

const ScorePage: React.FC = () => {
  const { user, isAffiliate } = useAuth();

  const [organisation, setOrganisation]: any = useState();
  const [department, setDedepartment]: any = useState();

  const [userGraphData, setUserGraphData]: any = useState();
  const [organisationGraphData, setOrganisationGraphData]: any = useState();
  const [departmentGraphData, setDepartmentGraphData]: any = useState();

  const fetchData = async () => {
    try {
      api.get(`/records/annual-report/user/${user?.id}`).then(({ data }) => {
        setUserGraphData(data.data);
      });
    } catch (e) {
      console.error(e);
    }

    if (user?.organisation) {
      try {
        api.get(`/organisations/${user?.organisation?.id}`).then(({ data }) => {
          setOrganisation(data.data);
        });
      } catch (e) {
        console.error(e);
      }

      try {
        api.get(`/records/annual-report/organisation/${user?.organisation?.id}`).then(({ data }) => {
          setOrganisationGraphData(data.data);
        });
      } catch (e) {
        console.error(e);
      }
    }

    if (user?.department) {
      api.get(`/departments/${user?.department?.id}`).then(({ data }) => {
        setDedepartment(data.data);
      });

      try {
        api.get(`/records/annual-report/department/${user?.department?.id}`).then(({ data }) => {
          setDepartmentGraphData(data.data);
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PageGrid
      sideBar={
        <Stack gap="6">
          {!isAffiliate() && <ChallengeCard />} <ExploreCTACard />{' '}
        </Stack>
      }
    >
      <SimpleGrid gap="6">
        <GridItem colSpan={2}>
          <Card>
            <CardHeader>
              <TextHeading title="Your impactX Score"></TextHeading>
            </CardHeader>
            <CardBody p={0} pb="6">
              <Stack direction={'row'} pt={6}>
                <Box width={'40%'}>
                  <Score score={user?.score?.impactXScore} />
                </Box>
                <Box width={'60%'}>
                  <Badges />
                  <Card bg={'gray.100'} border={'1px solid gray.200'} p="1px" mt={6}>
                    {userGraphData && userGraphData.length > 0 && <ScoreGraph data={userGraphData} />}
                  </Card>
                </Box>
              </Stack>
            </CardBody>
          </Card>
        </GridItem>
        {department && (
          <GridItem>
            <ScorePageCard title={department.name} score={department.score?.impactXScore} graphData={departmentGraphData} />
          </GridItem>
        )}
        {organisation && (
          <GridItem>
            <ScorePageCard title={organisation.name} score={organisation.score?.impactXScore} graphData={organisationGraphData} />
          </GridItem>
        )}
      </SimpleGrid>
    </PageGrid>
  );
};

export default ScorePage;
