import React, { useEffect, useState } from 'react';
import { Box, Card, Stack, Avatar, Flex, Spacer, IconButton, Select, Text, useDisclosure } from '@chakra-ui/react';

import { useSocket } from '../SocketContext';

import TextHeading from '../components/TextHeading';
import PageGrid from '../components/PageGrid';
import Border from '../components/XBorder';
import ScoreCard from '../components/ScoreCard';
import NewsCard from '../components/NewsCard';
import NotificationSettingsForm from '../components/NotificationSettingsForm';
import ModalDialog from '../components/XModal';

import { Notification } from '../types';
import { Utils } from '../services';
import { useAuth } from '../AuthContext';

import api from '../api';

import { ReactComponent as IcnGear } from '../icons/icn-gear.svg';

const NotificationsPage: React.FC = () => {
  const socket = useSocket();
  const { isAffiliate } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    if (socket) {
      socket.on('notification', (notification: Notification) => {
        setNotifications((prevNotifications) => [notification, ...prevNotifications]);
      });
    }
  }, [socket]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/notifications`);
      // TODO mark all  as read
      setNotifications(response.data.data);

      await api.patch(`/notifications/mark-all-read`);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleBoxClick = () => {};

  return (
    <>
      <ModalDialog title={'Notification settings'} isOpen={isOpen} onClose={onClose}>
        <NotificationSettingsForm onSubmit={onClose} />
      </ModalDialog>

      <PageGrid
        sideBar={
          <Stack gap="6">
            <ScoreCard />
            {!isAffiliate() && <NewsCard />}
          </Stack>
        }
      >
        <Card paddingBottom={0} paddingTop={1} alignSelf={'flex-start'} overflow={'hidden'}>
          <Box px={6}>
            <TextHeading fontSize={'21px'} title={'Notifications'} borderColor="#315D4F" paddingBottom={0} mt={0} mb={0}>
              {/* <Stack direction={'row'} alignItems={'center'} pb={1}>
                <Text whiteSpace={'nowrap'} fontSize={'12px'} pr={1}>
                  Order by:
                </Text>
                <Select variant="outline" size="sm">
                  <option value="newset">Newest</option>
                  <option value="oldest">Oldest</option>
                </Select>
                <IconButton
                  onClick={onOpen}
                  aria-label="Notification settings"
                  variant={'ghost'}
                  mr={-3}
                  isRound
                  icon={<IcnGear />}
                ></IconButton>
              </Stack> */}
            </TextHeading>
          </Box>
          <Box pt={0} pb={0}>
            {notifications.map((notification, index) => (
              <Box px={6} bg={notification.read ? '#F0F3F3' : '#fff'} key={index}>
                {index > 0 && <Border marginTop={'-2px'} marginBottom={0} />}
                <Stack mt={0} mb={0} key={notification.id} direction={'row'} gap={3} py={3} width={'100%'}>
                  <Box
                    // cursor={'pointer'}
                    onClick={handleBoxClick}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'space-evenly'}
                    width={'100%'}
                  >
                    <Flex alignItems={'center'} gap={3}>
                      <Box lineHeight={1} fontSize={'16px'} color={'#315D4F'}>
                        <Text fontWeight={'700'}>{notification.title}</Text>
                        <Text>{notification.message}</Text>
                      </Box>
                      <Spacer></Spacer>
                      <Box fontSize={'12px'} color={'#315D4F'} lineHeight={1} whiteSpace={'nowrap'}>
                        {Utils.formatTime(notification.createdAt)}
                      </Box>
                    </Flex>
                  </Box>
                </Stack>
              </Box>
            ))}
          </Box>
        </Card>
      </PageGrid>
    </>
  );
};

export default NotificationsPage;
