import React, { useState, useEffect } from 'react';
import { Flex, Box, Button, Input, Tabs, TabPanels, TabPanel, Stack, Text, SimpleGrid, GridItem, Center } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useToastContext } from '../ToastContext';
import { Utils } from '../services';

import { FormField } from './FormField';
import { FieldValidationMessage } from './FieldValidationMessage';
import { ImageUpload } from './ImageUpload';

import api from '../api';

interface Props {
  onSubmit: (data: any) => any;
  onDone: () => void;
  organization?: any;
  saving: boolean;
}

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().required(),
});

export const OrganizationForm: React.FC<Props> = ({ onSubmit, organization, saving, onDone }) => {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...(!organization
        ? {}
        : {
            name: organization.name || '',
            email: organization.email || '',
            phone: organization.phone || '',
            contactName: organization.contactName || '',
            address: {
              city: organization.address?.city || '',
              street: organization.address?.street1 || '',
              postCode: organization.address?.postCode || '',
              country: organization.address?.country || '',
            },
          }),
    },
    resolver: yupResolver(schema),
  });

  const toast = useToastContext();

  const [organizationData, setOrganizationData] = useState(organization || null);
  const [errorMessage, setErrorMessage] = useState('');
  const [numberOfLicences, setNumberOfLicences] = useState(null);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    const subscription = watch(async (value, { name, type }: any) => {
      if (type === 'change') {
        if (name === 'organisationId') {
          const {
            data: { data },
          }: // @ts-ignore
          any = await api.get('/organisations/' + value.organisationId);

          setNumberOfLicences(data.numberOflicences);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const [tabIndex, setTabIndex] = useState(0);

  const handleFormSubmit = async (formData: any) => {
    if (tabIndex === 1) {
      onDone();
      return;
    }

    try {
      const resp = await onSubmit({
        ...(organizationData ? { id: organizationData.id } : {}),
        ...Utils.cleanPayload(formData),
      });

      if (resp.data.errors) {
        let toastMessage = `An error occurred: \n`;

        resp.data.errors.forEach(({ message }: any) => (toastMessage += '* ' + message + '\n'));

        setErrorMessage(toastMessage);
      } else {
        setErrorMessage('');
        setOrganizationData(resp.data);
        setTabIndex(1);
      }
    } catch (error: any) {
      toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }
  };

  const handleImageUploadChange = async (file: File) => {
    setUploading(true);
    let formData = new FormData();
    formData.append('file', file);

    try {
      await api.patch(`/admin-organisations/${organizationData.id}/logo`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      setErrorMessage('An error occurred');
    }

    setUploading(false);
  };

  return (
    <Box as="form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Tabs index={tabIndex}>
        <TabPanels>
          <TabPanel p="0">
            <Text color="brand.900" fontSize={'md'} mb={6}>
              {organization ? `Here you can easily edit an organization.` : `Here you can easily add a new organization.`}
            </Text>

            <SimpleGrid columns={4} gap={4} rowGap={1} mt={1}>
              <GridItem colSpan={2}>
                <Stack gap={4} rowGap={1}>
                  <FormField
                    fieldName={'name'}
                    errors={errors}
                    control={control}
                    render={({ field }: any) => <Input {...field} autoFocus placeholder={'Organisation Name*'} />}
                  />
                  <FormField
                    fieldName={'email'}
                    errors={errors}
                    control={control}
                    render={({ field }: any) => <Input {...field} placeholder={'Organisation Email*'} />}
                  />
                  <FormField
                    fieldName={'phone'}
                    errors={errors}
                    control={control}
                    render={({ field }: any) => <Input {...field} placeholder={'Organisation Phone'} />}
                  />
                  <FormField
                    fieldName={'contactName'}
                    errors={errors}
                    control={control}
                    render={({ field }: any) => <Input {...field} placeholder="Contact Name" />}
                  />
                </Stack>
              </GridItem>
              <GridItem colSpan={2}>
                <Stack gap={4} rowGap={1}>
                  <FormField
                    fieldName={'address.street'}
                    errors={errors}
                    control={control}
                    render={({ field }: any) => <Input {...field} placeholder="Street" />}
                  />
                  <FormField
                    fieldName={'address.postCode'}
                    errors={errors}
                    control={control}
                    render={({ field }: any) => <Input {...field} placeholder={'Postal Code'} />}
                  />
                  <FormField
                    fieldName={'address.city'}
                    errors={errors}
                    control={control}
                    render={({ field }: any) => <Input {...field} placeholder="City" />}
                  />
                  <FormField
                    fieldName={'address.country'}
                    errors={errors}
                    control={control}
                    render={({ field }: any) => <Input {...field} placeholder="Country" />}
                  />
                </Stack>
              </GridItem>
            </SimpleGrid>
          </TabPanel>
          <TabPanel p="0">
            <Text color="brand.900" fontSize={'md'} mb={6}>
              Here you can easily upload an image or skip by clicking save.
            </Text>
            <Center>
              <ImageUpload onImageChange={handleImageUploadChange} />
            </Center>
          </TabPanel>
        </TabPanels>
      </Tabs>
      {errorMessage && (
        <FieldValidationMessage mt={-2} pb={6}>
          {errorMessage}
        </FieldValidationMessage>
      )}

      <Flex direction="row" pt={6} justifyContent={'space-between'}>
        <Center>
          {numberOfLicences && (
            <Text color="brand.900" fontSize={'md'}>
              Number of Licenses: <strong>{numberOfLicences}</strong>
            </Text>
          )}
        </Center>
        <Button type="submit" isLoading={saving || uploading} mr={2}>
          {tabIndex === 0 ? 'Next' : 'Save'}
        </Button>
      </Flex>
    </Box>
  );
};

export default OrganizationForm;
