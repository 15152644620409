import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

interface Props extends BoxProps {
  children: React.ReactNode;
}
export const FieldValidationMessage: React.FC<Props> = ({ children, ...props }) => (
  <Box minHeight={6} pt={1} fontSize={'sm'} color={'#F00'} whiteSpace={'pre-wrap'} {...props}>
    {children}
  </Box>
);
