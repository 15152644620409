import React from 'react';
import { Card, CardHeader, CardBody } from '@chakra-ui/react';

import TextHeading from './TextHeading';
import InfoTooltip from './InfoTooltip';

interface Props {
  title: string;
  children: React.ReactNode;
  light?: boolean;
  tooltipTitle?: string;
  tooltipText?: string;
}

const InfoCard: React.FC<Props> = ({ title, children, light, tooltipText, tooltipTitle }) => {
  return (
    <Card bg={light ? '#B1C3BD' : '#315D4F'} color={light ? '#315D4F' : '#fff'}>
      <CardHeader>
        <TextHeading color={light ? '#315D4F' : '#fff'} borderColor={light ? '#fff' : '#B1C3BD'} title={title}>
          {tooltipTitle && (
            <InfoTooltip title={tooltipTitle} iconColor={light ? '#315D4F' : 'rgba(255, 255, 255, .5)'}>
              {tooltipText}
            </InfoTooltip>
          )}
        </TextHeading>
      </CardHeader>
      <CardBody pb={6}>{children}</CardBody>
    </Card>
  );
};

export default InfoCard;
