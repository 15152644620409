import { defineStyleConfig, defineStyle, theme } from '@chakra-ui/react';

const sm = defineStyle({
  w: '31px',
  h: '31px',
});

export const Button = defineStyleConfig({
  baseStyle: {
    color: '#fff',
    _hover: {
      opacity: 0.8,
    },
  },
  sizes: {
    ...theme.components.Button.sizes,
    sm,
  },
  variants: {
    primary: {
      bg: 'brand.900',
      borderRadius: 'base',
      paddingX: '60px',
    },
    tertiary: {
      background: '#E2E8E7',
    },
    circle: {
      bg: 'brand.900',
      borderRadius: '50%',
      width: '48px',
      height: '48px',
      padding: '0',
    },
    link: {
      fontWeight: 400,
    },
    ghost: {
      pl: 0,
      color: '#315D4F',
      _hover: {
        backgroundColor: 'transparent',
        opacity: 0.8,
      },
      _active: {
        backgroundColor: 'transparent',
      },
    },
    wide: {
      bg: 'brand.900',
      borderRadius: 'base',
      paddingX: '100px',
    },
    outline: {
      bg: 'transparent',
      color: 'brand.900',
      borderColor: 'brand.900',
      borderRadius: 'base',
      paddingX: '60px',
      _hover: {
        bg: 'brand.900',
        opacity: 0.8,
        color: '#fff',
      },
      _active: {
        bg: 'brand.900',
        color: '#fff',
        borderColor: 'brand.900',
        borderWidth: '1px',
      },
    },
    delete: {
      bg: 'transparent',
      color: 'brand.900',
      borderColor: 'brand.900',
      borderRadius: 'base',
      paddingX: '60px',
      borderWidth: '1px',
      _hover: {
        bg: '#FF0000',
        opacity: 0.8,
        color: '#fff',
      },
      _active: {
        bg: '#FF0000',
        color: '#fff',
        borderColor: 'brand.900',
        borderWidth: '1px',
      },
    },
    filter: {
      bg: '#E2E8E7',
      color: 'brand.900',
      borderRadius: 'base',
      paddingX: '60px',
      height: '35px',
      border: 'none',
      _hover: {
        bg: 'brand.900',
        opacity: 0.8,
        color: '#fff',
      },
      _active: {
        bg: 'brand.900',
        color: '#fff',
        borderColor: 'brand.900',
      },
    },
  },
  defaultProps: {
    size: 'lg',
    variant: 'primary',
  },
});
