import React, { useState } from 'react';
import { Center, Box, BoxProps, Spinner, Button, Flex } from '@chakra-ui/react';
import ImageUploading, { type ImageListType, type ImageType } from 'react-images-uploading';
import ReactCrop, { type Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import Modal from './XModal';
import { useToastContext } from '../ToastContext';

interface Props extends BoxProps {
  onImageChange?: (file: File) => void;
  maxNumber?: number;
  children?: React.ReactNode;
  size?: string;
  message?: string;
  remove?: boolean;
  removeMessage?: string;
  onRemove?: () => void;
  uploading?: boolean;
  imageUrl?: string;
}

export const ImageUpload: React.FC<Props> = ({
  onImageChange,
  maxNumber = 1,
  size = '200px',
  children,
  message,
  remove = false,
  removeMessage = 'Remove image',
  onRemove,
  uploading,
  imageUrl,
  ...boxProps
}) => {
  const [image, setImage] = useState<ImageType>();
  const [processing, setProcessing] = useState(false);
  const toast = useToastContext();
  const [opacity, setOpacity] = useState(1);
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [crop, setCrop] = useState<Crop>({
    unit: '%',
    x: 0,
    y: 0,
    width: 100,
    height: 100,
  });
  const [croppedImageSrc, setCroppedImageSrc] = useState('');

  const handleChange = (imageList: ImageListType) => {
    setImage(imageList[0]);
    setCropModalOpen(true);
  };

  const handleDone = () => {
    setProcessing(true);

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const htmlImage = new Image();
    htmlImage.onload = function () {
      if (ctx && crop) {
        const width = htmlImage.width * (crop.width / 100);
        const height = htmlImage.height * (crop.height / 100);
        const cropX = htmlImage.width * (crop.x / 100);
        const cropY = htmlImage.height * (crop.y / 100);

        canvas.width = width;
        canvas.height = height;

        // Draw the cropped image onto the canvas
        ctx.drawImage(htmlImage, cropX, cropY, width, height, 0, 0, width, height);

        // Convert canvas to Blob
        canvas.toBlob((blob) => {
          if (blob && image) {
            const file = new File([blob], image.name);
            onImageChange && onImageChange(file);
            setCropModalOpen(false);

            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
              // @ts-ignore
              setCroppedImageSrc(reader.result);
              setProcessing(false);
            };
          }
        }, 'image/jpeg');
      }
    };

    if (image && image.dataURL) {
      htmlImage.src = image.dataURL;
    }
  };

  const handldeError = (error: any) => {
    if (error.maxNumber) {
      toast('An error occurred', `You can add a maximum of ${maxNumber} ${maxNumber > 1 ? 'images' : 'image'}.`, 'error');
    }
  };

  const handleRemoveClick = () => {
    onRemove && onRemove();
  };

  const onCropModalClose = () => {
    setCropModalOpen(false);
    setImage(undefined);
  };

  const imageUploadingValue: ImageListType = [];

  let previewImageSrc = '';
  let messageText = '';

  if (croppedImageSrc) {
    previewImageSrc = croppedImageSrc;
  } else if (imageUrl) {
    previewImageSrc = imageUrl;
  }

  if (!message) {
    messageText = previewImageSrc ? 'Change image' : '+ Add image';
  } else {
    messageText = message;
  }

  return (
    <>
      <Box onMouseEnter={() => setOpacity(0.25)} onMouseLeave={() => setOpacity(1)} {...boxProps}>
        <ImageUploading multiple={false} value={imageUploadingValue} onChange={handleChange} maxNumber={maxNumber} onError={handldeError}>
          {({ onImageUpload, dragProps }) => (
            <Center position={'relative'} width={size} height={size}>
              {/* {message} */}
              <Center position={'absolute'} top={0} left={0} height={'100%'} width={'100%'} {...dragProps}>
                <Center
                  position={'absolute'}
                  top={0}
                  left={0}
                  height={'100%'}
                  width={'100%'}
                  // cursor={'pointer'}
                  flexDirection={'column'}
                  alignContent={'center'}
                  zIndex={1}
                  opacity={children ? 0 : 1}
                  _hover={{
                    opacity: 1,
                  }}
                >
                  {uploading && <Spinner />}
                  {!uploading && (
                    <>
                      <Box my="1" py="1" onClick={onImageUpload} cursor={'pointer'}>
                        {messageText}
                      </Box>
                      {remove && (
                        <Box my="1" py="1" cursor={'pointer'} onClick={handleRemoveClick}>
                          {removeMessage}
                        </Box>
                      )}
                    </>
                  )}
                </Center>
                <Center height={'100%'} width={'100%'} position={'absolute'} top={0} left={0} opacity={opacity}>
                  {!children && !previewImageSrc && (
                    <svg xmlns="http://www.w3.org/2000/svg" width="118" height="118" viewBox="0 0 118 118" fill="none">
                      <circle cx="59" cy="59" r="58.5" stroke="#315D4F" strokeDasharray="5 5" />
                    </svg>
                  )}
                  {children && children}
                  {previewImageSrc && <img src={previewImageSrc} alt="" />}
                </Center>
                {image && (
                  <Modal title="Crop image" isOpen={cropModalOpen} onClose={onCropModalClose}>
                    <Box>
                      <ReactCrop crop={crop} onChange={(_c, c) => setCrop(c)} keepSelection={true}>
                        <img src={image['dataURL']} alt="" />
                      </ReactCrop>
                    </Box>
                    <Flex direction="row" pt={6} justifyContent={'space-between'}>
                      <Box> </Box>
                      <Button onClick={handleDone} isLoading={processing}>
                        Crop and upload
                      </Button>
                    </Flex>
                  </Modal>
                )}
              </Center>
            </Center>
          )}
        </ImageUploading>
      </Box>
    </>
  );
};

export default ImageUpload;
