import React, { useEffect } from 'react';
import { Card, CardBody, CardHeader, Text, LinkBox, LinkOverlay } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

import TextHeading from './TextHeading';
import Badges from './Badges';
import InfoTooltip from './InfoTooltip';
import { Score } from './Score';
import { useAuth } from '../AuthContext';

import { lookupTable } from '../constants';

interface Props {}

function toPossessive(name: string): string {
  if (!name) return '';

  // If the name ends in 's', just add an apostrophe
  if (name.endsWith('s')) {
    return name + "'";
  } else {
    // Otherwise, add 's
    return name + "'s";
  }
}

type ScoreObject = [number, string];

function getClosestByScore(target: number, list: any[]): ScoreObject {
  let closestObj: ScoreObject | null = null;
  let closestDifference: number = Infinity;

  for (const obj of list) {
    const difference = Math.abs(target - obj[0]);

    if (difference < closestDifference) {
      closestDifference = difference;
      closestObj = obj;
    }
  }

  return closestObj!;
}
const ScoreCard: React.FC<Props> = () => {
  const { user } = useAuth();

  const [text, setText] = React.useState('');
  const setEquivalentText = (score: number) => {
    if (score === 0) {
      // @ts-ignore
      setText('');
      return;
    }

    const res: any = getClosestByScore(score, lookupTable);

    if (!res) {
      // @ts-ignore
      setText(lookupTable[lookupTable.length - 1][1]);
    } else {
      if (res.length > 1) {
        setText(res[1]);
      }
    }
  };

  useEffect(() => {
    setEquivalentText(Number(user?.score.impactXScore));
  }, []);

  return (
    <Card>
      <CardHeader>
        <TextHeading title={`Your impactX Score`}>
          {/* <InfoTooltip title={'How is your department doing?'}>
            See how your team is doing in its sustainability efforts against others in your business. Can you reach the top of the table? 
          </InfoTooltip> */}
        </TextHeading>
      </CardHeader>
      <CardBody>
        <LinkBox>
          <LinkOverlay as={NavLink} to="/score">
            <Score score={user?.score?.impactXScore} />
          </LinkOverlay>
          <Text textAlign={'center'} fontSize="14px" color={'brand.900'} px="8" pt={2} lineHeight={'normal'}>
            {text && `This is equivalent to nearly ${text}`}
          </Text>
          <Badges align="center" pt={2} max={6} />
        </LinkBox>
      </CardBody>
    </Card>
  );
};

export default ScoreCard;
