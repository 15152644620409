import React, { useEffect, useState } from 'react';
import { Box, Stack, LinkBox, LinkOverlay, Center } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

import PageGrid from '../components/PageGrid';
import DataTabs from '../components/XTabs';
import ScoreCard from '../components/ScoreCard';
import NewsCard from '../components/NewsCard';
import MessageBox from '../components/MessageBox';

import { Message } from '../types';
import { useSocket } from '../SocketContext';
import { useAuth } from '../AuthContext';

import api from '../api';

const MessagesPage: React.FC = () => {
  const { user } = useAuth();
  const socket = useSocket();
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState(true);

  const tabData = [
    {
      label: 'Received',
      content: 'received',
    },
    {
      label: 'Sent',
      content: 'sent',
    },
  ];

  const [filter, setFilter] = useState<string | null>(null);

  const handleTabChange = (index: number, content: string) => {
    setFilter(content);
  };

  const fetchData = async () => {
    try {
      if (!filter) {
        return;
      }

      setLoading(true);
      const { data } = await api.get(`/message/${filter}`);

      data.data.items.forEach((message: Message) => {
        if (!message.read) {
          api.patch(`/message/${message.id}/mark-read`).catch((error) => {
            console.error(error);
          });
        }
      });

      if (filter === 'received') {
        setMessages(data.data.items);
      } else {
        setMessages(
          data.data.items.map((message: Message) => {
            return {
              ...message,
              sender: user,
            };
          })
        );
      }

      setLoading(false);
    } catch (error) {
      setMessages([]);
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filter]);

  useEffect(() => {
    if (socket) {
      socket.on('message', (message: Message) => {
        setMessages((messages) => [...messages, message]);
      });
    }

    return () => {
      if (socket) {
        socket.off('message');
      }
    };
  }, [socket]);

  return (
    <PageGrid
      sideBar={
        <Stack gap="6">
          <ScoreCard />
          <NewsCard />
        </Stack>
      }
    >
      <DataTabs data={tabData} onTabChange={handleTabChange} syncWithUrl>
        {messages.length === 0 && <Center py="6">No messages to display</Center>}
        {messages.map((message, index) => (
          <LinkBox key={index}>
            <LinkOverlay as={NavLink} to={`/messages/${message.id}`}>
              <MessageBox message={message} firstInList={index === 0} lastInList={index === messages.length - 1} />
            </LinkOverlay>
          </LinkBox>
        ))}
      </DataTabs>
    </PageGrid>
  );
};

export default MessagesPage;
