import React, { useState } from 'react';
import { Stack, Box, Avatar, FormControl, Input, Textarea, Flex, Spacer, Toast } from '@chakra-ui/react';

import ModalDialog from './XModal';
import Border from './XBorder';
import Button from './XButton';

import { User } from '../types';
import api from '../api';
import { useToastContext } from '../ToastContext';
import { Utils } from '../services';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  recipient: User | null;
  subject?: string;
  listingId: string;
  parentMessageId?: string;
}

export const MessageDialog: React.FC<Props> = ({ isOpen, onClose, recipient, subject: initalSubject, listingId, parentMessageId }) => {
  const toast = useToastContext();
  const [subject, setSubject] = useState(initalSubject || '');
  const [message, setMessage] = useState('');
  const [sending, setSetting] = useState(false);

  const handleSendClick = async () => {
    setSetting(true);

    try {
      await api.post('/message/send', {
        listingId,
        subject,
        message,
        ...(parentMessageId && {
          parentMessageId,
          receiverId: recipient?.id,
        }),
      });

      toast('Message sent', 'Your message has been sent successfully', 'success');
    } catch (error) {
      toast('An error occurred', Utils.formatErrorMessage(error), 'error');
    }

    setSetting(false);
    onClose();
  };

  return (
    <ModalDialog isOpen={isOpen} onClose={onClose}>
      <Box pt={2}>
        {recipient && (
          <Stack direction={'row'} gap={3} width={'100%'} pr={2}>
            <Avatar size="sm" name={recipient.fullName} src={recipient.profilePhoto} />
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'space-evenly'}
              width={'100%'}
              fontSize={'18px'}
              color={'brand.900'}
            >
              {recipient.fullName}
            </Box>
          </Stack>
        )}
        <Stack pt={2}>
          <Border />
          <FormControl id="subject">
            <Input variant="unstyled" placeholder="Subject" type="text" value={subject} onChange={(e) => setSubject(e.target.value)} />
          </FormControl>
          <Border />
          <FormControl id="message">
            <Textarea
              variant="unstyled"
              minHeight={'120px'}
              placeholder="Your Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              resize={'none'}
              autoFocus
            />
          </FormControl>
        </Stack>
        <Flex direction="row" pt={4}>
          <Spacer />
          <Button onClick={handleSendClick} type="submit" isLoading={sending}>
            Send
          </Button>
        </Flex>
      </Box>
    </ModalDialog>
  );
};
