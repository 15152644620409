import React, { useState, useEffect } from 'react';
import { LinkBox, LinkOverlay, Box, Wrap, WrapItem, Avatar, Flex } from '@chakra-ui/react';
import { User } from '../types';

interface Props {
  user: User | null;
}

export const AccountPane: React.FC<Props> = ({ user }) => {
  const [showAvatar, setShowAvatar] = useState(true);
  useEffect(() => {
    setShowAvatar(false);
    setTimeout(() => {
      setShowAvatar(true);
    }, 10);
  }, [user?.profilePhoto]);

  if (!user) return null;

  return (
    <LinkBox>
      <LinkOverlay>
        <Flex direction={'row'} justifyContent={'flex-end'} alignItems={'center'}>
          <Box lineHeight="20px" px="2" fontSize="14px" textAlign={'right'}>
            Welcome, <strong>{user?.fullName.split(' ')[0]}</strong> <br />
            {user.organisation && <strong>{user.organisation.name}</strong>}
            {user.affiliatePartner && <strong>{user.affiliatePartner.name}</strong>}
            {user.userType === 'xsilio admin' && <strong>XSILIO Admin</strong>}
          </Box>
          <WrapItem>{showAvatar && <Avatar src={user.profilePhoto} name={user?.fullName} />}</WrapItem>
        </Flex>
      </LinkOverlay>
    </LinkBox>
  );
};
