import { extendTheme, theme as baseTheme } from '@chakra-ui/react';

import { Card } from './Card';
import { Button } from './Button';
import { Input, Select, Textarea } from './Input';
import { Table } from './Table';
import { Modal } from './Modal';
import { Tag } from './Tag';
import { Avatar } from './Avatar';
import { CloseButton } from './CloseButton';
import { Switch } from './Switch';
import { Alert } from './Alert';

const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: '#DEE5E2',
        color: 'brand.900',
        fontFamily: 'DM Sans, sans-serif',
      },
      a: {
        color: 'brand.900',
      },
    },
  },
  radii: {
    xs: '25px',
    sm: '30px',
    base: '50px',
    xl: '160px',
  },
  components: {
    Button,
    Alert,
    Input,
    Card,
    Table,
    Modal,
    Tag,
    Select,
    Textarea,
    Avatar,
    CloseButton,
    Switch,
  },
  colors: {
    brand: {
      50: '#315D4F',
      100: '#DEE5E2',
      300: '#B1C3BD',
      500: '#87A29A',
      700: '#65877C',
      800: '#487164',
      900: '#315D4F',
    },
    switch: {
      100: '#315D4F',
      500: '#315D4F',
      900: '#315D4F',
    },
  },
});

export default theme;
