import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import NotFoundPage from '../pages/NotFoundPage';

import { useClientRoutes } from './clientRoutes';
import { useAuthRoutes } from './authRoutes';
import { useAdminRoutes } from './adminRoutes';

const AppRouter: React.FC = () => {
  const clientRoutes = useClientRoutes();
  const authRoutes = useAuthRoutes();
  const adminRoutes = useAdminRoutes();

  return (
    <Router>
      <Routes>
        {authRoutes}
        {clientRoutes}
        {adminRoutes}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
