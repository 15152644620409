import React from 'react';
import { Box, BoxProps, Flex } from '@chakra-ui/react';

interface Props extends BoxProps {}

export const FormFooter: React.FC<Props> = ({ children, ...restProps }) => {
  return (
    <Box {...restProps}>
      <Flex direction="row" pt={10} gap={4} justifyContent={'flex-end'}>
        {children}
      </Flex>
    </Box>
  );
};

export default FormFooter;
